import axios from 'axios';
import React, { useState } from 'react'
import config from '../../config';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { ToastContainer, toast } from "react-toastify";

function RegionalOffer() {

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const region = queryParams.get('region');
    const [itemsToDelete, setItemsToDelete] = useState([]);
    const [regionName, setRegionName] = useState('');
    
    const [vendors, setVendors] = useState([
        {
            logo: "",
            description: "",
            buttonText: "",
            buttonLink: "",
        },
    ]);

    const onFileChange3 = (e) => {
        let index = e.target.getAttribute("data-key");
        let newObj = new FormData();
        newObj.append("file", e.target.files[0]);
        axios
            .post(`${config.backend_URL}/admin/upload_single_image`, newObj)
            .then((data) => {
                let vendorsNew = vendors;
                vendorsNew[index]["logo"] = data.data.data;
                setVendors(vendorsNew)
            })
            .catch((err) => {
                console.log("error", err);
            });
    };

    const BlocksChange1 = (i, e) => {
        let vendorsNew = [...vendors];
        vendorsNew[i][e.target.name] = e.target.value;
        setVendors(vendorsNew);
    }

    // add More Blocks
    const addVendorFields = () => {
        setVendors([
                ...vendors,
                {
                    logo: "",
                    description: "",
                    buttonText: "",
                    buttonLink: "",
                },
            ]);
    }

    const removeVendorFields = (i) => {
        if (vendors[i]._id) {
            setItemsToDelete([vendors[i]._id, ...itemsToDelete]);
        }
        const vendorsNew = [...vendors.slice(0, i), ...vendors.slice(i + 1)];
        setVendors(vendorsNew);
    }

    const mySubmit = (e) => {
        e.preventDefault();

        let data = vendors.map((vendor) => ({
            ...vendor,
            region,
        }));

        try {
            axios.post(`${config.backend_URL}/admin/addVendor`, {data, itemsToDelete}).then((res) => {
                toast("Vendor updated successfully");
                setTimeout(() => {
                    window.location.href = '/admin/regional-offers'
                },2000)
           })
        } catch (error) {
            console.log(error);
        }

    }

    const getVendors = () => {
        try {
            console.log(region);
            axios.get(`${config.backend_URL}/admin/getVendors/${region}`).then((res) => {
                
                if (res.data.data.length > 0) setVendors(res.data.data)
           }) 
        } catch (error) {
            console.log(error);
        }
    }

    const getRegionById = () => {
        try {
            console.log(region);
            axios.get(`${config.backend_URL}/admin/getRegionById/${region}`).then((res) => {
                    setRegionName(res.data.data.region)
                
            })
        } catch (error) {
            console.log(error);
        }
    }
    useEffect(() => {
        getRegionById()
    }, [region])
    useEffect(() => {
        getVendors()
    },[])

  return (
      <div class="content-wrapper">
          <ToastContainer />
          <section class="content">
              <div class="row">
                  <div class="col-md-12">
                      <div class="card card-primary">
                          <div class="card-header">
                              <h3 class="card-title">{ regionName  }</h3>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="card-body">
                  <form
                      onSubmit={mySubmit}
                      encType="multipart/form-data"
                  >
                  <div class="form-group">
                      <b>Vendors</b>
                  </div>
                  {vendors.map((element, index) => {
                      let requi1 = element.logo !== "" ? false : true;
                      return (
                          <div class="row" key={index}>
                              <div class="form-group col-md-3">
                                  <label>Vendor Logo</label>
                                  <input
                                      type="file"
                                      class="form-control"
                                      name="file"
                                      data-key={index}
                                      onChange={onFileChange3}
                                  />
                                  {element.logo !== "" ? (
                                      <img
                                          src={
                                              config.cdn_backend_URL +
                                              "/" +
                                              element.logo
                                          }
                                          height={80}
                                          width={80}
                                          alt="logo"
                                      />
                                  ) : (
                                      ""
                                  )}
                              </div>

                              <div class="form-group col-md-4">
                                  <label>Vendor description</label>
                                  <input
                                      type="text"
                                      class="form-control"
                                      name="description"
                                      value={element.description}
                                      onChange={(e) =>
                                          BlocksChange1(index, e)
                                      }
                                  />
                              </div>

                              <div class="form-group col-md-2">
                                  <label>Button Text</label>
                                  <input
                                      type="text"
                                      class="form-control"
                                      value={element.buttonText}
                                      name="buttonText"
                                      onChange={(e) =>
                                          BlocksChange1(index, e)
                                      }
                                  />
                              </div>

                              <div class="form-group col-md-2">
                                  <label>Button Link</label>
                                  <input
                                      type="text"
                                      class="form-control"
                                      name="buttonLink"
                                      value={element.buttonLink}
                                      onChange={(e) =>
                                          BlocksChange1(index, e)
                                      }
                                  />
                              </div>
                              {index === 0 ? (
                                  <div class="form-group col-md-1">
                                      <label>&nbsp;&nbsp;&nbsp;</label>
                                      <button
                                          type="button"
                                          class="btn btn-warning btn-sm "
                                          onClick={() => addVendorFields()}
                                      >
                                          <i class="fa fa-plus"></i>
                                      </button>
                                  </div>
                              ) : (
                                  <div class="form-group col-md-1">
                                      <label>&nbsp;&nbsp;&nbsp;</label>
                                      <button
                                          type="button"
                                          class="btn btn-warning btn-sm "
                                          onClick={() =>
                                              removeVendorFields(index)
                                          }
                                      >
                                          <i class="fa fa-minus"></i>
                                      </button>
                                  </div>
                              )}
                          </div>
                      );
                  })}
                      <div class="form-group col-md-2">
                          <label>&nbsp;&nbsp;&nbsp;</label>
                          <button
                              type="submit"
                              class="btn btn-primary"
                          >
                              {" "}
                              {vendors.length > 0 ? "Update" : "Submit"}
                          </button>
                      </div>
                </form>
              </div>
            </section>  
      </div>
  )
}

export default RegionalOffer