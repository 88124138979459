import React from "react";
import { Link } from "react-router-dom";
import SwiperCore, {
  A11y,
  Autoplay,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import config from "../config.js";
SwiperCore.use([Autoplay]);
const axios = require("axios");

class Properties_img extends React.Component {
  state = {
    Properties: [],
  };

  componentDidMount() {
    this.getProperties();
  }

  getProperties = (key) => {
    let newObj = {};
    if (key !== "all") {
      newObj = { cityId: key };
    }

    axios
      .post(`${config.backend_URL}/admin/getProperties`, newObj)
      .then((responseJson) => {
        this.setState({ Properties: responseJson.data.data, cityId: key });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <>
        <article className="homePropertyBlock wrapper py-80">
          <div className="container">
            <div className="ltstPrprtyRow">
              <div className="prprtySldrOuter crslCntrls">
                <Swiper
                  modules={[Navigation, Pagination, Scrollbar, A11y]}
                  navigation
                  spaceBetween={30}
                  slidesPerView={4}
                  loop={true}
                  autoplay={{ delay: 3000 }}
                  breakpoints={{
                    320: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    576: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 30,
                    },
                    992: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    1200: {
                      slidesPerView: 4,
                      spaceBetween: 30,
                    },
                  }}
                >
                  {this.state.Properties.length > 0
                    ? this.state.Properties.map((x, index) => {
                        console.log("aniket", x);
                        let imagePath1 =
                          config.cdn_backend_URL + "/" + x.property_image;
                        let imagePath =
                          x.property_image === ""
                            ? `${config.cdn_backend_URL}/default.jpg`
                            : imagePath1;

                        return (
                          <SwiperSlide key={index}>
                            <div className="prprtyItem">
                              <Link to={`/alberta/${x.city.slug}/${x.slug}`}>
                                <div className="prprtyBadge">
                                  <span
                                    className="prprtyBadgeIcon"
                                    style={{
                                      backgroundImage: `url(${
                                        config.cdn_URL + "/images/badge.png"
                                      })`,
                                    }}
                                  ></span>
                                  For Rent
                                </div>
                                <div
                                  className="prprtyImg"
                                  style={{
                                    backgroundImage: `url(${imagePath})`,
                                  }}
                                >
                                  <div className="prprtyOverlay"></div>
                                </div>
                                <div className="prprtyInfo">
                                  <h4>{x.property_name}</h4>
                                  <p>
                                    <i>
                                      <img
                                        src={
                                          config.cdn_URL + "/images/locIcon.png"
                                        }
                                        alt={x.address}
                                      />
                                    </i>{" "}
                                    {x.address}
                                  </p>
                                </div>
                              </Link>
                            </div>
                          </SwiperSlide>
                        );
                      })
                    : ""}
                </Swiper>
              </div>
            </div>
            <div class="sctnBtn mt-md-5 mt-4 text-center">
              <Link to="/alberta">
                {" "}
                <button type="button" class="btn btnCommon btn btnWhite">
                  Explore More
                </button>{" "}
              </Link>
            </div>
          </div>
        </article>
      </>
    );
  }
}

export default Properties_img;
