import React from "react";
import { withRouter } from "react-router-dom";
import config from "../../config.js";
import Loader from "../../loader.js";
import Pagination from "react-js-pagination";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";

const axios = require("axios");
class VisitorParking extends React.Component {
  state = {
    loader_flag: false,
    modalIsOpen: false,
    status: "",
    activePage: 1,
    totalItemsCount: 0,
    data: [],
    errors: [],
    property: "",
    startDate: "",
    endDate: "",
    properties: [],
  };

  initialState = {
    loader_flag: false,
    modalIsOpen: false,
    activePage: 1,
    totalItemsCount: 0,
    status: "",
    data: [],
    errors: [],
    property: "",
    startDate: "",
    endDate: "",
    properties: [],
  };

  componentDidMount = async () => {
    this.setState({ loader_flag: true });
    await this.getFeedbacks();
    await this.getProperties();
    this.setState({ loader_flag: false });
  };

  getFeedbacks = async () => {
    const { activePage, property, startDate, endDate } = this.state;
    try {
      const response = await axios.post(
        `${config.backend_URL}/admin/getVisitorParking`,
        {
          page: activePage,
          propertyId: property,
          startDate: startDate,
          endDate: endDate,
        }
        );
        console.log(property)
      this.setState({
        data: response.data.data,
        totalItemsCount: response.data.total,
      });
    } catch (error) {
      console.error(error);
    }
  };

  getProperties = () => {
    axios
      .post(`${config.backend_URL}/admin/getProperties`)
      .then((responseJson) => {
        this.setState({ properties: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handlePageChange = async (pageNumber) => {
    this.setState({ loader_flag: true });
    await this.setState({ activePage: pageNumber });
    this.getFeedbacks();
    this.setState({ loader_flag: false });
  };

  changedata = (e) => {
    const id = e.target.getAttribute("data-id");
    this.setState({ loader_flag: true });
    axios
      .post(`${config.backend_URL}/admin/updateVisitorParking`, {
        id: id,
        status: e.target.value,
      })
      .then((responseJson) => {
        this.getFeedbacks();
        this.setState({ loader_flag: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loader_flag: false });
      });
  };

  handlePropertyChange = (e) => {
    this.setState({ property: e.target.value });
  };

  handleStartDateChange = (e) => {
    this.setState({ startDate: e.target.value });
  };

  handleEndDateChange = (e) => {
    this.setState({ endDate: e.target.value });
  };

  handleFilter = () => {
    this.getFeedbacks();
  };

  render() {
    return (
      <>
        {this.state.loader_flag ? (
          <Loader />
        ) : (
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Visitor Parking </h1>
                  </div>
                  <div className="col-sm-6">
                    <div className="row">
                      <div className="col-sm-4">
                        <select
                          className="form-control"
                          value={this.state.property}
                          onChange={this.handlePropertyChange}
                        >
                          <option value="">Select Property</option>
                          {this.state.properties.map((property) => (
                            <option key={property._id} value={property._id}>
                              {property.property_name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-sm-4">
                        <input
                          type="date"
                          className="form-control"
                          value={this.state.startDate}
                          onChange={this.handleStartDateChange}
                        />
                      </div>
                      <div className="col-sm-4">
                        <input
                          type="date"
                          className="form-control"
                          value={this.state.endDate}
                          onChange={this.handleEndDateChange}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-sm-12">
                        <button
                          className="btn btn-primary"
                          onClick={this.handleFilter}
                        >
                          Apply Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-primary">
                      <div className="card-header">
                        <h3 className="card-title">Visitor Parking List</h3>
                      </div>
                      <div className="card-body">
                        <table
                          id="example1"
                          className="table table-bordered table-striped"
                        >
                          <thead>
                            <tr>
                              <th width="3%">Sr No.</th>
                              <th title="Name">Name</th>
                              <th title="Property">Property</th>
                              <th title="Visiting Suite No">Visiting Suite</th>
                              <th title="Parking Stall Number">
                                Parking Stall
                              </th>
                              <th title="Plate Number">Plate</th>
                              <th title="Mobile">Mobile</th>
                              <th title="Email">Email</th>
                              <th title="Date">Date</th>
                              <th title="Time">Time</th>
                              <th title="Status">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.data.length > 0 ? (
                              this.state.data.map((x, key) => {
                                return (
                                  <tr key={key}>
                                    <td>{++key}</td>
                                    <td title={x.name}>{x.name}</td>
                                    <td title={x.property}>{x.property}</td>
                                    <td title={x.visiting_suite_number}>
                                      {x.visiting_suite_number}
                                    </td>
                                    <td title={x.visitor_parking_stall_number}>
                                      {x.visitor_parking_stall_number}
                                    </td>
                                    <td title={x.plate_number}>
                                      {x.plate_number}
                                    </td>
                                    <td title={x.number}>{x.number}</td>
                                    <td title={x.email}>{x.email}</td>
                                    <td>
                                      {new Intl.DateTimeFormat("en-US", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                      }).format(x.timestamp)}
                                    </td>
                                    <td>{`${x.from_time} - ${x.end_time}`}</td>
                                    <td>
                                      <select
                                        className="form-control"
                                        value={x.status}
                                        name="status"
                                        onChange={this.changedata}
                                        data-id={x._id}
                                      >
                                        <option value="">
                                          Choose Priority
                                        </option>
                                        <option value="pending">Pending</option>
                                        <option value="on_hold">On Hold</option>
                                        <option value="processing">
                                          {" "}
                                          Processing
                                        </option>
                                        <option value="successful">
                                          {" "}
                                          Successful
                                        </option>
                                      </select>
                                    </td>
                                  </tr>
                                );
                              })
                            ) : (
                              <tr>
                                <td colSpan={7} style={{ color: "red" }}>
                                  <center> Result Not Found </center>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>

                      <div className="d-flex justify-content-center">
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </>
    );
  }
}
export default withRouter(VisitorParking);
