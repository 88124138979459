import React from "react";
import { withRouter } from "react-router-dom";
import config from "../../config.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import Loader from "../../loader.js";
import Modal from "react-modal";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const axios = require("axios");

const cropper = React.createRef(null);

class AdminBanner extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      src: null,
      cropResult: null,

    };

    this.onChange = this.onChange.bind(this);
    this._crop = this._crop.bind(this);
  }

  state = {
    loader_flag: false,
    modalIsOpen: false,
    data: [],
    id: "",
    title: "",
    image: "",
    setFileName: "",
    setFileType: "",
    imageName: "",
    errors: [],
  };

  initialState = {
    loader_flag: false,
    modalIsOpen: false,
    data: [],
    id: "",
    title: "",
    image: "",
    errors: [],
  };

  onChange(e) {
    e.preventDefault();
    this.setState({ setFileName: e.target.files[0].name })
    this.setState({ setFileType: e.target.files[0].type })
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      this.setState({ src: reader.result });
    };
    reader.readAsDataURL(files[0]);

  }

  _crop() {
    this.setState({
      cropResult: cropper.current.cropper.getCroppedCanvas().toDataURL()
    });

  }



  openModal = (e) => {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  };

  closeModal = async (e) => {
    this.setState({ modalIsOpen: false });
  };

  changedata = (e) => {

    if (e.target.value) {
      if (e.target.name === "banner_title" && e.target.value.length > 30) {
        return;
      }
    }


    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount = () => {
    this.setState({ loader_flag: true });
    this.getBanners();
    this.getSettingsData();
    //this.setState({ loader_flag: false });
  };

  getSettingsData = () => {
    axios
      .get(`${config.backend_URL}/admin/getSettings`)
      .then((responseJson) => {
        this.setState({
          banner_title: responseJson.data.data.banner_title,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getBanners = () => {
    this.setState({ loader_flag: true });
    axios
      .get(`${config.backend_URL}/admin/getBanners`)
      .then((responseJson) => {
        this.setState({ data: responseJson.data.data, loader_flag: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loader_flag: false });
      });
  };

  mySubmit = async (e) => {
    e.preventDefault();
    this.setState({ loader_flag: true });
    let cropData = cropper.current.cropper.getCroppedCanvas().toDataURL()

    const url = cropData;

    fetch(url)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], this.state.setFileName, { type: this.state.setFileType })

        let newObj1 = new FormData();
        newObj1.append("file", file);

        axios
          .post(`${config.backend_URL}/admin/upload_single_image`, newObj1)
          .then((data) => {

            this.setState({ imageName: data.data.data });
            let newObj = {
              image: this.state.imageName,
              title: this.state.title,
            };
            console.log(newObj)
            axios
              .post(`${config.backend_URL}/admin/addBanner`, newObj)
              .then(async (data) => {
                console.log(data);

                if (data.data.status === true) {
                  toast(data.data.message);

                  await this.setState(this.initialState);

                  this.getBanners();
                  document.getElementById("testi_form").reset();
                } else {
                  toast("Something wrong!");
                }
                this.setState({ loader_flag: false });
              })
              .catch((err) => {
                console.log("error", err);
                this.setState({ loader_flag: false });
              });

          })
          .catch((err) => {

          });
      })






  };

  bannerTitleSubmit = (e) => {
    e.preventDefault();
    let newObj = {
      banner_title: this.state.banner_title,
    };
    this.setState({ loader_flag: true });
    axios
      .post(`${config.backend_URL}/admin/updateSettings`, newObj)
      .then(async (data) => {
        if (data.data.status === true) {
          toast("Banner text updated successfull");
        } else {
          toast("Something wrong!");
        }
        this.setState({ loader_flag: false });
      })
      .catch((err) => {
        console.log("error", err);
        this.setState({ loader_flag: false });
      });
  };

  onFileChange = (e) => {
    this.setState({ selectedFile: e.target.files[0] });
    $("#submitBtn").text("Processing...");
    $("#submitBtn").prop("disabled", true);

    let newObj = new FormData();
    newObj.append("file", e.target.files[0]);

    axios
      .post(`${config.backend_URL}/admin/upload_single_image`, newObj)
      .then((data) => {
        console.log(data.data);
        this.setState({ imageName: data.data.data });
        $("#submitBtn").text("Submit");
        $("#submitBtn").prop("disabled", false);
      })
      .catch((err) => {
        console.log("error", err);
        $("#submitBtn").text("Submit");
        $("#submitBtn").prop("disabled", false);
      });
  };

  delBanner = (id) => {
    if (window.confirm("Are you sure")) {
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id }),
      };
      this.setState({ loader_flag: true });
      fetch(`${config.backend_URL}/admin/delBanner`, options)
        .then((res) => {
          //console.log("response",res)
          return res.json();
        })
        .then((data) => {
          if (data.status === true) {
            this.getBanners();
            toast(data.message);
            //this.setState(this)
          } else {
            toast("Something wrong!");
          }
          this.setState({ loader_flag: false });
          window.location.reload();
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loader_flag: false });
        });
    } else {
      window.location.reload();
    }
  };

  render() {
    const customStyles = {
      content: {
        width: "50%",
        top: "50%",
        left: "55%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    return (
      <>
        <ToastContainer />
        {this.state.loader_flag ? (
          <><Loader /></>
        ) : (
          <>
            <div class="content-wrapper">
              <section class="content-header">
                <div class="container-fluid">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <h1> Banner </h1>
                    </div>
                    <div class="col-sm-6">
                      <ol class="breadcrumb float-sm-right">
                        <button
                          onClick={this.openModal}
                          class="pull-right btn btn-success"
                        >
                          Add Banner
                        </button>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section class="content">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title">Banner Title</h3>
                        </div>
                        <div class="card-body">
                          <div class="row">
                            <div class="form-group col-md-12">
                              <label>Title</label>
                              <input
                                class="form-control"
                                name="banner_title"
                                required
                                onChange={this.changedata}
                                value={this.state.banner_title}
                                placeholder="Enter Banner Title"
                              />
                            </div>

                            <div class="form-group col-md-3">
                              <button
                                type="submit"
                                class="btn btn-primary"
                                onClick={this.bannerTitleSubmit}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-7">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title">Banners List</h3>
                        </div>
                        <div class="card-body">
                          <table
                            id="exampl e1"
                            class="table table-bordered table-striped"
                          >
                            <thead>
                              <tr>
                                <th>Sr No.</th>
                                <th>Image</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data && this.state.data.length > 0 ? (
                                this.state.data.map((x, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{++key}</td>
                                      <td>
                                        <img
                                          src={`${config.cdn_backend_URL}/${x.image}`}
                                          height={100}
                                          alt="banner"
                                        />
                                      </td>
                                      <td>
                                        <a
                                          class="btn btn-danger btn-sm"
                                          href="#"
                                          onClick={() => {
                                            this.delBanner(x._id);
                                          }}
                                        >
                                          <i class="fas fa-trash"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={4} style={{ color: "red" }}>
                                    <center> Result Not Found </center>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <Modal
              isOpen={this.state.modalIsOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Add Amenities"
              ariaHideApp={false}
            >
              {/* modal */}
              <div class="modal-header">
                <h2>Add Banner</h2>
                <button
                  type="button"
                  class="btn-close"
                  onClick={this.closeModal}
                ></button>
              </div>
              <div class="modal-body">
                <p id="error"></p>
                <form
                  onSubmit={this.mySubmit}
                  encType="multipart/form-data"
                  id="testi_form"
                >
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <input
                          type="file"
                          name="image"
                          id="image"
                          defaultValue={this.state.image}
                          class="form-control"
                          accept="image/png, image/jpeg"
                          onChange={this.onChange}
                          required
                        />
                      </div>
                    </div>  
                    <div class="col-md-12">
                      {this.state.src &&
                        <Cropper
                          style={{ height: 250, width: '100%' }}
                          aspectRatio={16 / 9}
                          preview=".img-preview"
                          guides={false}
                          src={this.state.src}
                          crop={this._crop}
                          ref={cropper}
                          viewMode={1}
                          dragMode="move"
                          cropBoxMovable={false}
                        />}
                      {this.state.cropResult &&
                        <img
                          style={{ width: "100%" }}
                          src={this.state.cropResult}
                          alt="cropped image"
                        />}
                    </div>
                  </div>
                  <button type="submit" class="btn btn-success" id="submitBtn">
                    Submit
                  </button>
                </form>
              </div>
            </Modal>
          </>
        )}
      </>
    );
  }
}
export default withRouter(AdminBanner);
