import React from "react";
import { withRouter } from "react-router-dom";
import config from "../../config.js";
import Loader from "../../loader.js";
import Pagination from "react-js-pagination";
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css


const axios = require("axios");
class Property_Appointment extends React.Component {

    state = {
        loader_flag: false,
        status: '',
        data: [],
        errors: [],
        activePage: 1,
        totalItemsCount: 0,
    }

    initialState = {
        loader_flag: false,
        status: '',
        data: [],
        errors: []

    }

    componentDidMount() {
        this.setState({ loader_flag: true })
        this.getFeedbacks()
        this.setState({ loader_flag: false })
    }

    getFeedbacks = async () => {
        await axios.post(`${config.backend_URL}/admin/getPropertyFeedbacks`, { page: this.state.activePage })
            .then((responseJson) => {
                this.setState({ data: responseJson.data.data, totalItemsCount: responseJson.data.total })
            })
            .catch((error) => {
                console.error(error);
            });
    }

    changedata = (e) => {
        this.setState({ loader_flag: true })
        const id = e.target.getAttribute('data-id')
        axios.post(`${config.backend_URL}/admin/updateFeedbacks`, { id: id, status: e.target.value })
            .then((responseJson) => {
                this.getFeedbacks();
                this.setState({ loader_flag: false })
            })
            .catch((error) => {
                console.error(error);
                this.setState({ loader_flag: false })
            });
    }

    handlePageChange = async (pageNumber) => {
        this.setState({ loader_flag: true })
        await this.setState({ activePage: pageNumber });
        this.getFeedbacks()
        this.setState({ loader_flag: false })
    };


    render() {
        return (
            <>
            {this.state.loader_flag  ? (
               <><Loader /></>
              ) : (
                <div class="content-wrapper">
                    <section class="content-header">
                        <div class="container-fluid">
                            <div class="row mb-2">
                                <div class="col-sm-6">
                                    <h1>Property Feedback </h1>
                                </div>
                                <div class="col-sm-6">

                                </div>
                            </div>
                        </div>
                    </section>

                    <section class="content">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card card-primary">
                                        <div class="card-header">
                                            <h3 class="card-title">Property Feedback List</h3>
                                        </div>
                                        <div class="card-body table-responsive">
                                            <table id="exampl e1" class="table table-bordered table-striped ">
                                                <thead>
                                                    <tr>
                                                        <th >Sr No.</th>
                                                        <th>Name</th>
                                                        <th>Mobile</th>
                                                        <th>Email</th>
                                                        <th>Subject</th>
                                                        <th>Property</th>
                                                        <th>Room</th>
                                                        <th width="125px">Status</th>
                                                        <th>Date</th>
                                                        <th>Message</th>

                                                    </tr>
                                                </thead>
                                                <tbody>


                                                    {this.state.data.length > 0 ? this.state.data.map((x, key) => {
                                                        return (
                                                            <tr key={key}>
                                                                <td>{++key}</td>
                                                                <td>{x.name}</td>
                                                                <td>{x.number}</td>
                                                                <td title={x.email}>{x.email.substring(0, 10)}...</td>
                                                                <td title={x.subject}>{(x.subject && x.subject !== "") ? x.subject.substring(0, 10) + '...' : ''}</td>
                                                                <td>{x.property_name}</td>
                                                                <td>{x.bedroom}</td>
                                                                <td>

                                                                    <select class="form-control" value={x.status} name="status" onChange={this.changedata} data-id={x._id}>

                                                                        <option value="">Choose Priority</option>
                                                                        <option value="pending">Pending</option>
                                                                        <option value="on_hold">On Hold</option>
                                                                        <option value="processing"> Processing</option>
                                                                        <option value="successful"> Successful</option>

                                                                    </select>

                                                                </td>

                                                                <td>{new Intl.DateTimeFormat('en-US', { year: 'numeric', month: '2-digit', day: '2-digit' }).format(x.timestamp)}</td>
                                                                <td title={x.message}>{x.message.substring(0, 10)}...</td>

                                                            </tr>
                                                        )
                                                    }) :

                                                        <tr>
                                                            <td colSpan={10} style={{ color: 'red' }}><center> Result Not Found </center></td>
                                                        </tr>

                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <Pagination
                                            itemClass="page-item"
                                            linkClass="page-link"
                                                activePage={this.state.activePage}
                                                itemsCountPerPage={10}
                                                totalItemsCount={this.state.totalItemsCount}
                                                pageRangeDisplayed={5}
                                                onChange={this.handlePageChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                ) }
            </>
        );
    }
}
export default withRouter(Property_Appointment);