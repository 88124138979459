import React from "react";
import { withRouter } from "react-router-dom";
import config from "../../config.js";
import Links from "./includes/Links.js";
import Loader from "../../loader.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import jwt from "jsonwebtoken";

class Login extends React.Component {
  state = {
    showP: false,
    loader_flag: false,
    formdata: {
      username: "",
      password: "",
    },
  };

  componentDidMount = async () => {
    this.setState({ loader_flag: true });
    setTimeout(this.setState({ loader_flag: false }), 3000);
  };

  loginAdmin = (e) => {
    e.preventDefault();

    let error = 0;
    let arry = "";
    if (this.state.formdata.username === "") {
      toast("Username can not be empty");
      error++;
    }

    if (this.state.formdata.password === "") {
      toast("Password can not be empty");
      error++;
    }

    if (error > 0) {
      $("#error").html(arry);
    } else {
      $("#error").html("");

      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(this.state.formdata),
      };
      fetch(`${config.backend_URL}/admin/loginRequest`, options)
        .then((res) => {
          //console.log("response",res)
          return res.json();
        })

        .then((data) => {
          if (data.status == 200) {
            toast("Verification code sent !!");
            localStorage.setItem("email", this.state.formdata.username);
            localStorage.setItem("pass", this.state.formdata.password);
            this.props.history.push({
              pathname: `/2fa`,
              state: { token: data.VerificationToken }
            });

            // const id = jwt.sign(
            //   { expiresInMinutes: 60, id: data.data.id },
            //   "pokemon"
            // );
            // const user_flag = jwt.sign({ expiresInMinutes: 60,user_flag:data.data.flag }, 'pokemon')
            // const user_name = jwt.sign({ expiresInMinutes: 60,user_name:data.data.name }, 'pokemon')
            // const user_unicode = jwt.sign({ expiresInMinutes: 60,user_unicode:data.data.student_eng_id }, 'pokemon')
            // const mainID = jwt.sign({ expiresInMinutes: 60,mainID:data.mainID }, 'pokemon')
            // const user_img = jwt.sign({ expiresInMinutes: 60,user_img:data.photo }, 'pokemon')

            // localStorage.setItem("id", id);
            // localStorage.setItem("user_flag",user_flag)
            // localStorage.setItem("user_name",user_name)
            // localStorage.setItem("user_unicode",user_unicode)
            // localStorage.setItem("mainID",mainID)
            // localStorage.setItem("user_img",user_img)


          } else if(data.status) {
            toast(data.message);
            // this.props.history.push("/admin/dashboard");
          }
        })

        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  changedata = (e) => {
    this.setState({
      formdata: { ...this.state.formdata, [e.target.name]: e.target.value },
    });
  };

  render() {
    return (
      <>
        <Links />

        <ToastContainer />
        {this.state.loader_flag ? (
         <><Loader /></>
        ) : (
          <>
            <div class="appHdr">
              <div class="loginCenter">
                <div class="login-box">
                  <div class="login-logo">
                    <a href="#!">
                      <b>GSK Properties</b>
                    </a>
                  </div>
                  <div class="card">
                    <div class="card-body login-card-body">
                      <p class="login-box-msg">Sign in to start your session</p>
                      <form onSubmit={this.loginAdmin}>
                        <div class="input-group mb-3">
                          <input
                            type="username"
                            className="form-control"
                            placeholder="Username"
                            name="username"
                            onChange={this.changedata}
                            value={this.state.formdata.username}
                            required
                          />
                          <div class="input-group-append">
                            <div class="input-group-text">
                              <span class="fas fa-envelope"></span>
                            </div>
                          </div>
                        </div>
                        <div class="input-group mb-3">
                          <input
                            type={this.state.showP ? "p" : "password"}
                            className="form-control"
                            placeholder="Password"
                            name="password"
                            onChange={this.changedata}
                            value={this.state.formdata.password}
                            required
                          />
                          <div class="input-group-append input-group-click">
                            <div class="input-group-text">

                              {this.state.showP ? <span class="fas fa-eye" onClick={() => this.setState({ showP: !this.state.showP })}></span> :
                                <span class="fas fa-eye-slash" onClick={() => this.setState({ showP: !this.state.showP })}></span>}

                            </div>
                          </div>
                        </div>

                        <p id="errorMsg"></p>

                        <div class="row d-flex align-items-center justify-content-betwen">
                          <div class="col-auto">
                            <button
                              type="submit"
                              id="submitBtn"
                              class="btn btn-primary btn-block"
                            >
                              Sign In
                            </button>
                          </div>
                          <div class="col">

                            <p className="text-right m-0">
                              <a href="/forgot-password">Forgot Password</a>
                            </p>

                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default withRouter(Login);

//export default Login;