import React, { useState } from 'react';
import axios from 'axios';
import config from "../../config.js";
import { useEffect } from 'react';


function EditLandingPage() {
    const [newHeading, setNewHeading] = useState('');
    const [newText, setNewText] = useState('');
    const [image, setImage] = useState('');
    const [image1, setImage1] = useState('');
    const [highlightColor, setHighlightColor] = useState('#FF0000'); // Default color: red
    const [highlightedText, setHighlightedText] = useState([]);
    const [value, setvalue] = useState('');

    const handleHighlightChange = (e) => {
        const inputText = e.target.value;
        const textArray = inputText.split(/[\s\n]+/);

        const highlightedArray = textArray.map((word) => {
            return {
                word,
                highlighted: false,
            };
        });

        setHighlightedText(highlightedArray);
        setNewHeading(inputText);
    };

    const handleWordClick = (index) => {
        const updatedHighlightedText = [...highlightedText];
        updatedHighlightedText[index].highlighted = !updatedHighlightedText[index].highlighted;
        setHighlightedText(updatedHighlightedText);
    };

    const handleUpdate = async () => {
        try {
            let updatedHeading = newHeading;

            highlightedText.forEach((wordObj) => {
                if (wordObj.highlighted) {
                    updatedHeading = updatedHeading.replace(
                        wordObj.word,
                        `<i className="colorPrimary " style="color: ${highlightColor};">${wordObj.word}</i>`
                    );
                }
            });
            setvalue(updatedHeading)

            await axios.post(`${config.backend_URL}/admin/updateLandingContent`, {
                heading: updatedHeading,
                text: newText,
                image: image
            });

            alert('Content updated successfully!');
            setNewHeading('');
            setHighlightedText([]);
        } catch (error) {
            console.error(error);
            alert('Failed to update content.');
        }
    };

   const onFileChange = (e) => {
        let newObj = new FormData();
        newObj.append("file", e.target.files[0]);
        axios
            .post(`${config.backend_URL}/admin/upload_single_image`, newObj, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            .then((data) => {
                setImage(data.data.data);
            })
            .catch((err) => {
                console.log("error", err);
            });
    };

    const getLandingContent = async () => {
        try {
            let response = await axios.get(`${config.backend_URL}/admin/updateLandingContent`);
            console.log(response.data);
            setvalue(response.data.data.heading);
            setImage(response.data.data.image)
            setNewText(response.data.data.text)
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        getLandingContent()
    },[])

    return (
        <div className="content-wrapper mt-5">
            <section class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div className='col-md-6'>
                                <div className="form-group  ">
                                        <label htmlFor="newHeading">Heading :</label>
                                        <textarea
                                            className="form-control"
                                            id="newHeading"
                                            value={newHeading}
                                            onChange={handleHighlightChange}
                                        />
                                </div>
                        </div>
                        <div className='col-md-6'>
                            <div className="form-group ">
                                <label htmlFor="newHeading">Text:</label>
                                <textarea
                                    className="form-control"
                                    id="newText"
                                    value={newText}
                                    onChange={(e) => setNewText(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-md-12'>
                            <h1 dangerouslySetInnerHTML={{ __html: value }} />
                        </div>
                    </div>
                    <div className='row '>
                        <div className='col-12 my-3'>
                           <div style={{height: '1px', backgroundColor: '#ccc'}}></div>
                        </div>
                        <div className=" col d-flex justify-content-start mt-3">
                            <button className="btn btn-success" onClick={handleUpdate}>
                                Submit
                            </button>
                        </div>
                       
                    </div>
                   
                </div>
            </section>
        </div>
    );
}


export default EditLandingPage;
