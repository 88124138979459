module.exports = {
  login_secret: {
    key: "pokemon",
  },

  RentDigiCarebackend_URL: "https://www.rentdigicare.com:9000",

  //production
  backend_URL: "https://www.gskproperties.ca:9000",
  cdn_URL: "",
  cdn_backend_URL: "https://gskproperties.ca:9000",
  video_cdn: "https://gskvideos.nyc3.digitaloceanspaces.com",

  //  development
  // 'backend_URL': "http://139.59.39.238:9000",
  // 'cdn_URL': "",
  // 'cdn_backend_URL': "http://139.59.39.238:9000",
  // 'video_cdn': "https://gskvideos.nyc3.digitaloceanspaces.com",

  //  localhost
  // backend_URL: "http://localhost:9000",
  // cdn_URL: "",
  // cdn_backend_URL: "http://localhost:9000",
  // cdn_backend_URL: "https://gskvideos.nyc3.digitaloceanspaces.com/compressed"
  // video_cdn: "https://gskvideos.nyc3.digitaloceanspaces.com",
};
