const helper = {
  setMetaTitle: function (title) {
    const el = document.querySelector("title");

    el.innerText = `GSK Properties | ${title}`;
  },

  setMetaKeyword: function (keyword) {
    const el = document.querySelector("meta[name='keyword']");
    el.setAttribute("content", keyword);
  },

  setMetaDesc: function (desc) {
    const el = document.querySelector("meta[name='description']");
    el.setAttribute("content", desc);
  },
};

export default helper;
