import React from "react";
import config from "../config.js";
import { Header } from "../components";

const axios = require("axios");
class HeaderFront extends React.Component {
  state = {
    facebook_link: "",
    logo: "",
  };

  componentDidMount() {
    this.getSettings();
  }

  getSettings = () => {
    axios
      .get(`${config.backend_URL}/admin/getSettings`)
      .then(async (responseJson) => {
        await this.setState({
          facebook_link: responseJson.data.data.facebook_link,
          logo: responseJson.data.data.logo,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <>
        {this.props.changeMainLoader ? (
          <Header
            fblink={this.state.facebook_link}
            logo={this.state.logo}
            changeMainLoader={this.props.changeMainLoader}
            mainLoader= {this.props.mainLoader}
          />
        ) : (
          <Header fblink={this.state.facebook_link} logo={this.state.logo} />
        )}
      </>
    );
  }
}
export default HeaderFront;
