import React from "react";
import { withRouter } from "react-router-dom";
import config from "../../config.js";
import Links from "./includes/Links.js";
import Loader from "../../loader.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import jwt from "jsonwebtoken";

const axios = require("axios");


class ForgotPassword extends React.Component {
  state = {
    loader_flag: false,

    showError: false,
    Email: "",
    errorMsg: "",
    color: "",
  };

  mySubmit = async (e) => {
    e.preventDefault();
    if (!this.state.Email) {
      this.setState({ showError: true });
      this.setState({ errorMsg: "Cannot be empty" });
      this.setState({ color: "red" });
    } else {
      this.setState({ showError: false });
    }
    if (this.state.Email) {
      if (
        !this.state.Email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)
      ) {
        this.setState({ showError: true });
        this.setState({ errorMsg: "Invalid Email!" });
      } else {
        axios
          .post(`${config.backend_URL}/admin/reset-link`, {
            email: this.state.Email,
          })
          .then((responseJson) => {
            if (responseJson.data.status === 200) {
              this.setState({ showError: true });
              this.setState({ color: "green" });
              this.setState({ errorMsg: responseJson.data.message });
            }
            if (responseJson.data.status === 404) {
              this.setState({ showError: true });
              this.setState({ color: "red" });
              this.setState({ errorMsg: responseJson.data.message });
            }
          });
      }
    }
  };

  render() {
    return (
      <>
        <Links />

        <ToastContainer />
        {this.state.loader_flag ? (
         <><Loader /></>
        ) : (
          <>
            <div class="appHdr">
              <div class="loginCenter">
                <div class="login-box">
                  <div class="login-logo">
                    <a href="/admin">
                      <b>GSK Properties</b>
                    </a>
                  </div>
                  <div class="card">
                    <div class="card-body login-card-body">

                      <p class="login-box-msg">
                        Please fill the email below to reset your password
                      </p>
                      <form onSubmit={this.mySubmit}>
                        <div class="mb-3">
                          <div class="input-group">
                            <input
                              type="emaill"
                              className="form-control"
                              placeholder="Enter Your Email"
                              name="forgotEmail"
                              onChange={(e) =>
                                this.setState({
                                  Email: e.target.value,
                                })
                              }
                            />
                            <div class="input-group-append">
                              <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                              </div>
                            </div>
                          </div>
                          {this.state.showError ? (
                            <p
                              id="errorMsg"
                              className="text-center"
                              style={{ color: this.state.color }}
                            >
                              {this.state.errorMsg}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>

                        <div class="row d-flex align-items-center justify-content-center">
                          <div class="col-xl-6">
                            <button
                              type="submit"
                              class="btn btn-primary btn-block"
                            >
                              Reset Password
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
export default withRouter(ForgotPassword);


// export default ForgotPassword;
