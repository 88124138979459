import React from "react";
import config from "../config.js";
import ReactStars from "react-rating-stars-component";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFlip,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
SwiperCore.use([EffectFlip, Navigation, Pagination]);

const axios = require("axios");
class Testimonials_home extends React.Component {
  state = {
    data: [],
    google_rating: 0,
    facebook_rating: 0,
    status: false,
  };

  componentDidMount() {
    this.getTestimonials();
    this.getSettings();
  }

  getTestimonials = () => {
    axios
      .post(`${config.backend_URL}/admin/getTestimonials`)
      .then(async (responseJson) => {
        await this.setState({ data: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getSettings = () => {
    axios
      .get(`${config.backend_URL}/admin/getSettings`)
      .then((responseJson) => {
        this.setState({
          google_rating: responseJson.data.data.google_rating,
          facebook_rating: responseJson.data.data.facebook_rating,
          google_page_link: responseJson.data.data.google_page_link,
          facebook_page_link: responseJson.data.data.facebook_page_link,
          google_text: responseJson.data.data.google_text,
          facebook_text: responseJson.data.data.facebook_text,
          status: true,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getRatingsStar = (stars, flag) => {
    let html = [];
    for (var i = 0; i < stars; i++) {
      if (flag === "fb") {
        html.push(
          <i>
            <img src={config.cdn_URL + "/images/rateIconBlue.png"} alt="fb" />
          </i>
        );
      } else {
        html.push(
          <i>
            <img src={config.cdn_URL + "/images/rateIconOrange.png"} alt="google" />
          </i>
        );
      }
    }

    return html;
  };

  render() {
    const imgErrorPath = config.cdn_URL + "/no-img.png";

    return (
      <>
        {this.state.status == true ? (
          <>
            <article className="reviewsBlock wrapper py-80">
              <div className="reviewsBefore" style={{backgroundImage: `url(${config.cdn_URL + "/images/propertiesBg1.png"})`}}></div>
              <div className="container">
                <div className="rvwsHdrDiv">
                  <div className="row g-md-5 g-4 align-items-center">
                    <div className="col-md">
                      <div className="mainHeading m-0">
                        <h2>GSK Happy Clients</h2>
                        <div className="hdngBrdrs">
                          <span className="hdngBrdr1"></span>
                          <span className="hdngBrdr2"></span>
                          <span className="hdngBrdr3"></span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-auto">
                      <div className="reviewRatingDiv">
                        <div className="reviewRatingRow">
                          <a
                            href={this.state.google_page_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="rvwRatingCol">
                              <div className="rvwRatingIcon">
                                <img src={config.cdn_URL + "/images/googleIcon.png"} alt="google" />
                              </div>
                              <div className="rvwRatingDes">
                                <div className="rvwRatingTtl">
                                  Google Rating
                                </div>
                                <div className="rvwRatingScore">
                                  <span> {this.state.google_rating} </span>
                                  <ReactStars
                                    count={5}
                                    size={32}
                                    isHalf={true}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={
                                      <i className="fa fa-star-half-alt"></i>
                                    }
                                    fullIcon={<i className="fa fa-star"></i>}
                                    activeColor="#db6b0d"
                                    edit={false}
                                    value={this.state.google_rating}
                                  />
                                  {/* <div class="rvwRatingStars">
                                    {this.getRatingsStar(
                                      this.state.google_rating,
                                      "google"
                                    )}
                                  </div> */}
                                </div>

                                <div className="rvwRatingNote">
                                  <p> {this.state.google_text} </p>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a
                            href={this.state.facebook_page_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="rvwRatingCol">
                              <div className="rvwRatingIcon">
                                <img src={config.cdn_URL + "/images/facebookIcon.png"} alt="fb" />
                              </div>
                              <div className="rvwRatingDes">
                                <div className="rvwRatingTtl">
                                  Facebook Rating
                                </div>
                                <div className="rvwRatingScore">
                                  <span>{this.state.facebook_rating}</span>
                                  <ReactStars
                                    count={5}
                                    size={32}
                                    isHalf={true}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={
                                      <i className="fa fa-star-half-alt"></i>
                                    }
                                    fullIcon={<i className="fa fa-star"></i>}
                                    activeColor="#0d4880"
                                    edit={false}
                                    value={this.state.facebook_rating}
                                  />
                                  {/*  <div className="rvwRatingStars">
                                    {this.getRatingsStar(
                                      this.state.facebook_rating,
                                      "fb"
                                    )}
                                  </div> */}
                                </div>
                                <div className="rvwRatingNote">
                                  <p> {this.state.facebook_text} </p>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="reviewSliderOuter">
                  <div className="reviewSlider crslCntrls">
                    <Swiper
                      modules={[Navigation, Pagination, Scrollbar, A11y]}
                      navigation={this.state.data.length > 3 ? true : false}
                      spaceBetween={80}
                      slidesPerView={3}
                      centeredSlides={false}
                      loop={true}
                      autoplay={{ delay: 3000 }}
                      breakpoints={{
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        576: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        768: {
                          slidesPerView: 2,
                          spaceBetween: 60,
                        },
                        1200: {
                          slidesPerView: 3,
                          spaceBetween: 80,
                        },
                      }}
                    >
                      {this.state.data.length > 0
                        ? this.state.data.map((x, key) => {
                            let imgPath = config.cdn_backend_URL + "/" + x.image;
                            return (
                              <SwiperSlide>
                                <div className="reviewSlide">
                                  <div className="reviewContent">
                                    <p>
                                      “
                                      {x.desc.length > 365
                                        ? x.desc.substring(0, 365) + "..."
                                        : x.desc}
                                      ”
                                    </p>
                                  </div>
                                  <div className="reviewTitle">
                                    <h4>
                                      {x.name.substring(
                                        0,
                                        x.name.indexOf(" ") + 2
                                      )}
                                    </h4>
                                  </div>
                                  <div className="reviewAuthor">
                                    <span>
                                      <img
                                        src={imgPath}
                                        alt="testimonial"
                                        onError={({ currentTarget }) => {
                                          currentTarget.onerror = null;
                                          currentTarget.src = imgErrorPath;
                                        }}
                                      />
                                    </span>
                                  </div>
                                </div>
                              </SwiperSlide>
                            );
                          })
                        : ""}
                    </Swiper>
                  </div>
                </div>
              </div>
            </article>
          </>
        ) : (
          <> </>
        )}
      </>
    );
  }
}

export default Testimonials_home;
