import React from "react";
import config from "../config.js";
import {
    Footer,
} from "../components";
  
const axios = require("axios");
class FooterFront extends React.Component{
    state={
        facebook_link:"",
    }

    componentDidMount(){
        this.getSettings(); 
    } 
    
    getSettings = () => {
        axios.get(`${config.backend_URL}/admin/getSettings`)      
          .then(async (responseJson) => {
              await this.setState({facebook_link: responseJson.data.data.facebook_link})
          })
          .catch((error) => {
              console.error(error);
          });        
    }

    render(){
        return(
            <>
              <Footer fblink={this.state.facebook_link}/>
            </>
        )
    }
}
export default FooterFront;