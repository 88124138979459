import React from "react";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import Loader from "../loader.js";
import helper from "../helper.js";
import config from "../config.js";
import { HeaderFront, FooterFront } from "../components";
import Testimonials_Loop from "./Testimonials_Loop.js";
const axios = require("axios");

class Testimonials extends React.Component {
  state = {
    loader_flag: false,
    data: [],
  };

  componentDidMount() {
    this.getTestimonialProperties();
    this.getSettings();
    this.getMetaTags("testimonials");
  }
  // Meta Tags Set Sart
  getMetaTags = (page_name) => {
    axios
      .post(`${config.backend_URL}/admin/getMetaTags`, { page_name: page_name })
      .then((responseJson) => {
        //set Meta Tags
        helper.setMetaTitle(responseJson.data.data.meta_title);
        helper.setMetaDesc(responseJson.data.data.meta_desc);
        helper.setMetaKeyword(responseJson.data.data.meta_keyword);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getTestimonialProperties = () => {
    this.setState({ loader_flag: true });
    axios
      .post(`${config.backend_URL}/admin/getTestimonialProperties`, {})
      .then(async (responseJson) => {
        await this.setState({
          data: responseJson.data.data,
          loader_flag: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loader_flag: false });
      });
  };

  getSettings = () => {
    axios
      .get(`${config.backend_URL}/admin/getSettings`)
      .then((responseJson) => {
        this.setState({
          google_rating: responseJson.data.data.google_rating,
          facebook_rating: responseJson.data.data.facebook_rating,
          google_page_link: responseJson.data.data.google_page_link,
          facebook_page_link: responseJson.data.data.facebook_page_link,
          google_text: responseJson.data.data.google_text,
          facebook_text: responseJson.data.data.facebook_text,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getRatingsStar = (stars, flag) => {
    let html = [];
    for (var i = 0; i < stars; i++) {
      if (flag === "fb") {
        html.push(
          <i>
            <img src={config.cdn_URL + "/images/rateIconBlue.png"} alt="fb" />
          </i>
        );
      } else {
        html.push(
          <i>
            <img src={config.cdn_URL + "/images/rateIconOrange.png"} alt="google" />
          </i>
        );
      }
    }

    return html;
  };

  render() {
    return (
      <>
        <HeaderFront/>
        <div className="exceptAppWrapper">
          {this.state.loader_flag ? (
            <><Loader /></>
          ) : (
            <section>
              <article
                className="pageHdrBlock wrapper py-80"
                style={{ backgroundImage: `url(${config.cdn_URL + "/images/subpageBg.jpg"})` }}
              >
                <div className="pageHdrOverlay"></div>
                <div className="container">
                  <div className="row align-items-center justify-space-beetween">
                    <div className="col-sm">
                      <h3>Testimonials</h3>
                    </div>
                    <div className="col-sm-auto">
                      <div className="pagebrdcrmbs">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to="/">Home</Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              Testimonials
                            </li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              <article className="testiHeadingBlock wrapper py-60" style={{backgroundImage: `url(${config.cdn_URL + "/images/propertiesBg.png"})`}}>
                <div className="container">
                  <div className="rvwsHdrDiv">
                    <div className="row g-md-5 g-4 align-items-center">
                      <div className="col-md">
                        <div className="mainHeading m-0">
                          <h2>GSK Happy Clients</h2>
                          <div className="hdngBrdrs">
                            <span className="hdngBrdr1"></span>
                            <span className="hdngBrdr2"></span>
                            <span className="hdngBrdr3"></span>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-auto">
                        <div className="reviewRatingDiv">
                          <div className="reviewRatingRow">
                            <a href={this.state.google_page_link}>
                              <div className="rvwRatingCol">
                                <div className="rvwRatingIcon">
                                  <img src={config.cdn_URL + "/images/googleIcon.png"} alt="google" />
                                </div>

                                <div className="rvwRatingDes">
                                  <div className="rvwRatingTtl">
                                    Google Rating
                                  </div>
                                  <div className="rvwRatingScore">
                                    <span> {this.state.google_rating} </span>
                                    <div class="rvwRatingStars">
                                      {this.getRatingsStar(
                                        this.state.google_rating,
                                        "google"
                                      )}
                                    </div>
                                  </div>
                                  <div className="rvwRatingNote">
                                    <p> {this.state.google_text} </p>
                                  </div>
                                </div>
                              </div>
                            </a>

                            <a href={this.state.facebook_page_link}>
                              <div className="rvwRatingCol">
                                <div className="rvwRatingIcon">
                                  <img src={config.cdn_URL + "/images/facebookIcon.png"} alt="fb" />
                                </div>
                                <div className="rvwRatingDes">
                                  <div className="rvwRatingTtl">
                                    Facebook Rating
                                  </div>
                                  <div className="rvwRatingScore">
                                    <span>{this.state.facebook_rating}</span>
                                    <div className="rvwRatingStars">
                                      {this.getRatingsStar(
                                        this.state.facebook_rating,
                                        "fb"
                                      )}
                                    </div>
                                  </div>
                                  <div className="rvwRatingNote">
                                    <p> {this.state.facebook_text} </p>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <article className="testimonialBlock wrapper">
                {this.state.data.length > 0
                  ? this.state.data.map((x, key) => {
                      let imagePath1 =
                        config.cdn_backend_URL + "/" + x.property_image;
                      let imagePath =
                        x.property_image === ""
                          ? `${config.cdn_backend_URL}/default.jpg`
                          : imagePath1;

                      return key % 2 === 0 ? (
                        <div className="testimonialRow py-60">
                          <div className="container">
                            <div className="row g-md-5 g-4 align-items-center">
                              <div className="col-md-6">
                                <div className="testiMainDiv">
                                  <div className="mainHeading">
                                    <h5>Customers reviews</h5>
                                    <h2>
                                      About <span>{x.property_name}</span>
                                    </h2>
                                    <div className="hdngBrdrs">
                                      <span className="hdngBrdr1"></span>
                                      <span className="hdngBrdr2"></span>
                                      <span className="hdngBrdr3"></span>
                                    </div>
                                  </div>
                                  <div className="testiSliderOuter">
                                    <div className="testimonialSlider crslCntrls">
                                      <Testimonials_Loop testimonils={x.main} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6"></div>
                            </div>
                          </div>
                          <div
                            className="testiMainImg"
                            style={{ backgroundImage: `url(${imagePath})` }}
                          ></div>
                        </div>
                      ) : (
                        <div className="testimonialRow py-60">
                          <div className="container">
                            <div className="row g-md-5 g-4 align-items-center">
                              <div className="col-md-6">
                                <div className="testiMainDiv">
                                  <div className="mainHeading">
                                    <h5>Customers reviews</h5>
                                    <h2>
                                      About <span>{x.property_name}</span>
                                    </h2>
                                    <div className="hdngBrdrs">
                                      <span className="hdngBrdr1"></span>
                                      <span className="hdngBrdr2"></span>
                                      <span className="hdngBrdr3"></span>
                                    </div>
                                  </div>
                                  <div className="testiSliderOuter">
                                    <div className="testiSliderOuter testimonialSlider crslCntrls">
                                      <Testimonials_Loop testimonils={x.main} />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6"></div>
                            </div>
                          </div>
                          <div
                            className="testiMainImg"
                            style={{ backgroundImage: `url(${imagePath})` }}
                          ></div>
                        </div>
                      );
                    })
                  : ""}
              </article>
            </section>
          )}
        </div>
        <FooterFront/>
      </>
    );
  }
}

export default Testimonials;
