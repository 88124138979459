import React from "react";
import { withRouter } from "react-router-dom";
import Links from "./Links.js";

class Header extends React.Component {
  logoutAdmin = () => {
    localStorage.clear();
    this.props.history.push("/login");
  };
  render() {
    return (
      <>
        <Links />
        <nav class="main-header d-sm-flex justify-content-end navbar navbar-expand navbar-white navbar-light">
          <ul class="navbar-nav">
            <li class="nav-item d-none d-sm-inline-block">
              <a
                href="#!"
                class="nav-link"
                onClick={() => {
                  this.logoutAdmin();
                }}
              >
                Logout
              </a>
            </li>
          </ul>
        </nav>
      </>
    );
  }
}

export default withRouter(Header);
