import React, { useEffect, useState } from "react";
import config from "../config.js";
import { Button, } from "react-bootstrap";

export default function HeaderLanding(props) {

  const [MenuToggle, setMenuToggle] = useState(false)


  const handleMenuShow = () => {
    setMenuToggle(!MenuToggle)
  }


  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const currentScroll = window.pageYOffset;
      if (currentScroll > 150) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  return (
    <div className={`w-100 bg-white landingHeaderMenu ${isSticky ? 'is-sticky' : ''}`}>
      <nav class={`landingHeader navbar navbar-expand-lg  ` } id="duple">
        <a class="navbar-brand" href="#">
          <img src="https://www.gskproperties.ca:9000/file_1651815945633.png" alt="logo" />
        </a>
        <button
          onClick={handleMenuShow}
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNavDropdown"
          aria-controls="navbarNavDropdown"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse desktopMenu" id="navbarNavDropdown">
            <ul class="navbar-nav ms-auto">
              <li class="nav-item active">
                <a class="nav-link" href="https://www.gskproperties.ca/" target="_blank">
                  Home <span class="sr-only"></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" target="_blank" href="https://www.gskproperties.ca/why-choose-us">
                Choose Us
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" target="_blank" href="https://www.gskproperties.ca/alberta">
                Properties
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" target="_blank" href="https://www.gskproperties.ca/blog">
                Blog  
                </a>
              </li>
              {/* <li class="nav-item">
                <a class="nav-link" href="#">
                Contact Us  
                </a>
              </li> */}
            
            </ul>
            <Button
              variant="primary"
              className="btn comnButton"
              onClick={() => {
                localStorage.setItem('AppointmentModal', true);
                window.location.href = '/'
              }}
              style={{borderRadius: '100px', fontSize: '16px'}}
            >
              Book An Appointment
            </Button>
        </div>
        {MenuToggle &&
          <div class="collapse navbar-collapse mobMenu" id="navbarNavDropdown">
            <ul class="navbar-nav mx-auto">
              <li class="nav-item active">
                <a class="nav-link" href="https://www.gskproperties.ca/" target="_blank">
                  Home <span class="sr-only"></span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" target="_blank" href="https://www.gskproperties.ca/why-choose-us">
                Choose Us
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" target="_blank" href="https://www.gskproperties.ca/alberta">
                Properties
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" target="_blank" href="https://www.gskproperties.ca/gskblog/blog">
                Blog  
                </a>
              </li>
              {/* <li class="nav-item">
                <a class="nav-link" href="#">
                Contact Us  
                </a>
              </li> */}
            
            </ul>
            <Button
              variant="primary"
              className="btn comnButton"
              onClick={() => {
                localStorage.setItem('AppointmentModal', true);
                window.location.href = '/'
              }}
              style={{borderRadius: '100px', fontSize: '16px'}}
            >
              Book An Appointment
            </Button>
          </div>
        }
        <div>
        
        </div>
      </nav>
    </div>
  );
}



