import $ from "jquery";
import React from "react";
import { Button, Modal, Offcanvas } from "react-bootstrap";
import { Link, NavLink, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import config from "../config.js";
import Loader from "../loader.js";
const axios = require("axios");
const _ = require("lodash");
class Header extends React.Component {
  state = {
    bedroomOptn: [],
    flag: "property",
    name: "",
    number: "",
    email: "",
    property: "",
    bedroom: "",
    bedroomNo: "",
    message: "",
    errors: [],
    data: [],
    properties: [],
    allProperties: [],
    bedroomData: [],
    submitBtn: true,
    meta_title: "",
    loader_flag: true,
    pageLoaded: false,
  };

  initialState = {
    bedroomOptn: 0,
    flag: "property",
    name: "",
    number: "",
    email: "",
    property: "",
    bedroom: "",
    message: "",
    errors: [],
    submitBtn: true,
    show: false,
    visitorShow: false,
  };
  
  componentDidMount = () => {
    this.getCities();
    this.getProperties1();
    this.getBedroom();
    this._headerScroll();
    const isAppointmentModalVisible = localStorage.getItem('AppointmentModal') === 'true';
    this.setState({
      show: isAppointmentModalVisible,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    console.log(this.state.loader_flag,'<<<<<<<<<<<<<<<<',prevState ,">>>>>>>>>>>>", this.props);
    console.log('this.state.loader_flag',this.state.loader_flag,prevState.loader_flag, this.props?.mainLoader)
    if (this.state.loader_flag !== prevState.loader_flag) {
      this.props?.changeMainLoader &&
        this.props.changeMainLoader(this.state.loader_flag);
    }
  }

  _headerScroll() {
    const header = document.getElementById("myHeader");
    const sticky = header.offsetTop;
    const scrollCallBack = window.addEventListener("scroll", () => {
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
      } else {
        header.classList.remove("sticky");
      }
    });
    return () => {
      window.removeEventListener("scroll", scrollCallBack);
    };
  }

  getBedroom = () => {
    this.setState({ loader_flag: true });
    axios
      .get(`${config.backend_URL}/admin/getBedroom`)
      .then((responseJson) => {
        console.log(responseJson.data.data);
        // return
        this.setState({ bedroomData: responseJson.data.data });
        this.setState({ loader_flag: false });
      })
      .catch((error) => {
        this.setState({ loader_flag: false });
        console.error(error);
      });
  };
  searchOptions = () => {
    axios
      .post(`${config.backend_URL}/admin/searchOptions`)
      .then((responseJson) => {
        this.setState({
          bedroomOptn: responseJson.data.data[0].bedroom.sort(),
        });

        console.log("aaaaaa", this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // shouldComponentUpdate(nextProps) {
  //       console.log("Gfdggfdg");
  //       this.setState({ meta_title: '' })
  //       if (nextProps !== this.props) {
  //         return true;
  //       } else {
  //         return false;
  //       }

  // }

  getCities = () => {
    this.setState({ loader_flag: true });
    axios
      .get(`${config.backend_URL}/admin/getCities`)
      .then((responseJson) => {
        const sortedCities = _.sortBy(responseJson.data.data, "city");
        this.setState({ data: sortedCities });
        this.setState({ loader_flag: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loader_flag: false });
      });
  };

  getProperties = (key) => {
    let newObj = {};
    newObj = { slug: key };
    this.setState({ properties: [] });
    axios
      .post(`${config.backend_URL}/admin/getProperties`, newObj)
      .then((responseJson) => {
        const sortedProperties = _.sortBy(
          responseJson.data.data,
          "property_name"
        );
        this.setState({ properties: sortedProperties });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getProperties1 = () => {
    this.setState({ loader_flag: true });
    axios
      .post(`${config.backend_URL}/admin/getProperties`)
      .then((responseJson) => {
        this.setState({ allProperties: responseJson.data.data });
        this.setState({ loader_flag: false });
      })
      .catch((error) => {
        this.setState({ loader_flag: false });
        console.error(error);
      });
  };
  changedata = (e) => {
    if (e.target.name === "property") {
      let key = e.target[e.target.selectedIndex].getAttribute("data-key");
      let propertyId = e.target[e.target.selectedIndex].getAttribute("data-id");

      let managerEmails = this.state.allProperties[key].manager_emails;

      let manager_email = managerEmails
        .map(function (elem) {
          return elem.manager_email;
        })
        .join(",");

      this.getPropertyBedroom(propertyId);

      this.setState({ manager_email: manager_email, propertyId: propertyId });
    }

    if (e.target.name === "bedroom") {
      const data = JSON.parse(e.target.value);
      this.setState({ bedroom: data.id });
      this.setState({ bedroomNo: data.number });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  getPropertyBedroom = (propertyId) => {
    axios
      .post(`${config.backend_URL}/admin/getPropertyBedroom`, {
        _id: propertyId,
      })
      .then((responseJson) => {
        console.log("qqqq", responseJson.data.data);
        this.setState({ bedroomOptn: responseJson.data.data.bedroomArr });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  mySubmit = (e) => {
    e.preventDefault();
    let error = 0;
    let arry = "";
    if (this.state.name === "") {
      arry += "Name can not be empty* ";
      toast("Name can not be empty* ");
      error++;
    }

    if (this.state.number === "") {
      arry += "Number can not be empty* ";
      toast("Number can not be empty* ");
      error++;
    }
    if (this.state.email === "") {
      arry += "Email can not be empty* ";
      toast("Email can not be empty* ");
      error++;
    }

    if (this.state.property === "") {
      toast("Property can not be empty* ");
      error++;
    }
    if (this.state.bedroom === "") {
      toast("Bedroom can not be empty* ");
      error++;
    }

    if (this.state.message === "") {
      arry += "Message can not be empty* ";
      toast("Message can not be empty* ");
      error++;
    }
    console.log(error);

    this.setState({ submitBtn: false });

    //this.setState({ errors: arry })
    if (error > 0) {
      $("#error").html(arry);
      //toast(arry)
    } else {
      $("#error").html("");

      let newObj = {
        propertyId: this.state.propertyId,
        property_name: this.state.property,
        manager_email: this.state.manager_email,
        flag: this.state.flag,
        name: this.state.name,
        number: this.state.number,
        email: this.state.email,
        bedroom: this.state.bedroom,
        bedroomNo: this.state.bedroomNo,
        message: this.state.message,
      };
      this.setState({ loader_flag: true });
      axios
        .post(`${config.backend_URL}/admin/addFeedbacks`, newObj)
        .then(async (data) => {
          console.log(data);

          if (data.data.status === true) {
            toast(
              "Thank you for Choosing GSK Properties Your message has been delivered and our team will contact you within 24 hours."
            );

            await this.setState(this.initialState);
          } else {
            toast("Something wrong!");
          }
          this.setState({ loader_flag: false });
        })
        .catch((err) => {
          console.log("error", err);
          console.log("api flag change 1");
          this.setState({ loader_flag: false });
        });
    }
  };

  handleClose = () => { this.setState({ show: false }); localStorage.setItem("AppointmentModal", 'false') }
  handleShow = () => this.setState({ show: true });

  handleVisitorClose = () => this.setState({ visitorShow: false });
  handleVisitorShow = () => this.setState({ visitorShow: true });

  handleMenuClose = () => this.setState({ menuShow: false });
  handleMenuShow = () => this.setState({ menuShow: true });

  mySubmit1 = (e) => {
    e.preventDefault();
    let error = 0;
    let arry = "";
    if (this.state.name === "") {
      arry += "Name can not be empty* ";
      toast("Name can not be empty* ");
      error++;
    }
    if (this.state.number === "") {
      arry += "Number can not be empty* ";
      toast("Number can not be empty* ");
      error++;
    }
    if (this.state.email === "") {
      arry += "Email can not be empty* ";
      toast("Email can not be empty* ");
      error++;
    }
    if (this.state.date === "") {
      arry += "Date can not be empty* ";
      toast("Date can not be empty* ");
      error++;
    }
    if (this.state.from_time === "" || this.state.end_time === "") {
      arry += "Time can not be empty* ";
      toast("Time can not be empty* ");
      error++;
    }
    if (this.state.propertyId === "") {
      toast("Property can not be empty* ");
      error++;
    }
    if (this.state.car_make === "") {
      arry += "Car Make can not be empty* ";
      toast("Car Make can not be empty* ");
      error++;
    }
    if (this.state.car_model === "") {
      arry += "Car Model can not be empty* ";
      toast("Car Model can not be empty* ");
      error++;
    }
    if (this.state.plate_number === "") {
      arry += "Car Plate Number can not be empty* ";
      toast("Car Plate Number can not be empty* ");
      error++;
    }
    if (this.state.visitor_parking_stall_number === "") {
      arry += "Visitor Parking Stall Number can not be empty* ";
      toast("Visitor Parking Stall Number can not be empty* ");
      error++;
    }
    if (this.state.visiting_suite_number === "") {
      arry += "Visiting Suite Number can not be empty* ";
      toast("Visiting Suite Number can not be empty* ");
      error++;
    }

    // console.log(error)
    if (error > 0) {
      $("#error").html(arry);
      //toast(arry)
    } else {
      this.setState({ submitBtn: false });
      $("#error").html("");

      let { allProperties, visitorShow, submitBtn, ...newObj } = this.state;
      this.setState({ loader_flag: true });
      axios
        .post(`${config.backend_URL}/admin/addVisitorParking`, newObj)
        .then(async (data) => {
          console.log(data);
          if (data.data.status === true) {
            toast("Thanks, your request has been submitted.");
            await this.setState(this.initialState);
          } else {
            toast("Something wrong!");
          }
          this.setState({ loader_flag: false });
        })
        .catch((err) => {
          this.setState({ loader_flag: false });
          console.log("error", err);
        });
    }
  };

  render() { 
    const { location } = this.props;
    return (
      <>
        <ToastContainer />
        <link
          href={config.cdn_URL + "/css/font-awesome.css"}
          rel="stylesheet"
        />
        <link
          rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
          integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
          crossorigin="anonymous"
        />
        <link
          rel="stylesheet"
          href={config.cdn_URL + "/css/swiper-bundle.min.css"}
        />

        <link href="/css/style.css" rel="stylesheet" crossorigin="anonymous" />
        <link
          href={config.cdn_URL + "/css/responsive.css"}
          rel="stylesheet"
          crossorigin="anonymous"
        />
        <link
          href="css/responsive.css"
          rel="stylesheet"
          crossorigin="anonymous"
        />
        <header id="myHeader" className="mainHeader wrapper">
          {!this.props.mainLoader && (
            <div className="container">
              <div className="row align-items-center">
                <div className="col-auto">
                  <div className="hdrLogo">
                    <NavLink to="/">
                      <img
                        src={config.cdn_backend_URL + "/" + this.props.logo}
                        alt="logo"
                      />
                    </NavLink>
                  </div>
                </div>
                <div className="col">
                  <div className="mobileMenu d-block d-xl-none">
                    <div className="offcanvasBtn">
                      <Button variant="menuBar" onClick={this.handleMenuShow}>
                        <span className="bar1"></span>
                        <span className="bar2"></span>
                        <span className="bar3"></span>
                      </Button>
                    </div>
                  </div>
                  <div className="hdrMenu d-none d-xl-block">
                    <ul className="hdrMenuList">
                      <li  className={location.pathname === '/' ? 'menuActive' : ''}>
                        <NavLink exact to="/">Home</NavLink>
                      </li>
                      <li  className={location.pathname === '/' ? 'menuActive' : ''}>
                        <NavLink to="/why-choose-us">Choose Us</NavLink>
                      </li>
                      <li>
                        <NavLink to="/alberta">Properties</NavLink>
                        <ul>
                          {this.state.data.length > 0 ? (
                            this.state.data.map((x, key) => {
                              return x.propertyCount > 0 ? (
                                <li
                                  onMouseEnter={() => {
                                    this.getProperties(x.slug);
                                  }}
                                  key={x.slug}
                                >
                                  <Link to={`/alberta/${x.slug}`}>
                                    {x.city}
                                  </Link>
                                  <ul>
                                    {this.state.properties.length > 0 && (
                                      this.state.properties.map((y, key1) => {
                                        return (
                                          <li key={y.slug}>
                                            <Link
                                              onClick={() =>
                                                window.location.replace(
                                                  `/alberta/${x.slug}/${y.slug}`
                                                )
                                              }
                                            >
                                              {y.property_name}
                                            </Link>
                                          </li>
                                        );
                                      })
                                    )}
                                  </ul>
                                </li>
                              ) : (
                                ""
                              );
                            })
                          ) : (
                            <>''</>
                          )}
                        </ul>
                      </li>
                      <li onClick={this.handleVisitorShow}>
                        <a href="javascript:void(0)" 
                        // style={{color:'#fff',fontWeight:'500',cursor:'pointer'}}
                        >Visitor Parking</a>
                       
                        {/* <a
                          href="javascript:void(0)"
                          onClick={this.handleVisitorShow}
                        >
                          Visitor Parking
                        </a> */}
                      </li>

                      <li>
                        
                        <a
                          href="https://app.rentdigicare.com/maintenance-request/gsk_properties"
                          target="_blank"
                        >
                          Request Maintenance
                        </a>
                      </li>
                      <li>
                        <NavLink to="/gskblog/blog">Blog</NavLink>
                      </li>
                      <li>
                        <NavLink  target={"_blank"} to="/gsk">Offers</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-auto d-none @d-xl-block">
                  <div className="hdrScl">
                    <a
                      href={`${this.props.fblink}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
        </header>

        {!this.props.mainLoader && (
          <>
            <Offcanvas
              className="offcanvasOuter"
              show={this.state.menuShow}
              onClick={this.handleMenuClose}
            >
              <Offcanvas.Header className="offcanvasHeader" closeButton>
                <Offcanvas.Title>
                  <div className="offcanvasLogo">
                    <NavLink to="/">
                      <img
                        src={config.cdn_backend_URL + "/" + this.props.logo}
                        alt="logo"
                      />
                    </NavLink>
                  </div>
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="offcanvasBody">
                <div className="offcanvasBody">
                  <div className="hdrMenu">
                    <ul>
                      <li>
                        <NavLink to="/">Home</NavLink>
                      </li>
                      <li>
                        <NavLink to="/why-choose-us">Choose Us</NavLink>
                      </li>
                      <li>
                        <NavLink to="/alberta">Properties</NavLink>
                      </li>
                      <li>
                        <a
                          href="javascript:void(0)"
                          onClick={this.handleVisitorShow}
                        >
                          Visitor Parking
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://app.rentdigicare.com/maintenance-request/gsk_properties"
                          target="_blank"
                        >
                          Request Maintenance
                        </a>
                      </li>
                      <li>
                        <a
                          href="/gskblog/blog"
                          // target="_blank"
                        >
                          Blog
                        </a>
                      </li>
                      <li>
                        <a
                          href="/gsk"
                          // target="_blank"
                        >
                          Offers
                        </a>
                      </li>
                      <li className="menuBtn">
                        <a
                          href="javascript:void(0)"
                          className="btnCommon w-100"
                          onClick={this.handleShow}
                        >
                          Book An Appointment
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="hdrScl">
                    <a
                      href={`${this.props.fblink}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                  </div>
                </div>
              </Offcanvas.Body>
            </Offcanvas>
            <Modal
              show={this.state.show}
              onHide={this.handleClose}
              dialogClassName="homeCntctDiv fedBackPopDialog"
            >
              <Modal.Header closeButton>
                <Modal.Title className="mdlHeading">
                  Book an Appointment
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="feedBackPopup appntmtnFeedBackPopup">
                  <div className="homeCntctForm">
                    <div className="mainHeading mb-2">
                      <p>Select the property you are inquiring about.</p>
                    </div>

                    <ToastContainer />
                    <form onSubmit={this.mySubmit}>
                      <div className="cntctBrdrTtl">APPOINTMENTS</div>
                      <div className="form-group">
                        <label>
                          Name <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/userIcon.png"}
                            alt="Name"
                          />
                        </i>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="Write Name"
                          onChange={this.changedata}
                          value={this.state.name}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Mobile Number <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/callIcon.png"}
                            alt="Mobile Number"
                          />
                        </i>
                        <input
                          className="form-control number"
                          type="text"
                          name="number"
                          placeholder="+1 _ _ _   _ _ _   _ _ _"
                          onChange={this.changedata}
                          value={this.state.number}
                          maxlength="10"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Email <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/mailIcon.png"}
                            alt="Email"
                          />
                        </i>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={this.changedata}
                          value={this.state.email}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Property <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/propertyIcon.png"}
                            alt="Property"
                          />
                        </i>
                        <select
                          className="form-control"
                          name="property"
                          onChange={this.changedata}
                          value={this.state.property}
                          required
                        >
                          <option value="">Select</option>
                          {this.state.allProperties.map((y, key1) => {
                            return (
                              <option
                                value={y.property_name}
                                data-key={key1}
                                data-id={y._id}
                              >
                                {y.property_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>

                      <div className="form-group">
                        <label>
                          Bedroom <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/bedroomIcon.png"}
                            alt="Bedroom"
                          />
                        </i>
                        <select
                          className="form-control"
                          name="bedroom"
                          onChange={this.changedata}
                          required
                        >
                          <option value="">Select</option>
                          {this.state.bedroomOptn.length > 0
                            ? this.state.bedroomData
                                .sort((a, b) => a - b)
                                .map((x, key) => {
                                  var tm = this.state.bedroomOptn.includes(
                                    x._id
                                  )
                                    ? x.bedroom
                                    : "hidden";
                                  var mt =
                                    tm != "hidden" ? (
                                      <option
                                        value={`{"id": "${x._id}", "number": ${tm}}`}
                                      >
                                        {" "}
                                        {tm}{" "}
                                      </option>
                                    ) : (
                                      ""
                                    );
                                  return mt;
                                })
                            : ""}
                        </select>
                      </div>

                      <div className="form-group formGroupTextarea">
                        <label>
                          Message Me <span className="astrict">*</span>
                        </label>
                        <textarea
                          className="form-control"
                          type="text"
                          name="message"
                          placeholder="Hi, I’m happy with home."
                          onChange={this.changedata}
                          value={this.state.message}
                        ></textarea>
                      </div>
                      <div className="form-btn">
                        <button
                          className="btn btnCommon w-100"
                          type="Submit"
                          disabled={!this.state.submitBtn}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
            <Modal
              show={this.state.visitorShow}
              onHide={this.handleVisitorClose}
              dialogClassName="homeCntctDiv fedBackPopDialog"
            >
              <Modal.Header closeButton>
                <Modal.Title className="mdlHeading">
                  Visitor Parking
                </Modal.Title>
              </Modal.Header>

              <Modal.Body>
                <div className="feedBackPopup vstrParkingPopup">
                  <div className="homeCntctForm">
                    <div className="mainHeading mb-2">
                      <p>Select the property you are inquiring about.</p>
                    </div>

                    <ToastContainer />
                    <form onSubmit={this.mySubmit1}>
                      <div className="cntctBrdrTtl">VISITOR PARKING FORM</div>

                      <div className="form-group">
                        <label>
                          Property <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/propertyIcon.png"}
                            alt="Property"
                          />
                        </i>
                        <select
                          className="form-control"
                          name="property"
                          onChange={this.changedata}
                          value={this.state.property}
                          required
                        >
                          <option value="">Select</option>
                          {this.state.allProperties.map((y, key1) => {
                            return (
                              <option
                                value={y.property_name}
                                data-key={key1}
                                data-id={y._id}
                              >
                                {y.property_name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                      <div className="form-group">
                        <label>
                          Visiting Suite Number{" "}
                          <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={
                              config.cdn_URL +
                              "/images/visitorSuitNumberIcon.png"
                            }
                            alt="Visiting Suite Number"
                          />
                        </i>
                        <input
                          className="form-control"
                          type="text"
                          name="visiting_suite_number"
                          placeholder="Write Visiting Suite Number"
                          onChange={this.changedata}
                          value={this.state.visiting_suite_number}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Name <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/userIcon.png"}
                            alt="Name"
                          />
                        </i>
                        <input
                          className="form-control"
                          type="text"
                          name="name"
                          placeholder="Write Name"
                          onChange={this.changedata}
                          value={this.state.name}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Mobile Number <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/callIcon.png"}
                            alt="Mobile Number"
                          />
                        </i>
                        <input
                          className="form-control number"
                          type="text"
                          name="number"
                          placeholder="+1 _ _ _   _ _ _   _ _ _"
                          onChange={this.changedata}
                          value={this.state.number}
                          maxlength="10"
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Email <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/mailIcon.png"}
                            alt="Email"
                          />
                        </i>
                        <input
                          className="form-control"
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          onChange={this.changedata}
                          value={this.state.email}
                        />
                      </div>
                      <div className="form-group">
                        <label>
                          Visitor Parking Stall Number{" "}
                          <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/stallNumber.png"}
                            alt="Visitor Parking Stall Number"
                          />
                        </i>
                        <input
                          className="form-control"
                          type="text"
                          name="visitor_parking_stall_number"
                          placeholder="Write Visitor Parking Stall Number"
                          onChange={this.changedata}
                          value={this.state.visitor_parking_stall_number}
                        />
                      </div>

                      <div className="form-group pdRghtNo">
                        <label>
                          Select Date <span className="astrict">*</span>
                        </label>
                        <i className="noClick">
                          <img
                            src={config.cdn_URL + "/images/dateIcon.png"}
                            alt="Select Date"
                          />
                        </i>
                        <input
                          className="form-control"
                          type="date"
                          name="date"
                          onChange={this.changedata}
                          value={this.state.date}
                        />
                      </div>
                      <div class="row">
                        <div class="col-sm-6">
                          <div className="form-group pdRghtNo">
                            <label>
                              From <span className="astrict">*</span>
                            </label>
                            <i className="noClick">
                              <img
                                src={config.cdn_URL + "/images/timeIcon.png"}
                                alt="From"
                              />
                            </i>
                            <input
                              className="form-control"
                              type="time"
                              name="from_time"
                              onChange={this.changedata}
                              value={this.state.from_time}
                            />
                          </div>
                        </div>
                        <div class="col-sm-6">
                          <div className="form-group pdRghtNo">
                            <label>
                              To <span className="astrict">*</span>
                            </label>
                            <i className="noClick">
                              <img
                                src={config.cdn_URL + "/images/timeIcon.png"}
                                alt="To"
                              />
                            </i>
                            <input
                              className="form-control"
                              type="time"
                              name="end_time"
                              onChange={this.changedata}
                              value={this.state.end_time}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <label>
                          Car Make <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/carMakeIcon.png"}
                            alt="car Make"
                          />
                        </i>
                        <input
                          className="form-control"
                          type="text"
                          name="car_make"
                          placeholder="Write Car Make"
                          onChange={this.changedata}
                          value={this.state.car_make}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Car Model <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/carModelIcon.png"}
                            alt="car Model"
                          />
                        </i>
                        <input
                          className="form-control"
                          type="text"
                          name="car_model"
                          placeholder="Write Car Model"
                          onChange={this.changedata}
                          value={this.state.car_model}
                        />
                      </div>

                      <div className="form-group">
                        <label>
                          Plate Number <span className="astrict">*</span>
                        </label>
                        <i>
                          <img
                            src={config.cdn_URL + "/images/plateNumberIcon.png"}
                            alt="Plate Number"
                          />
                        </i>
                        <input
                          className="form-control"
                          type="text"
                          name="plate_number"
                          placeholder="Write Plate Number"
                          onChange={this.changedata}
                          value={this.state.plate_number}
                        />
                      </div>
                      <div className="form-btn">
                        <button
                          className="btn btnCommon w-100"
                          type="Submit"
                          disabled={!this.state.submitBtn}
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </Modal.Body>
            </Modal>
          </>
        )}

        {/*<Modal*/}
        {/*  show={this.state.solarShow}*/}
        {/*  onHide={this.handleSolarClose}*/}
        {/*  dialogClassName="solaPopupDialog fedBackPopDialog"*/}
        {/*>*/}
        {/*  <Modal.Header closeButton>*/}
        {/*      <Modal.Title className="mdlHeading d-none">Solar Homes Tour</Modal.Title>*/}
        {/*  </Modal.Header>*/}
        {/*  <Modal.Body>*/}
        {/*    <div className="solaPopup">*/}
        {/*      <div className="solaPopupHdr">*/}
        {/*        <div className="solaPopupTtl">*/}
        {/*          <h2>GSK Properties Now Introduces Solar Powered</h2>*/}
        {/*          <h3>Rental Homes in ST Albert - The first of its kind</h3>*/}
        {/*        </div>*/}
        {/*        <div className="solaPopupMediaOuter">*/}
        {/*          <div className="solaPopupMedia">*/}
        {/*            <img src="images/solarMainImg.jpg" />*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="solaPopupBody">*/}
        {/*        <div className="solaFeauList">*/}
        {/*          <Row className="g-4">*/}
        {/*            <Col md>*/}
        {/*                <div className="solaFeauCol mt-md-4 mt-xl-5">*/}
        {/*                  <span>*/}
        {/*                    <img src="images/solarFeauImg1.jpg" />*/}
        {/*                  </span>*/}
        {/*                  <p>GSK Properties leads the path towards Green Revolution</p>*/}
        {/*                </div>*/}
        {/*            </Col>*/}
        {/*            <Col md>*/}
        {/*              <div className="solaFeauCol">*/}
        {/*                <span>*/}
        {/*                  <img src="images/solarFeauImg2.jpg" />*/}
        {/*                </span>*/}
        {/*                <p>One of its kind GSK Properties introduces Environment Friendly Homes</p>*/}
        {/*              </div>*/}
        {/*            </Col>*/}
        {/*            <Col md>*/}
        {/*              <div className="solaFeauCol mt-md-4 mt-xl-5">*/}
        {/*                <span>*/}
        {/*                  <img src="images/solarFeauImg3.jpg" />*/}
        {/*                </span>*/}
        {/*                <p>Powering Canada's Future with Solar Energy</p>*/}
        {/*              </div>*/}
        {/*            </Col>*/}
        {/*          </Row>*/}
        {/*        </div>*/}
        {/*        <div className="solarSaving">*/}
        {/*          <i>*/}
        {/*            <img src="images/saveIcon.png" />*/}
        {/*          </i>*/}
        {/*          <div>*/}
        {/*            <h5>GSK solar powered rentals help to cap your utilities bill up to $400 per month.</h5>*/}
        {/*            <h4>That’s A Saving Of Whooping: 12*$400 = $4800 ( Approximately) Per Year!</h4>*/}
        {/*          </div>*/}
        {/*        </div>*/}
        {/*        <div className="solarBtn">*/}
        {/*          <a href="images/SolarVsRegular.pdf" className="btnCommon" target="_blank">Solar Homes Tour</a>*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*      <div className="solaPopupFtr">*/}
        {/*        <p>Save Money, Save The Earth, Go Solar with GSK Rental Homes</p>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </Modal.Body>*/}
        {/*</Modal>*/}
      </>
    );
  }
}
export default withRouter(Header);
