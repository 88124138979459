import React from "react";
import { withRouter } from "react-router-dom";
import config from "../../config.js";
import Loader from "../../loader.js";
import Pagination from "react-js-pagination";
import "react-toastify/dist/ReactToastify.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import axios from "axios";

class Feedback extends React.Component {
  state = {
    loader_flag: false,
    modalIsOpen: false,
    status: "",
    activePage: 1,
    totalItemsCount: 0,
    data: [],
    errors: [],
  };

  initialState = {
    loader_flag: false,
    modalIsOpen: false,
    activePage: 1,
    totalItemsCount: 0,
    status: "",
    data: [],
    errors: [],
  };

  componentDidMount = async () => {
    this.setState({ loader_flag: true });
    await this.getFeedbacks();
    this.setState({ loader_flag: false });
  };

  getFeedbacks = async () => {
    try {
      const response = await axios.post(
        `${config.backend_URL}/admin/getContactFeedbacks`,
        {
          page: this.state.activePage,
        }
      );
      this.setState({
        data: response.data.data,
        totalItemsCount: response.data.total,
      });
    } catch (error) {
      console.error(error);
    }
  };

  handlePageChange = async (pageNumber) => {
    this.setState({ loader_flag: true });
    await this.setState({ activePage: pageNumber });
    this.getFeedbacks();
    this.setState({ loader_flag: false });
  };

  changedata = async (e) => {
    const id = e.target.getAttribute("data-id");
    this.setState({ loader_flag: true });
    try {
      await axios.post(`${config.backend_URL}/admin/updateFeedbacks`, {
        id: id,
        status: e.target.value,
      });
      this.getFeedbacks();
    } catch (error) {
      console.error(error);
    } finally {
      this.setState({ loader_flag: false });
    }
  };

  render() {
    return (
      <>
        {this.state.loader_flag ? (
          <Loader />
        ) : (
          <div className="content-wrapper">
            <section className="content-header">
              <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Contact Feedbacks</h1>
                  </div>
                  <div className="col-sm-6"></div>
                </div>
              </div>
            </section>

            <section className="content">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="card card-primary">
                      <div className="card-header">
                        <h3 className="card-title">Contact Feedbacks List</h3>
                      </div>
                      <div className="card-body">
                        <div className="table-responsive" style={{fontSize:"0.7rem"}}>
                          <table className="table table-bordered table-striped">
                            <thead>
                              <tr>
                                <th style={{ maxWidth: "3%" }}>S.No.</th>
                                <th style={{ maxWidth: "12%" }}>Name</th>
                                <th style={{ maxWidth: "8%" }}>Mobile</th>
                                <th style={{ maxWidth: "10%" }}>Email</th>
                                <th style={{ maxWidth: "12%" }}>Subject</th>
                                <th style={{ maxWidth: "12%" }}>
                                  Property / Building
                                </th>
                                <th>Message</th>
                                <th>Date</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data.length > 0 ? (
                                this.state.data.map((x, index) => (
                                  <tr key={x._id}>
                                    <td>{index + 1}</td>
                                    <td>{x.name}</td>
                                    <td>{x.number}</td>
                                    <td title={x.email}>{x.email}</td>
                                    <td title={x.subject}>{x.subject}</td>
                                    <td title={x.message}>
                                      {x.property}{" "}
                                      {x.building ? `(${x.building})` : ""}
                                    </td>
                                    <td title={x.message}>{x.message}</td>
                                    <td>
                                      {new Intl.DateTimeFormat("en-US", {
                                        year: "numeric",
                                        month: "2-digit",
                                        day: "2-digit",
                                      }).format(x.timestamp)}
                                    </td>
                                    <td>
                                      <select
                                        className="form-control"
                                        value={x.status}
                                        name="status"
                                        onChange={this.changedata}
                                        data-id={x._id}
                                      >
                                        <option value="">
                                          Choose Priority
                                        </option>
                                        <option value="pending">Pending</option>
                                        <option value="on_hold">On Hold</option>
                                        <option value="processing">
                                          Processing
                                        </option>
                                        <option value="successful">
                                          Successful
                                        </option>
                                      </select>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td colSpan={9} style={{ color: "red" }}>
                                    <center>Result Not Found</center>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>

                      <div className="d-flex justify-content-center">
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={this.state.activePage}
                          itemsCountPerPage={10}
                          totalItemsCount={this.state.totalItemsCount}
                          pageRangeDisplayed={5}
                          onChange={this.handlePageChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </>
    );
  }
}

export default withRouter(Feedback);
