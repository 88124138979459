import React from "react";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.js";
import Loader from "../../loader.js";
const axios = require("axios");

class Seo_url extends React.Component {
  state = {
    loader_flag: false,
    page_name: "home",
    meta_title: "",
    meta_keyword: "",
    meta_desc: "",
    slug: "",
    cities: [],
    categories: [],
  };

  initialState = {
    loader_flag: false,
    meta_title: "",
    meta_keyword: "",
    meta_desc: "",
  };

  changedata = (e) => {
    if (e.target.name === "page_name") {
      let newObject = { page_name: e.target.value };
      this.getMetaTags(newObject);
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  getCategory = () => {
    axios
      .get(`${config.backend_URL}/admin/getCategories`)
      .then((responseJson) => {
        this.setState({ categories: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCities = () => {
    axios
      .get(`${config.backend_URL}/admin/getCities`)
      .then((responseJson) => {
        this.setState({ cities: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  componentDidMount = async () => {
    this.setState({ loader_flag: true });
    let newObject = { page_name: this.state.page };
    this.getCities();
    this.getCategory();
    await this.getMetaTags(newObject);
    this.setState({ loader_flag: false });
  };

  getMetaTags = (newObject) => {
    axios
      .post(`${config.backend_URL}/admin/getMetaTags`, newObject)
      .then((responseJson) => {
        if (responseJson.data.status === true) {
          this.setState(responseJson.data.data);
          console.log("ssdfsdfs", responseJson.data.data);
        } else {
          this.setState(this.initialState);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  mySubmit = (e) => {
    e.preventDefault();
    let { _id, ...newObj } = this.state;
    this.setState({ loader_flag: true });
    delete newObj.cities;
    delete newObj.loader_flag;

    console.log(newObj, "SDDDDDDDDDD");
    axios
      .post(`${config.backend_URL}/admin/addMetaTags`, newObj)
      .then(async (data) => {
        if (data.data.status === true) {
          toast(data.data.message);
          //this.getSettingsData();
        } else {
          toast("Something wrong!");
        }
        this.setState({ loader_flag: false });
      })
      .catch((err) => {
        console.log("error", err);
        this.setState({ loader_flag: false });
      });
  };

  render() {
    return (
      <>
        <ToastContainer />
        {this.state.loader_flag ? (
         <><Loader /></>
        ) : (
          <>
            <div class="content-wrapper">
              <section class="content-header">
                <div class="container-fluid">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <h1> Meta Tags </h1>
                    </div>
                    <div class="col-sm-6"></div>
                  </div>
                </div>
              </section>

              <section class="content">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title"> Meta Tags</h3>
                        </div>

                        <form
                          onSubmit={this.mySubmit}
                          encType="multipart/form-data"
                          id="testi_form"
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label>Select Page</label>

                                <select
                                  class="form-control"
                                  name="page_name"
                                  required
                                  onChange={this.changedata}
                                >
                                  <option value="home">Home Page</option>
                                  <option value="about">About Us</option>
                                  <option value="whychooseus">
                                    Why Choose Us
                                  </option>
                                  <option value="properties">Properties</option>
                                  <option value="testimonials">
                                    Testimonials
                                  </option>
                                  <option value="search">
                                    Search Properties
                                  </option>
                                    <option value="contact">Contact Us</option>
                                    <option value="gsk">Gsk</option>
                                  {this.state.cities.length > 0 &&
                                    this.state.cities.map((item, index) => {
                                      return (
                                        <option value={item.city}>
                                          {item.city}
                                        </option>
                                      );
                                    })}
                                  {this.state.categories.length > 0 &&
                                    this.state.categories.map((item, index) => {
                                      return (
                                        <option value={item.category}>
                                          {item.category}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label>Meta Title </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="meta_title"
                                  placeholder=""
                                  value={this.state.meta_title}
                                  onChange={this.changedata}
                                />
                              </div>
                            </div>
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label>Meta Keyword</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="meta_keyword"
                                  placeholder=""
                                  value={this.state.meta_keyword}
                                  onChange={this.changedata}
                                />
                              </div>
                            </div>

                            <div class="row">
                              <div class="form-group col-md-6">
                                <label>Meta Description</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="meta_desc"
                                  placeholder=""
                                  value={this.state.meta_desc}
                                  onChange={this.changedata}
                                />
                              </div>
                            </div>

                            <div class="form-group col-md-2">
                              <label>&nbsp;&nbsp;&nbsp;</label>
                              <button type="submit" class="btn btn-primary">
                                {" "}
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </>
    );
  }
}

export default withRouter(Seo_url);
