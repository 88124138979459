import React from "react";
import { withRouter } from "react-router-dom";
import config from "../../config.js";
import Loader from "../../loader.js";

const axios = require("axios");
class Dashboard extends React.Component {
  state = {
    loader_flag: false,
    propertyTotal: 0,
    categoryTotal: 0,
    testimonialTotal: 0,
    feedbackTotal: 0,
    appointmentTotal: 0,
  };

  componentDidMount = () => {
    this.getAllCounts();
  };

  getAllCounts = () => {
    this.setState({ loader_flag: true });
    axios
      .post(`${config.backend_URL}/admin/getAllCounts`)
      .then((responseJson) => {
        this.setState(responseJson.data.data);
        this.setState({ loader_flag: false });
      })
      .catch((error) => {
        this.setState({ loader_flag: false });
        console.error(error);
      });
  };

  render() {
    return (
      <>
        {this.state.loader_flag ? (
         <><Loader /></>
        ) : (
          <div class="content-wrapper">
            <div class="content-header">
              <div class="container-fluid">
                <div class="row mb-2">
                  <div class="col-sm-6">
                    <h1 class="m-0 text-dark">Dashboard</h1>
                  </div>
                  <div class="col-sm-6">
                    <ol class="breadcrumb float-sm-right">
                      <li class="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li class="breadcrumb-item active">Dashboard </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>

            <section class="content">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-info">
                      <div class="inner">
                        <h3> {this.state.propertyTotal} </h3>
                        <p>Total Properties</p>
                      </div>

                      <a href="properties_list" class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>

                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-warning">
                      <div class="inner">
                        <h3> {this.state.categoryTotal} </h3>

                        <p>Total Categories</p>
                      </div>

                      <a href="category" class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>

                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-success">
                      <div class="inner">
                        <h3> {this.state.testimonialTotal} </h3>

                        <p>Total Testimonials</p>
                      </div>

                      <a href="testimonials" class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>

                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-danger">
                      <div class="inner">
                        <h3> {this.state.feedbackTotal} </h3>

                        <p>Total Feedbacks</p>
                      </div>

                      <a href="feedback" class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>

                  <div class="col-lg-3 col-6">
                    <div class="small-box bg-success">
                      <div class="inner">
                        <h3> {this.state.appointmentTotal} </h3>

                        <p>Total Appointments</p>
                      </div>

                      <a href="property_appointment" class="small-box-footer">
                        More info <i class="fas fa-arrow-circle-right"></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        )}
      </>
    );
  }
}
export default withRouter(Dashboard);
