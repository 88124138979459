import React from "react";
import config from "../config.js";
import Amenities_swiper from "./Amenities_swiper.js";

const axios = require("axios");
class Amenities extends React.Component {
  state = {
    amenity_heading: "",
    amenity_desc: "",
  };

  componentDidMount() {
    this.getSettings();
  }

  getSettings = () => {
    axios
      .get(`${config.backend_URL}/admin/getSettings`)
      .then((responseJson) => {
        this.setState({
          amenity_heading: responseJson.data.data.amenity_heading,
          amenity_desc: responseJson.data.data.amenity_desc,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  render() {
    return (
      <>
        <article
          id="amenities"
          className="gskCatAmenBlock wrapper py-80"
          style={{
            backgroundImage: `url(${config.cdn_URL + "/images/aboutBg.jpg"})`,
          }}
        >
          <div className="gskAmenBlock">
            <div className="abtKeyPatt">
              <img src={config.cdn_URL + "/images/keyIcon1.png"} />
            </div>
            <div className="container">
              <div className="row">
                <div className="mainHeading amentiesHdng text-center col-lg-8 offset-lg-2">
                  <h2>{this.state.amenity_heading}</h2>
                  <div className="hdngBrdrs">
                    <span className="hdngBrdr1"></span>
                    <span className="hdngBrdr2"></span>
                    <span className="hdngBrdr3"></span>
                  </div>
                  <p>
                    What is the importance of Amenities and Facilities?
                    Amenities play a major role while choosing your next rental
                    home. At GSK Properties we believe in providing a one stop
                    solution to all{" "}
                    <a href="https://gskproperties.ca/" target="_blank">
                      Property Management Services
                    </a>{" "}
                    for residents such as Underground Parking, Fitness Area,
                    Lounge Area, Splash Park for Kids, Theatre Room, Party Rooms
                    and much more!!!
                  </p>
                </div>
              </div>
              <Amenities_swiper />
            </div>
          </div>
        </article>
      </>
    );
  }
}

export default Amenities;
