import React from "react";
// import { Link } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";

class MapComp extends React.Component {
  state = {
    //data: this.props.data,
    zoom: this.props.viewAllstatus ? 4 : 10,
    activeMarker: {},
    selectedPlace: {},
    currentCenter: { lat: this.props.data[0].lat, lng: this.props.data[0].lng },
    markerObjects: [],
  };

  componentDidMount = () => {
    this.setState({ data: this.props.data });
  };

  componentDidUpdate = async (prevProps) => {
    if (this.props !== prevProps) {
      // Check if it's a new user, you can also use some unique property, like the ID  (this.props.user.id !== prevProps.user.id)
      console.log("this.props", this.props);
      console.log("state", this.state);
      console.log("prevProps", prevProps);
      await this.setState({ data: this.props.data });
      this.toggleBounce();
      /*  if (this.props.viewAllstatus) {
        await this.setState({ zoom: 4 });
      } else {
        await this.setState({ zoom: 13 });
      } */
    }
  };
  toggleBounce = () => {
    this.props.data.map((mark, index) => {
      let x = this.state.markerObjects[index];
      if (mark.border) {
        x.setAnimation(this.props.google.maps.Animation.BOUNCE);
      } else {
        x.setAnimation(null);
      }
    });
  };

  onMarkerClick = async (props, marker, e) => {
    //    console.log("e",e)
    //    console.log("marker",marker)
    await this.props.setPropertyId(props.id);
    this.toggleBounce();
    //    console.log("props",this.props.data)
  };

  onMarkerMounted = (element) => {
    console.log("marker", element);
    if (element != null) {
      this.setState((prevState) => ({
        markerObjects: [...prevState.markerObjects, element.marker],
      }));
    }
  };

  onMarkerHover = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  windowHasClosed = (props, marker, e) => {
    this.setState({
      selectedPlace: {},
      activeMarker: {},
      showingInfoWindow: false,
    });
  };

  handleMapClick = (ref, map, ev) => {
    console.log("fdsfds", ref);
    console.log("currentCenter", ev.latLng);
    this.setState({ currentCenter: ref.initialCenter });
  };

  onInfoWindowOpen = (props, marker, e) => {
    console.log("info", e);
    console.log("props", props);
    console.log("marker---> ", marker);
  };
  render() {
    return (
      <>
        <div className="listingMapCol">
          <div className="listingMapDiv">
            {this.props.data.length > 0 ? (
              <Map
                google={this.props.google}
                zoom={this.state.zoom}
                className={"map"}
                initialCenter={{
                  lat: this.props.data[0].lat,
                  lng: this.props.data[0].lng,
                }}
                center={this.state.currentCenter}
                // onClick={this.handleMapClick}
              >
                {this.state.data &&
                  this.state.data.map((x, index) => {
                    return (
                      <Marker
                        key={index}
                        ref={this.onMarkerMounted}
                        title={x.property_name}
                        id={x._id}
                        onClick={this.onMarkerClick}
                        onMouseover={this.onMarkerHover}
                        name={x.property_name}
                        slug={x.slug}
                        position={x}
                        animation={
                          x.border
                            ? this.props.google.maps.Animation.BOUNCE
                            : ""
                        }
                      />
                    );
                  })}

                <InfoWindow
                  marker={this.state.activeMarker}
                  onClose={this.windowHasClosed}
                  visible={this.state.showingInfoWindow}
                  onOpen={this.onInfoWindowOpen}
                >
                  <div>
                    {/* <Link to="/"> */}
                    <h5>
                      <a href={`/${this.state.selectedPlace.slug}`}>
                        {this.state.selectedPlace.name}
                      </a>
                    </h5>
                    {/* </Link> */}
                  </div>
                </InfoWindow>
              </Map>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyCHhn_brq4zBzxkyC2OyUo8-Dg6VqaoITc",
})(MapComp);
