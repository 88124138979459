import React from "react";
import { Link } from "react-router-dom";
import config from "../config.js";
import HomeBanner from "./HomeBanner.js";
const axios = require("axios");

class Footer extends React.Component {
  state = {
    manager_email: "",
    propertyId: "",
    property: "",
    visiting_suite_number: "",
    name: "",
    number: "",
    email: "",
    visitor_parking_stall_number: "",
    date: "",
    from_time: "",
    end_time: "",
    car_make: "",
    car_model: "",
    plate_number: "",
    allProperties: [],
    visitorShow: false,
    submitBtn: true,
    cities: [],
    propertyTypes: [],
  };

  initialState = {
    manager_email: "",
    propertyId: "",
    property: "",
    visiting_suite_number: "",
    name: "",
    number: "",
    email: "",
    visitor_parking_stall_number: "",
    date: "",
    from_time: "",
    end_time: "",
    car_make: "",
    car_model: "",
    plate_number: "",
    submitBtn: true,
  };

  componentDidMount = () => {
    this.getProperties();
    this.getCities();
    this.getCategory();
  };

  getProperties = () => {
    axios
      .post(`${config.backend_URL}/admin/getProperties`)
      .then((responseJson) => {
        this.setState({ allProperties: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getCities = () => {
    axios
      .get(`${config.backend_URL}/admin/getCities`)
      .then((responseJson) => {
        this.setState({ cities: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCategory = () => {
    axios
      .get(`${config.backend_URL}/admin/getCategories`)
      .then((responseJson) => {
        this.setState({ propertyTypes: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  changedata = (e) => {
    if (e.target.name === "property") {
      let key = e.target[e.target.selectedIndex].getAttribute("data-key");
      let propertyId = e.target[e.target.selectedIndex].getAttribute("data-id");
      let managerEmails = this.state.allProperties[key].manager_emails;

      let manager_email = managerEmails
        .map(function (elem) {
          return elem.manager_email;
        })
        .join(",");

      this.setState({ manager_email: manager_email, propertyId: propertyId });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  // <footer className="ftrBlock wrapper py-80" style={{backgroundImage: `url(${config.cdn_URL + "/images/ftrBg.png"})`}}>
  //   <div className="container">
  //     <div className="row align-items-center">
  //       <div className="col">
  //         <div className="ftrInfo">
  //           <div className="ftrLinks">
  //             <NavLink to="/about-us">About Us</NavLink>
  //             <span>|</span>
  //             <NavLink to="/testimonials">Testimonials</NavLink>
  //             <span>|</span>
  //             <NavLink to="/blog">Blog</NavLink>
  //             <span>|</span>
  //             <NavLink to="/contact-us">Contact Us</NavLink>
  //           </div>
  //         </div>
  //         <div className="ftrCopy">
  //           <p>
  //             © <span>GSK</span> Properties {new Date().getFullYear()}
  //           </p>
  //         </div>
  //       </div>
  //     </div>
  //   </div>
  // </footer>
  render() {
    return (
      <>
        <section
          class="wrapper featrdPrprtyBlk "
          // style={{ backgroundColor: " #f5f5f7" }}
        >
          <div className="container featrdPrprtyBlkContainer" >
            <HomeBanner />
          </div>
        </section>
        <footer
          class="wrapper footrBlk "
          style={{
            backgroundImage: `url(${config.cdn_URL + "/images/ftrBg.png"})`,
          }}
        >
          <div class="container ">
            {/* <div class="footerTop">
              <div className="">
                <div class="ftr-logo">
                  <Link to="">
                    <img
                      style={{ width: "155px", filter: "invert(100%)" }}
                      src="/images/ftrLogo.png"
                      alt="Logo"
                    />
                  </Link>
                </div>
              </div>
              <div className=" d-flex flex-wrap gap-4   mt-2  ">
                {[
                  { value: "Home", url: "/" },
                  { value: "Properties", url: "/alberta" },
                  { value: "Blog", url: "/gskblog/blog" },
                  { value: "Testimonials", url: "/testimonials" },
                  { value: "About Us", url: "/about-us" },
                  { value: "Contact Us", url: "/contact-us" },
                ].map((item) => (
                  <h5 key={item.value}>
                    <a className="text-white text-bold" href={item.url}>
                      {item.value}
                    </a>
                  </h5>
                ))}
              </div>
            </div> */}
            <div class="copyRight " />
            <div class="row ">
              <div class="col-md-3 col-sm-6 anchor mb-3">
                <h5
                  className="text-white mb-3 text-uppercase text-bold"
                  style={{ fontWeight: "600" }}
                >
                  Popular Searches
                </h5>
                <div className="anchor d-flex flex-column">
                  {this.state.propertyTypes?.map((item) => (
                    <div className="mt-2">
                      <Link
                        to={{
                          pathname: `/alberta/${item?.category
                            .toLowerCase()
                            .replaceAll(" ", "-")}`,
                          state: { cityId: "category" },
                        }}
                        className="text-white"
                      >
                        {item?.category} for rent near me
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
              <div class="col-md-3 col-sm-6  mb-3 ">
                <h5
                  className="text-white mb-3 text-uppercase text-bold"
                  style={{ fontWeight: "600" }}
                >
                  Top Rental Market
                </h5>
                <div className="anchor d-flex flex-column ">
                  {this.state.cities.map((item) => {
                    return (
                      <div className="mt-2">
                        <Link
                          to={{
                            pathname: `/alberta/${item.slug}`,
                          }}
                          className="text-white"
                        >
                          House for rent in {item.city}
                        </Link>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div class="col-md-3 col-sm-6 featrdPrprtyBlk-colPad anchor mb-3">
                <h5
                  className="text-white mb-3 text-uppercase text-bold "
                  style={{ fontWeight: "600" }}
                >
                  Rental Info
                </h5>
                <a href="/alberta" className="text-white mt-2">
                  Search Property
                </a>
                <br />
                <a href="/#amenities" className="text-white  mt-2">
                  Amenities
                </a>
                <br />
              </div>
              <div class="col-md-3 featrdPrprtyBlk-colPad contactPrprtyBlk col-sm-6 mb-3 ">
                <h5
                  className="text-white mb-3 text-uppercase text-bold"
                  style={{ fontWeight: "600" }}
                >
                  Contact Info
                </h5>
                <a className="text-white mt-2 ">
                  <img
                    className="mx-2"
                    src="https://img.icons8.com/FFFFFF/ios/24/null/region-code.png"
                  />
                  1073 Chappelle Blvd SW
                </a>
                <br />
                <a
                  href="mailto:feedback@gskproperties.ca"
                  className="text-white mt-2"
                >
                  <img
                    className="mx-2"
                    src="https://img.icons8.com/FFFFFF/ios/24/new-post--v1.png"
                  />
                  feedback@gskproperties.ca
                </a>
              </div>
            </div>
            <div class="copyRight" />
            <div className="row justify-content-between mb-3 mt-2">
              <div className="col-md-6">
                <small className=" text-white">
                  ©Copyrights 2023 -{" "}
                  <a href="https://www.gskproperties.ca">
                    www.gskproperties.ca
                  </a>{" "}
                  - All rights reserved
                </small>
              </div>
              <div className="col-md-6 ">
                <div className="justify-content-md-end d-flex align-items-center">
                  <small className="d-flex align-items-center"></small>
                  <div className=" text-white"> Follow Us :</div>
                  <div className="d-flex gap-2 mx-1">
                    <a
                      title="Facebook"
                      href="https://www.facebook.com/gskproperties"
                    >
                      <img src="https://img.icons8.com/color/30/null/facebook-new.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
