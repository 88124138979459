import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import Select from "react-select";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.js";
import Loader from "../../loader.js";
class AddBlog extends React.Component {
  state = {
    loader_flag: false,
    blog_name: "",
    category_id: "",
    blog_description: "",
    blog_image: [undefined],
    blog_status: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    meta_image: undefined,
    blog_id: "",
    blog_name: "",
    category_list: [],
    size: 1,
    selectedAuthor: [],
    authorList: [],
  };

  initialState = {
    loader_flag: false,
    blog_name: "",
    category_id: "",
    blog_description: "",
    blog_image: [undefined],
    blog_status: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    meta_image: undefined,

    selectedAuthor: [],
    authorList: [],
  };

  componentDidMount() {
    this.getCategoryList();
    this.getAuthors();
    if (this.props.location?.state?.id) {
      console.log("id", this.props.location.state.id);
      let blog_id = this.props.location.state.id;
      let blog_name = this.props.location.state.name;
      this.setState({ blog_id: blog_id });
      this.setState({ blog_name: blog_name });
      this.getcategory(blog_name);
    }
  }

  getCategoryList = () => {
    axios
      .get(`${config.backend_URL}/admin/blog/fetchAllCategory`)
      .then(async (data) => {
        console.log(data);
        if (data.status === 200) {
          this.setState({
            category_list: data.data.result,
          });
        } else {
          toast("Something wrong!");
        }
      })
      .catch((err) => {
        toast.error("Something wrong!");
        console.log("error", err);
      });
  };

  getcategory = async (id) => {
    axios
      .get(`${config.backend_URL}/admin/blog/fetchBlog/${id}`)
      .then(async (data) => {
        console.log(data);
        if (data.status === 200) {
          this.setState({
            blog_name: data.data.result?.name,
            blog_description: data.data.result?.description,
            blog_image: data.data.result?.images,
            blog_status: data.data.result?.status,
            meta_title: data.data.result?.meta_content?.title,
            meta_description: data.data.result?.meta_content?.description,
            meta_keyword: data.data.result?.meta_content?.keyword,
            meta_image: data.data.result?.meta_content?.image,
            selectedAuthor: data.data.result?.author?.map((v) => ({
              value: v._id,
              label: v.name,
            })),
            category_id: data.data.result?.category?._id,
          });
        } else {
          toast("Something wrong!");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  addCategory = (e) => {
    e.preventDefault();
    if (this.state.blog_image.length > 0) {
      let uploadObj = {
        name: this.state.blog_name,
        slug: this.state.blog_name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-"),
        description: this.state.blog_description,
        images: this.state.blog_image,
        status: this.state.blog_status,
        categoryID: this.state.category_id,
        author: this.state.selectedAuthor?.map((v) => ({
          _id: v.value,
          name: v.label,
        })),
        meta_content: {
          title: this.state.meta_title,
          keyword: this.state.meta_keyword,
          description: this.state.meta_description,
          image: this.state.meta_image,
        },
      };
      if(uploadObj.name == ''){
        return toast("Please enter Blog Name");
      }else if(uploadObj.categoryID == ''){
        return toast("Please select Blog Category");
      }else if(uploadObj.description == ''){
        return toast("Please enter Blog Description");
      }else if(uploadObj.author == ''){
        return toast("Please select Blog Author");
      }else if(uploadObj.images == ''){
        return toast("Please upload Blog Image");
      }else if(uploadObj.meta_content.title == ''){
        return toast("Please enter Meta Title");
      }else if(uploadObj.meta_content.description == ''){
        return toast("Please enter Meta Description");
      }else if(uploadObj.meta_content.keyword == ''){
        return toast("Please enter Meta Keyword");
      }else if(!uploadObj.meta_content.image ){
        return toast("Please upload Meta Image");
      }
      axios
        .post(`${config.backend_URL}/admin/blog/createBlog`, uploadObj)
        .then(async (data) => {
          console.log(data);
          if (data.status === 200) {
            toast(data.data.msg);
            this.setState(this.initialState);
            this.props.history.push("/admin/blogList");
          } else {
            toast("Something wrong!");
          }
        })
        .catch((err) => {
          console.log("error", err);
          toast("Something wrong! Please try again.");
          this.setState({ loader_flag: false });
        });
    } else {
      toast("Please upload image");
    }
  };

  uploadSingleImage = (e) => {
    e.preventDefault();
    const { name, files } = e.target;
    // let file = e.target?.files[0]
    // console.log('file: ', files,"/n",name);
    try {
      let form = new FormData();
      form.append("file", files[0]);
      axios
        .post(`${config.backend_URL}/admin/upload_single_image`, form)
        .then(async (data) => {
          console.log(data);
          if (data.status === 200) {
            this.setState({ [name]: data.data.data });
          } else {
            toast("Something wrong!");
          }
          // this.setState({ loader_flag: false });
        });
    } catch (err) {
      console.log(err);
      toast("Something wrong!");
    }
  };

  uploadMultipleImage = (e) => {
    e.preventDefault();
    const { name, files } = e.target;
    // let file = e.target?.files[0]
    // console.log('file: ', files,"/n",name);
    try {
      let form = new FormData();
      if (files.length > 4) {
        for (let i = 0; i < 4; i++) form.append("file", files[i]);
      } else {
        for (let i = 0; i < files.length; i++) form.append("file", files[i]);
      }
      axios
        .post(`${config.backend_URL}/admin/upload_multiple_image`, form)
        .then(async (data) => {
          console.log(data);
          if (data.status === 200) {
            this.setState({ [name]: data.data.data });
          } else {
            toast("Something wrong!");
          }
          // this.setState({ loader_flag: false });
        });
    } catch (err) {
      console.log(err);
      toast("Something wrong!");
    }
  };

  getAuthors = async () => {
    axios
      .post(`${config.backend_URL}/admin/blog/get_author`)
      .then(async (data) => {
        if (data.status === 200) {
          const res = data.data?.data?.map((v) => ({
            value: v._id,
            label: v.name,
          }));
          this.setState({ authorList: res });
        } else {
          toast("Something wrong!");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  updateCategory = (e) => {
    e.preventDefault();
    let uploadObj = {
      name: this.state.blog_name,
      slug: this.state.blog_name.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "-"),
      description: this.state.blog_description,
      images: this.state.blog_image,
      status: this.state.blog_status,
      categoryID: this.state.category_id,
      author: this.state.selectedAuthor?.map((v) => ({
        _id: v.value,
        name: v.label,
      })),
      meta_content: {
        title: this.state.meta_title,
        keyword: this.state.meta_keyword,
        description: this.state.meta_description,
        image: this.state.meta_image,
      },
    };
    if(uploadObj.name == ''){
      return toast("Please enter Blog Name");
    }else if(uploadObj.categoryID == '' || uploadObj.categoryID == "Select Category"){
      return toast("Please select Blog Category");
    }else if(uploadObj.description == ''){
      return toast("Please enter Blog Description");
    }else if(uploadObj.author == ''){
      return toast("Please select Blog Author");
    }else if(uploadObj.images == ''){
      return toast("Please upload Blog Image");
    }else if(uploadObj.meta_content.title == ''){
      return toast("Please enter Meta Title");
    }else if(uploadObj.meta_content.description == ''){
      return toast("Please enter Meta Description");
    }else if(uploadObj.meta_content.keyword == ''){
      return toast("Please enter Meta Keyword");
    }else if(!uploadObj.meta_content.image ){
      return toast("Please upload Meta Image");
    }
    try {
      axios
        .post(
          `${config.backend_URL}/admin/blog/updateBlog/${this.state.blog_id}`,
          uploadObj
        )
        .then(async (data) => {
          console.log(data);
          if (data.status === 200) {
            toast(data.data.msg);
            this.setState(this.initialState);
          } else {
            toast("Something wrong!");
          }
        })
        .catch((err) => {
          console.log("error", err);
          toast("Something wrong! Please try again.");
          this.setState({ loader_flag: false });
        });
    } catch (err) {
      toast("Something wrong! Please try again.");
      console.log(err);
    }
  };

  render() {
    const customStyles = {
      content: {
        width: "30%",
        top: "50%",
        left: "55%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    return (
      <>
        <ToastContainer />
        {this.state.loader_flag ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <div class="content-wrapper">
              <section class="content-header">
                <div class="container-fluid">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <h1>Blog / Add Blog</h1>
                    </div>
                    <div class="col-sm-6">
                      <ol class="breadcrumb float-sm-right">
                        <Link
                          to="/admin/blogList"
                          class="pull-right btn btn-success"
                        >
                          Blog List
                        </Link>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section class="content">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title">Add Blog</h3>
                        </div>
                        <div class="card-body">
                          <form
                            onSubmit={
                              this.state.blog_id
                                ? this.updateCategory
                                : this.addCategory
                            }
                          >
                            <div class="form-group row">
                              <div className="col-md-3 text-end"></div>
                              <div className="col-md">
                                <b>Add Blog</b>
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Blog Title</label>
                              </div>
                              <div className="col-md">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={this.state.blog_name}
                                  onChange={(e) => {
                                    this.setState({
                                      blog_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Blog Category</label>
                              </div>
                              <div className="col-md">
                                <select
                                  class="form-select"
                                  size={this.state.size}
                                  value={this.state.category_id}
                                  onChange={(e) =>
                                    this.setState({
                                      category_id: e.target.value,
                                      size: 1,
                                    })
                                  }
                                  onFocus={(e) => this.setState({ size: 5 })}
                                  onBlur={(e) => this.setState({ size: 1 })}
                                >
                                  <option>Select Category</option>
                                  {this.state.category_list.length > 0 &&
                                    this.state.category_list.map(
                                      (item, index) => {
                                        return (
                                          <option value={item._id}>
                                            {item.name}
                                          </option>
                                        );
                                      }
                                    )}
                                </select>
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Blog Description</label>
                              </div>
                              <div className="col-md">
                                <div className="ckEditor">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                      link: {
                                        decorators: {
                                          addTargetToExternalLinks: {
                                            mode: "automatic",
                                            callback: (url) =>
                                              /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/.test(
                                                url
                                              ),
                                            attributes: {
                                              target: "_blank",
                                              rel: "noopener noreferrer",
                                            },
                                          },
                                        },
                                      },
                                    }}
                                    data={this.state.blog_description}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      this.setState({ blog_description: data });
                                    }}
                                    name="blog_description"
                                    onFocus={(event, editor) => {}}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Blog Author</label>
                              </div>
                              <div className="col-md">
                                <Select
                                  isMulti={true}
                                  isClearable={true}
                                  isSearchable={true}
                                  className=" css-jxcsum-menu"
                                  styles={{
                                    control: (baseStyles, state) => ({
                                      ...baseStyles,
                                      zIndex: 10,
                                    }),
                                  }}
                                  value={this.state.selectedAuthor}
                                  options={this.state.authorList}
                                  placeholder="Search By Author Name"
                                  onChange={(e) =>
                                    this.setState({ selectedAuthor: e })
                                  }
                                />
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Blog Images</label>
                              </div>
                              <div className="col-md">
                                <div className="d-flex align-items-start align-items-sm-center gap-4">
                                  <img
                                    src={
                                      this.state.blog_image[0]
                                        ? `${config.cdn_backend_URL}/${this.state.blog_image[0]}`
                                        : "../images/noImage.jpg"
                                    }
                                    alt="blog-category-image"
                                    class="d-block rounded"
                                    height="100"
                                    width="100"
                                  />
                                  {/* <img
                                    src={
                                      this.state.blog_image[1]
                                        ? `${config.backend_URL}/${this.state.blog_image[1]}`
                                        : "../images/noImage.jpg"
                                    }
                                    alt="blog-category-image"
                                    class="d-block rounded"
                                    height="100"
                                    width="100"
                                  />
                                  <img
                                    src={
                                      this.state.blog_image[2]
                                        ? `${config.backend_URL}/${this.state.blog_image[2]}`
                                        : "../images/noImage.jpg"
                                    }
                                    alt="blog-category-image"
                                    class="d-block rounded"
                                    height="100"
                                    width="100"
                                  />
                                  <img
                                    src={
                                      this.state.blog_image[3]
                                        ? `${config.backend_URL}/${this.state.blog_image[3]}`
                                        : "../images/noImage.jpg"
                                    }
                                    alt="blog-category-image"
                                    class="d-block rounded"
                                    height="100"
                                    width="100"
                                  /> */}
                                  <input
                                    type="file"
                                    class="form-control"
                                    name="blog_image"
                                    data-key="0"
                                    onChange={this.uploadMultipleImage}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Meta Title</label>
                              </div>
                              <div className="col-md">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={this.state.meta_title}
                                  onChange={(e) => {
                                    this.setState({
                                      meta_title: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Meta Description</label>
                              </div>
                              <div className="col-md">
                                <textarea
                                  class="form-control"
                                  name=""
                                  placeholder=""
                                  value={this.state.meta_description}
                                  onChange={(e) => {
                                    this.setState({
                                      meta_description: e.target.value,
                                    });
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Meta Keywords</label>
                              </div>
                              <div className="col-md">
                                <textarea
                                  class="form-control"
                                  name=""
                                  placeholder=""
                                  value={this.state.meta_keyword}
                                  onChange={(e) => {
                                    this.setState({
                                      meta_keyword: e.target.value,
                                    });
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Meta Image</label>
                              </div>
                              <div className="col-md">
                                <div className="d-flex align-items-start align-items-sm-center gap-4">
                                  <img
                                    src={
                                      this.state.meta_image
                                        ? `${config.backend_URL}/${this.state.meta_image}`
                                        : "../images/noImage.jpg"
                                    }
                                    alt="blog-category-image"
                                    class="d-block rounded"
                                    height="100"
                                    width="100"
                                  />
                                  <input
                                    type="file"
                                    class="form-control"
                                    name="meta_image"
                                    data-key="0"
                                    onChange={this.uploadSingleImage}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row align-items-center">
                              <div className="col-md-3 text-end"></div>
                              <div className="col-md">
                                <button type="submit" class="btn btn-primary">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </>
    );
  }
}
export default withRouter(AddBlog);
