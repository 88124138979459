import React from "react";
import { withRouter } from "react-router-dom";
import config from "../../config.js";
import Links from "./includes/Links.js";
import Loader from "../../loader.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import jwt from "jsonwebtoken";
const axios = require("axios");

const location1 = window.location.href;
const position1 = location1.search("tkn");
const subStr1 = location1.substring(position1);

// geting position of domain and domain it self
const domainPosition1 = subStr1.search("=");
const companyDomain1 = subStr1.substring(domainPosition1 + 1);


class TwoFa extends React.Component {
  state = {
    loader_flag: false,
    otp: "",
    token: "",
    email1: "",
    pass1: "",
    
  };

  componentDidMount = () => {
    this.setState(({ token: this.props.location.state ? this.props.location.state.token : companyDomain1 }))
    let email = localStorage.getItem("email")
    let pass = localStorage.getItem("pass")
    console.log(email)
    this.setState({ email1: email })
    this.setState({ pass1: pass })
   
  };

  mySubmit = (e) => {
    e.preventDefault();

    if (this.state.otp) {

      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          verificationToken: this.state.token,
          verificationCode: JSON.parse(this.state.otp)
        }),
      };

      fetch(`${config.backend_URL}/admin/twoFactorLogin`, options)
        .then((res) => {
          return res.json();
        })

        .then((data) => {
          console.log("data", data);
          if (data.status === true) {
            toast("Successfully Login!!");
            const id = jwt.sign(
              { expiresInMinutes: 60, id: data.data._id },
              "pokemon"
            );


            localStorage.setItem("id", id);

            this.props.history.push("/admin/dashboard");
          } else {
            toast(data.message);
          }
        })

        .catch((err) => {
          console.log("error", err);
        });
    }
  }



  resend = (e) => {
    e.preventDefault()

    axios
      .post(`${config.backend_URL}/admin/loginRequest`, {
        username: this.state.email1,
        password: this.state.pass1
      })
      .then((data) => {

        if (data.status === 200) {
          this.setState({ token: data.data.VerificationToken })
          toast(data.data.message);
        } else {
          toast(data.data.message);
        }
      })
  }

  render() {
    return (
      <>
        <Links />

        <ToastContainer />
        {this.state.loader_flag ? (
          <><Loader /></>
        ) : (
          <>
            <div class="appHdr">
              <div class="loginCenter">
                <div class="login-box">
                  <div class="login-logo">
                    <a href="#!">
                      <b>OTP</b>
                    </a>
                  </div>
                  <div class="card">
                    <div class="card-body login-card-body">
                      <p class="login-box-msg">Enter otp to start your session</p>
                      <form onSubmit={this.mySubmit}>
                        <div class="input-group mb-3">
                          <input
                            type="otp"
                            className="form-control"
                            placeholder="OTP"
                            onChange={(e) => this.setState({ otp: e.target.value })}
                            name="otp"
                            required
                          />
                          <div class="input-group-append">
                            <div class="input-group-text"></div>
                          </div>
                        </div>
                        <div class="row d-flex align-items-center justify-content-betwen">
                          <div class="col">
                            <button
                              type="submit"
                              id="submitBtn"
                              class="btn btn-primary btn-block"
                            >
                              Submit
                            </button>
                            <button
                              type="submit"
                              id="submitBtn"
                              class="btn btn-primary btn-block"
                              onClick={this.resend}
                            >
                              Resend
                            </button>
                          </div>

                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}


export default withRouter(TwoFa);

//export default Login;
