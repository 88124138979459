import $ from "jquery";
import _ from "lodash";
import React from "react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import ReactStars from "react-rating-stars-component";
import { toast } from "react-toastify";
import { A11y, Navigation, Pagination, Scrollbar } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";
import { FooterFront, HeaderFront } from "../components";
import config from "../config.js";
import helper from "../helper.js";
import Loader from "../loader.js";
import ScriptUtils from "../utils/ScriptLinkChange";

let images = [];
let a = 0;
const axios = require("axios");

class PropertyDetail extends React.Component {
  state = {
    loader_flag: false,
    flag: "property",
    name: "",
    number: "",
    email: "",
    subject: "",
    message: "",
    errors: [],
    testimonials: [],
    photoIndex: 0,
    isOpen: false,
    bathroomData: [],
    bedroomData: [],
    google_rating: 0,
    facebook_rating: 0,
    a: 0,
    vendors1: [],
    cities: [],
    push_images: [],
    url: "",
    manager_name: "",
    manager_mobile_number: "",
  };

  initialState = {
    loader_flag: false,
    flag: "property",
    name: "",
    number: "",
    email: "",
    subject: "",
    message: "",
    errors: [],
    testimonials: [],
    vendors1: [],
  };

  componentDidMount = () => {
    //this.setState({ loader_flag: true })
    let slug = this.props.match.params.property;
    this.getCities(slug);
    this.getBedroom();
    this.getBathroom();
    this.getProperties(slug);
    document.body.classList.add("detailPage");
    console.log("this.state.background_video", this.state.background_video);
  };

  getBedroom = () => {
    axios
      .get(`${config.backend_URL}/admin/getBedroom`)
      .then((responseJson) => {
        console.log(responseJson.data.data);
        // return
        this.setState({ bedroomData: responseJson.data.data });

        console.log(this.state.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getBathroom = () => {
    axios
      .get(`${config.backend_URL}/admin/getBathroom`)
      .then((responseJson) => {
        console.log(responseJson.data.data);
        // return
        this.setState({ bathroomData: responseJson.data.data });
        console.log(this.state.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  shouldComponentUpdate = (nextProps) => {
    console.log("this.rops", this.props.location.pathname);
    console.log("nextProps", nextProps.location.pathname);
    if (nextProps.location.pathname !== this.props.location.pathname) {
      let slug = nextProps.location.pathname?.replace("/", "");
      this.getProperties(slug);
      return false;
    }
    return true;
  };

  getTestimonials = (id) => {
    this.setState({ loader_flag: true });
    axios
      .post(`${config.backend_URL}/admin/getTestimonials`, { propertyId: id })
      .then((responseJson) => {
        this.setState({
          testimonials: responseJson.data.data,
          loader_flag: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loader_flag: false });
      });
  };

  getCalendarUrl = async (propertyName) => {
    try {
      const capitalizeEachWord = (str) =>
        str.replace(/\b\w/g, (char) => char.toUpperCase());

      const propertyMappings = {
        "McINTYRE APARTMENTS": "McIntyre Apartments",
        "OAK TOWER": "Oak Tower Apartments",
        "STONESHIRE VILLAS": "STONESHIRE VILLAS",
        "SILVERSTONE TERRACE": "Silverstone Terrace Apartments",
        "CHAPPELLE GARDEN VILLA": "Chappelle Garden Villa Townhomes",
        "GUARDIAN VILLA": "Guardian Villa Townhomes",
        "RIVERSIDE VILLA": "Riverside Villa Townhomes",
        "OXFORD CAMPUS": "Oxford Campus Apartments",
        "NOVA VILLA": "Nova Villa Apartments",
      };

      const formattedPropertyName =
        propertyMappings[propertyName] ||
        capitalizeEachWord(propertyName.toLowerCase());

      const response = await axios.post(
        `${config.RentDigiCarebackend_URL}/property/getManagersOfPropertiesCalendar`,
        // `http://localhost:9000/property/getManagersOfPropertiesCalendar`,

        {
          propertyTitle: formattedPropertyName,
          companyId: "62c4307c5dba72e1eb1b6493",
        }
      );

      if (response.data.status === 200 && response.data.Managers.primaryID) {
        const url = `${window.location.protocol}//app.rentdigicare.com/calendar/${response.data.Managers.primaryID}?propertyName=${formattedPropertyName}`;

        this.setState({ url });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getPropertyManager = async (propertyName) => {
    try {
      const capitalizeEachWord = (str) =>
        str.replace(/\b\w/g, (char) => char.toUpperCase());

      const propertyMappings = {
        "McINTYRE APARTMENTS": "McIntyre Apartments",
        "OAK TOWER": "Oak Tower Apartments",
        "STONESHIRE VILLAS": "STONESHIRE VILLAS",
        "SILVERSTONE TERRACE": "Silverstone Terrace Apartments",
        "CHAPPELLE GARDEN VILLA": "Chappelle Garden Villa Townhomes",
        "GUARDIAN VILLA": "Guardian Villa Townhomes",
        "RIVERSIDE VILLA": "Riverside Villa Townhomes",
        "OXFORD CAMPUS": "Oxford Campus Apartments",
        "NOVA VILLA": "Nova Villa Apartments",
      };

      const formattedPropertyName =
        propertyMappings[propertyName] ||
        capitalizeEachWord(propertyName.toLowerCase());

      const response = await axios.post(
        `${config.RentDigiCarebackend_URL}/property/getManagersOfPropertiesCalendar`,
        // `http://localhost:9000/property/getManagersOfPropertiesCalendar`,

        {
          propertyTitle: formattedPropertyName,
          companyId: "62c4307c5dba72e1eb1b6493",
        }
      );

      if (response.data.status === 200 && response.data.Managers) {
        const manager = response.data.Managers;
        const firstname = manager.firstname;
        const lastname = manager.lastname;
        const mobile = manager.mobile;
        const managerName = `${firstname} ${lastname}`;
        this.setState({
          manager_name: managerName,
          manager_mobile_number: mobile,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  getCities = (slug) => {
    axios
      .get(`${config.backend_URL}/admin/getCities`)
      .then((responseJson) => {
        this.setState({ cities: responseJson.data.data });
        this.getProperties(slug, responseJson.data.data);
        this.setState({ loader_flag: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loader_flag: false });
      });
  };

  getProperties = (slug, city) => {
    this.setState({ loader_flag: true });
    axios
      .post(`${config.backend_URL}/admin/getProperty`, { slug: slug })
      .then((responseJson) => {
        console.log("responseJson>>>>>>", responseJson);
        const cityName = city.filter(
          (city) => city._id === responseJson.data.data.cityId
        )[0]
          ? city.filter((city) => city._id === responseJson.data.data.cityId)[0]
              .city
          : "none";
        try {
          ScriptUtils.dynamicSubmitLink(
            responseJson.data.data.property_name,
            cityName
          );
        } catch (e) {
          console.log(e);
        }
        if (_.isEmpty(responseJson.data.data)) {
          this.props.history.push("/");
        } else {
          this.setState(responseJson.data.data);
          this.setState({ vendors1: responseJson.data.data.vendors });
          this.getTestimonials(responseJson.data.data._id);
          this.getCalendarUrl(responseJson.data.data.property_name);
          this.getPropertyManager(responseJson.data.data.property_name);
          console.log(responseJson);
          if (responseJson.data.data.property_blocks) {
            // responseJson.data.data.property_blocks.map((data) => {
            //   // data.Block_image.map((item, index) => {
            //   this.setState({
            //     push_images: [
            //       ...this.state.push_images,
            //       config.backend_URL + "/" + data.Block_image,
            //     ],
            //   });
            //   // });
            // });
            let data = responseJson.data.data.property_blocks.map(
              (item) => item.Block_image
            );
            const blockImages = data.flat();
            const newArray = blockImages.map(
              (value) => config.backend_URL + "/" + value
            );
            this.setState({ push_images: newArray });
            console.log("newArray", newArray);
          }
          //set Meta Tags
          helper.setMetaTitle(responseJson.data.data.meta_title);
          helper.setMetaDesc(responseJson.data.data.meta_desc);
          helper.setMetaKeyword(responseJson.data.data.meta_keyword);
        }
        this.setState({ loader_flag: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loader_flag: false });
      });
  };

  getRatingsStar = (stars, flag) => {
    let html = [];
    for (var i = 0; i < stars; i++) {
      if (flag === "fb") {
        html.push(
          <i>
            <img src={config.cdn_URL + "/images/rateIconBlue.png"} alt="fb" />
          </i>
        );
      } else {
        html.push(
          <i>
            <img
              src={config.cdn_URL + "/images/rateIconOrange.png"}
              alt="google"
            />
          </i>
        );
      }
    }
    return html;
  };

  mySubmit = (e) => {
    e.preventDefault();
    let error = 0;
    let arry = "";
    if (this.state.name === "") {
      arry += "Name can not be empty* ";
      toast("Name can not be empty* ");
      error++;
    }

    if (this.state.number === "") {
      arry += "Number can not be empty* ";
      toast("Number can not be empty* ");
      error++;
    }
    if (this.state.email === "") {
      arry += "Email can not be empty* ";
      toast("Email can not be empty* ");
      error++;
    }
    if (this.state.subject === "") {
      arry += "Subject can not be empty* ";
      toast("Subject can not be empty* ");
      error++;
    }

    if (this.state.message === "") {
      arry += "Message can not be empty* ";
      toast("Message can not be empty* ");
      error++;
    }
    console.log(error);

    //this.setState({ errors: arry })
    if (error > 0) {
      $("#error").html(arry);
      //toast(arry)
    } else {
      $("#error").html("");

      let manager_email = this.state.manager_emails
        .map(function (elem) {
          return elem.manager_email;
        })
        .join(",");

      let newObj = {
        propertyId: this.state._id,
        property_name: this.state.property_name,
        manager_email: manager_email,
        flag: this.state.flag,
        name: this.state.name,
        number: this.state.number,
        email: this.state.email,
        subject: this.state.subject,
        message: this.state.message,
      };
      this.setState({ loader_flag: true });
      axios
        .post(`${config.backend_URL}/admin/addFeedbacks`, newObj)
        .then(async (data) => {
          console.log(data);
          if (data.data.status === true) {
            toast("Thanks, your request has been submitted.");
            await this.setState(this.initialState);
          } else {
            toast("Something wrong!");
          }
          this.setState({ loader_flag: false });
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loader_flag: false });
        });
    }
  };

  getYoutubeCode = (link) => {
    return link?.replace(
      "https://www.youtube.com/watch?v=",
      "https://www.youtube.com/embed/"
    );
  };

  changedata = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  createMap = (lat, long) => {
    let zoom = 15;
    let mapScale = 591657550.5 / Math.pow(2, zoom + 1);
    var base64 = btoa([lat, long]);
    let mapStr =
      "https://www.google.com/maps/embed?key=asdfsdfsf&pb=!1m7!1m2!1m1!1d" +
      mapScale +
      "!3m3!1m2!1s0!2z" +
      base64;
    return mapStr;
  };
  get_url_extension(url) {
    return url.split(/[#?]/)[0].split(".").pop().trim();
  }

  datea = () => {
    return (
      this.state.vendors1 &&
      this.state.vendors1.map((x, key) => {
        console.log("vendor", this.state.vendors1);

        function isNumeric(str) {
          if (typeof str != "string") return false; // we only process strings!
          return (
            !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str))
          ); // ...and ensure strings of whitespace fail
        }

        return (
          <SwiperSlide>
            <div className="vendorCol">
              <div className="vendorImg">
                <img
                  src={config.backend_URL + "/" + x.logo}
                  alt="Vendor Logo"
                />
              </div>

              <div className="vendorInfo">
                <p>{x.description}</p>
              </div>
              <div className="vendorBtn">
                {(() => {
                  if (x.buttonLink.includes("https://")) {
                    return (
                      <a href={x.buttonLink} className="btnCommon">
                        {x.buttonText}
                      </a>
                    );
                  } else if (x.buttonLink.includes("www")) {
                    const link = "https://" + x.buttonLink;
                    return (
                      <a href={link} className="btnCommon">
                        {x.buttonText}
                      </a>
                    );
                  } else if (x.buttonLink.includes("@")) {
                    const link = "Mailto:" + x.buttonLink;
                    return (
                      <a href={link} className="btnCommon">
                        {x.buttonText}
                      </a>
                    );
                  } else if (isNumeric(x.buttonLink)) {
                    const link = "tel:" + x.buttonLink;
                    return (
                      <a href={link} className="btnCommon">
                        {x.buttonText}
                      </a>
                    );
                  } else {
                    return <>{/* <Loader /> */}</>;
                  }
                })()}
              </div>
            </div>
          </SwiperSlide>
        );
      })
    );
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen) {
      console.log(
        "isOpen",
        this.state.isOpen,
        prevState.isOpen,
        this.state.push_images
      );
    }
  }

  render() {
    const { photoIndex, isOpen } = this.state;
    let imagePath1 = config.cdn_backend_URL + "/" + this.state.property_image;
    let imgPath =
      this.state.property_image === ""
        ? `${config.cdn_backend_URL}/default.jpg`
        : imagePath1;
    const imgErrorPath = config.cdn_URL + "/no-img.png";
    console.log(
      "video",
      this.get_url_extension(
        config.cdn_backend_URL + "/" + this.state.background_video
      )
    );
    console.log("property block ", this.state.bathroomData);

    return (
      <>
        <HeaderFront />
        {this.state.loader_flag ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <section>
              <article
                className="prprtyMainVdoBlock wrapper"
                /* style={{ backgroundImage: `url("images/novaVillaSlide1.jpg")` }} */
              >
                {this.state.background_video?.url ? (
                  this.get_url_extension(this.state.background_video?.url) ===
                  "mp4" ? (
                    <div className="prprtyMainVideo">
                      <video muted autoPlay width="320" height="240">
                        <source
                          src={this.state?.background_video?.url}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  ) : (
                    <img src={this.state.background_video?.url} />
                  )
                ) : ["mp4", "wav"].includes(
                    this.get_url_extension(
                      config.cdn_backend_URL + "/" + this.state.background_video
                    )
                  ) ? (
                  <div className="prprtyMainVideo">
                    <video muted autoPlay width="320" height="240">
                      <source
                        src={
                          config.cdn_backend_URL +
                          "/" +
                          this.state.background_video
                        }
                        type="video/mp4"
                      />
                    </video>
                  </div>
                ) : (
                  <img
                    src={
                      config.cdn_backend_URL + "/" + this.state.background_video
                    }
                  />
                )}

                <div className="prprtyMainVdoTextDiv">
                  <div className="container h-100 d-flex align-items-center justify-content-center">
                    <div className="prprtyMainVdoText">
                      <h1>{this.state.property_name}</h1>
                      <p>
                        <i className="d-inline-block px-2">
                          <img
                            src={config.cdn_URL + "/images/locIcon.png"}
                            alt={this.state.property_name}
                          />
                        </i>
                        {this.state.address}
                      </p>
                      {/* <div className="resAppBtn">
                                        <Link to="/" className="btnCommon">Resident's Application</Link>
                                    </div> */}

                      {/* {this.state.application_link ? (
                        <><Loader /></>
                      ) : (
                        ""
                      )} */}

                      {/*<div className="resAppBtn">
                          <a
                            href={this.state.application_link}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <div className="btnCommon">
                              Resident's Application
                            </div>
                          </a>
                        </div>*/}
                    </div>
                  </div>
                </div>

                <div className="mainSldrOverlay"></div>
              </article>

              {this.state.property_highlights ? (
                <>
                  <article class="prprtyRatesBock wrapper py-80">
                    <div style={{ paddingBottom: "35px", textAlign: "center" }}>
                      <ul>
                        <li className="menuBtn">
                          <a
                            href={this.state.url}
                            className="btnCommon"
                            style={{
                              marginBottom: "12px",
                              width: "20rem",
                              fontSize: "1rem",
                              padding: "5px 0px",
                            }}
                          >
                            Book An Appointment
                          </a>
                          <div>
                            <p className="font-weight-bold text-dark">
                              Manager:{" "}
                              {this.state.manager_name &&
                                this.state.manager_name}
                            </p>
                            <p className="font-weight-bold text-dark">
                              Phone Number:{" "}
                              {this.state.manager_mobile_number &&
                                this.state.manager_mobile_number}
                            </p>
                          </div>
                        </li>
                      </ul>
                    </div>

                    <div class="container">
                      <div class="row g-4 align-items-center justify-content-center">
                        {this.state.property_highlights &&
                          this.state.property_highlights.map((x) => {
                            let imgPath =
                              config.cdn_backend_URL + "/" + x.highlight_icon;
                            return (
                              <div class="col-sm-6 col-md-6 col-lg-4 col-xl">
                                <div class="prprtyRateCol">
                                  <div class="prprtyRateIcon">
                                    <span>
                                      <img src={imgPath} alt="property Rate" />
                                    </span>
                                  </div>
                                  <div class="prprtyRateTtl">
                                    <h4>
                                      <sup></sup>
                                      {x.highlight_amount}
                                    </h4>
                                    <p>
                                      {x.highlight_text.substring(0, 100)}
                                      {x.highlight_text.length > 100
                                        ? "..."
                                        : ""}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </article>
                </>
              ) : (
                ""
              )}
              <article class="prprtyDtlBock wrapper">
                <div class="container">
                  <div class="prprtyDtlRow py-80">
                    <div class="row">
                      <div class="col-lg-7 col-xl-8">
                        <div class="prprtyDtlContent">
                          <div class="mainHeading headingSmallNo">
                            <h2>
                              {this.state.property_title}
                              <span> </span>
                            </h2>
                            <div class="hdngBrdrs">
                              <span class="hdngBrdr1"></span>
                              <span class="hdngBrdr2"></span>
                              <span class="hdngBrdr3"></span>
                            </div>
                            <p>{this.state.property_desc}</p>
                          </div>
                        </div>
                        <div class="prprtyFeaItem">
                          <div class="mainHeading headingSmallNo">
                            <h2>
                              Property <span>Features</span>
                            </h2>
                            <div class="hdngBrdrs">
                              <span class="hdngBrdr1"></span>
                              <span class="hdngBrdr2"></span>
                              <span class="hdngBrdr3"></span>
                            </div>
                          </div>
                          <div class="infoList infoListFullNo">
                            <ul>
                              {this.state.property_features &&
                                this.state.property_features.map((fe, key) => {
                                  return (
                                    <li>
                                      {" "}
                                      <i>
                                        <img
                                          src={
                                            config.cdn_URL +
                                            "/images/checkIcon.png"
                                          }
                                          alt="Check Icon"
                                        />
                                      </i>{" "}
                                      {fe.feature}{" "}
                                    </li>
                                  );
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-lg-5 col-xl-4">
                        <div
                          class="prprtyDtlImg"
                          style={{ backgroundImage: `url(${imgPath})` }}
                        >
                          <div class="prprtyCallCol">
                            <div class="prprtyCallInfo">
                              <div class="prprtyCallIcon">
                                <img
                                  src={
                                    config.cdn_URL + "/images/phone-call1.png"
                                  }
                                  alt="Schedule a Tour"
                                />
                              </div>
                              <div class="prprtyCallDes">
                                <h4>Schedule A Tour</h4>
                                <p>
                                  Or reach us directly at
                                  <br />
                                  <a href={`tel:${this.state.manager_number}`}>
                                    {" "}
                                    {this.state.manager_number}
                                  </a>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>

              {this.state.property_youtube &&
              this.state.property_youtube.length > 0 ? (
                <article class="prprtyVdoBock wrapper py-80">
                  <div class="container">
                    <div class="row justify-content-center">
                      <div class="col-sm-6">
                        <div class="mainHeading headingWhite headingCenter">
                          <h2>
                            {this.state.youtube_video_main_heading
                              ? this.state.youtube_video_main_heading
                              : this.state.property_title}
                          </h2>
                          {/* <h2>Check Out Nova Villa's 2 Bed &amp; 2 Bath Luxury Apartment</h2> */}
                          <div class="hdngBrdrs">
                            <span class="hdngBrdr1"></span>
                            <span class="hdngBrdr2"></span>
                            <span class="hdngBrdr3"></span>
                          </div>
                        </div>
                      </div>

                      <div class="col-sm-12">
                        <div class="row g-xl-5 g-4 justify-content-center">
                          {this.state.property_youtube &&
                            this.state.property_youtube.map((element, key) => {
                              console.log(
                                this.state.property_youtube,
                                ">>>>>>>>>this.state.property_youtube"
                              );
                              let youTubeLink = this.getYoutubeCode(
                                element.youtube_video_link
                              );
                              return (
                                <div class="col-lg-4 col-md-6">
                                  <div class="virtualTourCol">
                                    <div class="virtualTourFrame">
                                      <iframe
                                        loading="lazy"
                                        title={key}
                                        src={`${youTubeLink}?autoplay=1&mute=1?autoplay=1&mute=1`}
                                        allowFullScreen="allowfullscreen"
                                      ></iframe>
                                    </div>
                                    <div class="virtualTourTtl">
                                      <h4>{element.youtube_video_heading}</h4>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
              ) : (
                ""
              )}

              {this.state.property_virtualTours &&
              this.state.property_virtualTours.length > 0 ? (
                <article class="virtualTourBlock wrapper py-80">
                  <div class="container">
                    <div class="mainHeading headingCenter">
                      <h2>
                        Checkout Our <span>Virtual Tours</span>
                      </h2>
                      <div class="hdngBrdrs">
                        <span class="hdngBrdr1"></span>
                        <span class="hdngBrdr2"></span>
                        <span class="hdngBrdr3"></span>
                      </div>
                    </div>
                    <div class="row g-xl-5 g-4 justify-content-center">
                      {this.state.property_virtualTours &&
                        this.state.property_virtualTours.map((tour, key) => {
                          return (
                            <div
                              class="col-lg-4 col-md-6"
                              title={tour.virtual_tours_title}
                            >
                              <div class="virtualTourCol h-100">
                                <div class="virtualTourFrame">
                                  <iframe
                                    loading="lazy"
                                    title={key}
                                    src={`${tour.virtual_tours_link}`}
                                    allowFullScreen="allowfullscreen"
                                  ></iframe>
                                </div>
                                <div class="virtualTourTtl">
                                  {/* <h4>{tour.virtual_tours_title}</h4> */}
                                  <h4>
                                    {tour.virtual_tours_title.substring(0, 50)}
                                    {tour.virtual_tours_title.length > 50
                                      ? "..."
                                      : ""}
                                  </h4>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </article>
              ) : (
                ""
              )}

              {this.state.testimonials.length > 0 ||
              this.state.google_rating > 0 ||
              this.state.facebook_rating > 0 ? (
                <article class="prprtyRvwsBlock wrapper py-80">
                  <div class="container">
                    {this.state.google_rating > 0 ||
                    this.state.facebook_rating > 0 ? (
                      <div class="prprtyRvwsHdng">
                        <div class="row g-md-5 g-4 align-items-center">
                          <div class="col-md">
                            <div class="mainHeading headingWhite m-0">
                              <h2>
                                {this.state.review_title
                                  ? this.state.review_title
                                  : "Room Design is all about functionality & comfort."}
                              </h2>
                              <div class="hdngBrdrs">
                                <span class="hdngBrdr1"></span>
                                <span class="hdngBrdr2"></span>
                                <span class="hdngBrdr3"></span>
                              </div>
                            </div>
                          </div>

                          <div className="col-md-auto">
                            <div className="reviewRatingDiv">
                              <div className="reviewRatingRow">
                                {this.state.google_rating > 0 ? (
                                  <a
                                    href={this.state.google_page_link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <div className="rvwRatingCol">
                                      <div className="rvwRatingIcon">
                                        <img
                                          src={
                                            config.cdn_URL +
                                            "/images/googleIcon.png"
                                          }
                                          alt="Google"
                                        />
                                      </div>
                                      <div className="rvwRatingDes">
                                        <div className="rvwRatingTtl">
                                          Google Rating
                                        </div>
                                        <div className="rvwRatingScore">
                                          <span>
                                            {" "}
                                            {this.state.google_rating}{" "}
                                          </span>
                                          <ReactStars
                                            count={5}
                                            size={32}
                                            isHalf={true}
                                            emptyIcon={
                                              <i className="far fa-star"></i>
                                            }
                                            halfIcon={
                                              <i className="fa fa-star-half-alt"></i>
                                            }
                                            fullIcon={
                                              <i className="fa fa-star"></i>
                                            }
                                            activeColor="#db6b0d"
                                            edit={false}
                                            value={this.state.google_rating}
                                          />
                                          {/* <div class="rvwRatingStars">
                                            {this.getRatingsStar(
                                              this.state.google_rating,
                                              "google"
                                            )}
                                          </div> */}
                                        </div>

                                        <div className="rvwRatingNote">
                                          <p> {this.state.google_text} </p>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                ) : (
                                  ""
                                )}

                                {this.state.facebook_rating > 0 ? (
                                  <a
                                    href={this.state.facebook_page_link}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <div className="rvwRatingCol">
                                      <div className="rvwRatingIcon">
                                        <img
                                          src={
                                            config.cdn_URL +
                                            "/images/facebookIcon.png"
                                          }
                                          alt="Facebook"
                                        />
                                      </div>
                                      <div className="rvwRatingDes">
                                        <div className="rvwRatingTtl">
                                          Facebook Rating
                                        </div>
                                        <div className="rvwRatingScore">
                                          <span>
                                            {this.state.facebook_rating}
                                          </span>
                                          <ReactStars
                                            count={5}
                                            size={32}
                                            isHalf={true}
                                            emptyIcon={
                                              <i className="far fa-star"></i>
                                            }
                                            halfIcon={
                                              <i className="fa fa-star-half-alt"></i>
                                            }
                                            fullIcon={
                                              <i className="fa fa-star"></i>
                                            }
                                            activeColor="#0d4880"
                                            edit={false}
                                            value={this.state.facebook_rating}
                                          />
                                          {/* <div className="rvwRatingStars">
                                            {this.getRatingsStar(
                                              this.state.facebook_rating,
                                              "fb"
                                            )}
                                          </div> */}
                                        </div>
                                        <div className="rvwRatingNote">
                                          <p> {this.state.facebook_text} </p>
                                        </div>
                                      </div>
                                    </div>
                                  </a>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}

                    <div class="prprtyRrvwsSliderOuter pt-80">
                      <div class="propertyReviewSlider crslCntrls">
                        <Swiper
                          modules={[Navigation, Pagination, Scrollbar, A11y]}
                          navigation={
                            this.state.testimonials.length > 3 ? true : false
                          }
                          spaceBetween={30}
                          slidesPerView={3}
                          loop={true}
                          autoplay={{ delay: 3000 }}
                          breakpoints={{
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            576: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            991: {
                              slidesPerView: 2,
                              spaceBetween: 30,
                            },
                            1200: {
                              slidesPerView: 3,
                              spaceBetween: 30,
                            },
                          }}
                        >
                          {this.state.testimonials.length > 0
                            ? this.state.testimonials.map((x, key) => {
                                let imgPath =
                                  config.cdn_backend_URL + "/" + x.image;
                                return (
                                  <SwiperSlide>
                                    <div class="prprtyRvwslide">
                                      <div class="prprtyRvwAuthor">
                                        <span>
                                          <img
                                            src={imgPath}
                                            alt="Testimonial"
                                            onError={({ currentTarget }) => {
                                              currentTarget.onerror = null;
                                              currentTarget.src = imgErrorPath;
                                            }}
                                          />
                                        </span>
                                        <div class="prprtyRvwTitle">
                                          <h4>
                                            {" "}
                                            {x.name.substring(
                                              0,
                                              x.name.indexOf(" ") + 2
                                            )}{" "}
                                          </h4>
                                        </div>
                                      </div>
                                      <div class="prprtyRvwsContent">
                                        <p>“{x.desc}”</p>
                                      </div>
                                    </div>
                                  </SwiperSlide>
                                );
                              })
                            : ""}
                        </Swiper>
                      </div>
                    </div>
                  </div>
                </article>
              ) : (
                ""
              )}

              {(this.state.property_blocks &&
                this.state.property_blocks.length) > 0 ? (
                <article class="adtnPrprtyBlock wrapper">
                  <div class="adtnPrprtySldrRow py-80">
                    <div
                      className="adtnPrprtySldrBefore"
                      style={{
                        backgroundImage: `url(${
                          config.cdn_URL + "/images/buildingPatt1.png"
                        })`,
                      }}
                    ></div>
                    <div class="container">
                      <div class="mainHeading headingCenter">
                        <h2>
                          Checkout Our <span>Property Images</span>
                        </h2>
                        <div class="hdngBrdrs">
                          <span class="hdngBrdr1"></span>
                          <span class="hdngBrdr2"></span>
                          <span class="hdngBrdr3"></span>
                        </div>
                      </div>
                      <div class="adtnPrprtySliderOuter crslCntrls">
                        <Swiper
                          modules={[Navigation, Pagination, Scrollbar, A11y]}
                          navigation={
                            this.state.property_blocks.length > 2 ? true : false
                          }
                          spaceBetween={30}
                          slidesPerView={2}
                          autoplay={{ delay: 3000 }}
                          breakpoints={{
                            320: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            576: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            768: {
                              slidesPerView: 1,
                              spaceBetween: 20,
                            },
                            991: {
                              slidesPerView: 1,
                              spaceBetween: 30,
                            },
                            1200: {
                              slidesPerView: 2,
                              spaceBetween: 30,
                            },
                          }}
                        >
                          {console.log(
                            "this.state.property_blocks",
                            this.state.property_blocks
                          )}
                          {this.state.property_blocks &&
                            this.state.property_blocks.map((x, key) => {
                              let imgPath =
                                config.cdn_backend_URL +
                                  "/" +
                                  x.Block_image.length >=
                                1
                                  ? x.Block_image.map((item) => {
                                      return item;
                                    })
                                  : x.Block_image[0];
                              console.log("imgPath", x.Block_image);
                              return (
                                <SwiperSlide>
                                  <div class="adtnPrprtyCol">
                                    <div
                                      class="adtnPrprtyImg"
                                      style={{
                                        backgroundImage: `url(${
                                          x.Block_image
                                            ? config.backend_URL + "/" + imgPath
                                            : config.backend_URL +
                                              "/images/amenities5.jpg"
                                        })`,
                                      }}
                                    ></div>
                                    <div class="adtnPrprtyDesOuter">
                                      <div class="adtnPrprtyDes">
                                        <div class="adtnPrprtyPrice">
                                          <h3>Rent - Starting from</h3>
                                          <h4>
                                            <sup>$</sup>
                                            {x.block_price}
                                          </h4>
                                        </div>
                                        <div class="adtnPrprtyInfo">
                                          <ul>
                                            <li>
                                              <i>
                                                <img
                                                  src={
                                                    config.cdn_URL +
                                                    "/images/beds.png"
                                                  }
                                                  alt="Beds"
                                                />
                                                <img
                                                  src={
                                                    config.cdn_URL +
                                                    "/images/bedsWhite.png"
                                                  }
                                                  alt="Beds White"
                                                />
                                              </i>{" "}
                                              Beds:{" "}
                                              {this.state.bedroomData.map(
                                                (y) => {
                                                  var mt =
                                                    y._id == x.bedroom ? (
                                                      <span> {y.bedroom} </span>
                                                    ) : (
                                                      <span> </span>
                                                    );
                                                  return mt;
                                                }
                                              )}
                                            </li>
                                            <li>
                                              <i>
                                                <img
                                                  src={
                                                    config.cdn_URL +
                                                    "/images/baths.png"
                                                  }
                                                  alt="Baths"
                                                />
                                                <img
                                                  src={
                                                    config.cdn_URL +
                                                    "/images/bathsWhite.png"
                                                  }
                                                  alt="Baths White"
                                                />
                                              </i>{" "}
                                              Baths:{" "}
                                              {this.state.bathroomData.map(
                                                (y) => {
                                                  var mt =
                                                    y._id == x.bathroom ? (
                                                      <span>
                                                        {" "}
                                                        {y.bathroom}{" "}
                                                      </span>
                                                    ) : (
                                                      <span> </span>
                                                    );
                                                  return mt;
                                                }
                                              )}
                                            </li>
                                          </ul>
                                        </div>
                                      </div>

                                      <div class="adtnPrprtyScrty">
                                        <p>
                                          Security Deposit - <sup>$</sup>
                                          {x.security_deposit}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </SwiperSlide>
                              );
                            })}
                        </Swiper>
                      </div>
                      <div class="viewGalleryBtn mt-4 text-center">
                        <button
                          className="btnCommon"
                          type="button"
                          onClick={() =>
                            this.setState({ isOpen: !this.state.isOpen })
                          }
                        >
                          View Images
                        </button>
                        {console.log(
                          "this.state.push_images",
                          this.state.push_images
                        )}
                        {isOpen && this.state.push_images.length > 0 && (
                          <Lightbox
                            mainSrc={this.state.push_images[photoIndex]}
                            nextSrc={
                              this.state.push_images[
                                (photoIndex + 1) % this.state.push_images.length
                              ]
                            }
                            prevSrc={
                              this.state.push_images[
                                (photoIndex +
                                  this.state.push_images.length -
                                  1) %
                                  this.state.push_images.length
                              ]
                            }
                            onCloseRequest={() =>
                              this.setState({ isOpen: false })
                            }
                            onMovePrevRequest={() =>
                              this.setState({
                                photoIndex:
                                  (photoIndex +
                                    this.state.push_images.length -
                                    1) %
                                  this.state.push_images.length,
                              })
                            }
                            onMoveNextRequest={() =>
                              this.setState({
                                photoIndex:
                                  (photoIndex + 1) %
                                  this.state.push_images.length,
                              })
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </article>
              ) : (
                ""
              )}
              {/* {console.log("this.state.vendors1", this.state.vendors1)} */}
              {this.state.vendors1 && !this.state.vendors1[0]?.logo == "" ? (
                <article class="vendorsBlock wrapper py-80 gskRetailPartners">
                  <div class="container">
                    <div class="mainHeading headingCenter">
                      <h2>GSK Resident Retail Partners</h2>
                      <div class="hdngBrdrs">
                        <span class="hdngBrdr1"></span>
                        <span class="hdngBrdr2"></span>
                        <span class="hdngBrdr3"></span>
                      </div>
                    </div>
                    <div class="vendorsSliderOuter crslCntrls">
                      <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        navigation
                        spaceBetween={20}
                        slidesPerView={4}
                        autoplay={{ delay: 3000 }}
                        breakpoints={{
                          320: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                          },
                          576: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                          },
                          768: {
                            slidesPerView: 2,
                            spaceBetween: 20,
                          },
                          991: {
                            slidesPerView: 3,
                            spaceBetween: 30,
                          },
                          1200: {
                            slidesPerView: 4,
                            spaceBetween: 20,
                          },
                        }}
                      >
                        {this.datea()}
                      </Swiper>
                    </div>
                  </div>
                </article>
              ) : (
                <></>
              )}

              <article class="bookApointBlock wrapper py-80">
                <div
                  className="bookApointBefore"
                  style={{
                    backgroundImage: `url(${
                      config.cdn_URL + "/images/bookAppointment.png"
                    })`,
                  }}
                ></div>
                <div class="container">
                  <div class="row g-5">
                    <div class="col-lg-3 col-xl-3">
                      {/* <div class="homeCntctDiv">
                        <div class="homeCntctForm">
                          <div class="mainHeading headingSmall headingCenter">
                            <h2>Make an Appointment</h2>
                            <div class="hdngBrdrs">
                              <span class="hdngBrdr1"></span>
                              <span class="hdngBrdr2"></span>
                              <span class="hdngBrdr3"></span>
                            </div>
                            <p>
                              Please fill out the form and our Community Leader
                              will get in contact with you.
                            </p>
                          </div>

                          <form onSubmit={this.mySubmit}>
                            <div className="cntctBrdrTtl">APPOINTMENT</div>

                            <div class="form-group selectedService">
                              <label>Property</label>
                              <i>
                                <img
                                  src={
                                    config.cdn_URL + "/images/propertyIcon.png"
                                  }
                                  alt="Property"
                                />
                              </i>
                              <input
                                class="form-control"
                                type="text"
                                name="property_name"
                                value={this.state.property_name}
                                readOnly
                              />
                            </div>

                            <div className="form-group">
                              <label>
                                Name <span className="astrict">*</span>
                              </label>
                              <i>
                                <img
                                  src={config.cdn_URL + "/images/userIcon.png"}
                                  alt="Name"
                                />
                              </i>
                              <input
                                className="form-control"
                                type="text"
                                name="name"
                                placeholder="Write Name"
                                onChange={this.changedata}
                                value={this.state.name}
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                Mobile Number <span className="astrict">*</span>
                              </label>
                              <i>
                                <img
                                  src={config.cdn_URL + "/images/callIcon.png"}
                                  alt="Mobile Number"
                                />
                              </i>
                              <input
                                className="form-control number"
                                type="text"
                                name="number"
                                placeholder="+1 _ _ _   _ _ _   _ _ _"
                                onChange={this.changedata}
                                value={this.state.number}
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                Email <span className="astrict">*</span>
                              </label>
                              <i>
                                <img
                                  src={config.cdn_URL + "/images/mailIcon.png"}
                                  alt="Email"
                                />
                              </i>
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                placeholder="Enter your email"
                                onChange={this.changedata}
                                value={this.state.email}
                              />
                            </div>
                            <div className="form-group">
                              <label>
                                Subject <span className="astrict">*</span>
                              </label>
                              <i>
                                <img
                                  src={
                                    config.cdn_URL + "/images/subjectIcon.png"
                                  }
                                  alt="Subject"
                                />
                              </i>
                              <input
                                className="form-control"
                                type="text"
                                name="subject"
                                placeholder="Perfect Home"
                                onChange={this.changedata}
                                value={this.state.subject}
                              />
                            </div>
                            <div className="form-group formGroupTextarea">
                              <label>
                                Message Me <span className="astrict">*</span>
                              </label>
                              <textarea
                                className="form-control"
                                type="text"
                                name="message"
                                placeholder="Hi, I’m happy with home."
                                onChange={this.changedata}
                                value={this.state.message}
                              ></textarea>
                            </div>
                            <div className="form-btn">
                              <button
                                className="btn btnCommon w-100"
                                type="Submit"
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div> */}
                    </div>
                    <div class="col-lg-9 col-xl-9">
                      <div className="bookApointMap h-100">
                        <iframe
                          title="address"
                          src={this.createMap(this.state.lat, this.state.lng)}
                          allowFullScreen=""
                          loading="lazy"
                          style={{ height: 450 }}
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </section>
          </>
        )}
        <FooterFront />
      </>
    );
  }
}
export default PropertyDetail;
