import React from "react";
import { Link } from "react-router-dom";
import helper from "../helper.js";
import { HeaderFront, FooterFront } from "../components"; 
import WhyChooseUs_section from "./WhyChooseUs_home.js";
import config from "../config.js";
const axios = require("axios");

class WhyChooseUs extends React.Component{

    componentDidMount(){
        this.getMetaTags('whychooseus');        
    }
    
    // Meta Tags Set Sart
    getMetaTags = (page_name) => {
        axios.post(`${config.backend_URL}/admin/getMetaTags`,{ page_name:page_name })
          .then((responseJson) => {
             
                //set Meta Tags
                helper.setMetaTitle(responseJson.data.data.meta_title);  
                helper.setMetaDesc(responseJson.data.data.meta_desc);  
                helper.setMetaKeyword(responseJson.data.data.meta_keyword);  
              
          })
          .catch((error) => {
            console.error(error);
          });
    }

    render(){

    return (
    <>
    <HeaderFront/>
    <div className="exceptAppWrapper">
      <section>
        <article className="pageHdrBlock wrapper py-80" style={{backgroundImage: `url(${config.cdn_URL + "/images/subpageBg.jpg"})`}}>
          <div className="pageHdrOverlay"></div>
          <div className="container">
              <div className="row align-items-center justify-space-beetween">
                  <div className="col-sm">
                      <h3>Why Choose Us</h3>
                  </div>
                  <div className="col-sm-auto">
                      <div className="pagebrdcrmbs">
                          <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                              {/* <li className="breadcrumb-item active" aria-current="page">Why Choose Us</li> */}
                          </ol>
                          </nav>
                      </div>
                  </div>
              </div>
          </div>
        </article>
        <WhyChooseUs_section />
      </section>
    </div>
    <FooterFront/>
    </>
  )
}
};

export default WhyChooseUs;