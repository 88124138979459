import React from "react";
import { Button, Modal } from "react-bootstrap";
import { FooterFront, HeaderFront } from "../components";
import config from "../config.js";
import helper from "../helper.js";
import Amenities from "./Amenities.js";
import Banner from "./Banner.js";
import Feedback_Form from "./Feedback_Form.js";
import Properties_Img from "./Properties_img.js";
import Search_Fields from "./Search_Fields.js";
import Testimonials from "./Testimonials_home.js";
import WhyChooseUs from "./WhyChooseUs_home.js";
import Loader from "../loader";

const axios = require("axios");
class Home extends React.Component {
  state = {
    show: false,
    solarShow: false,
    mainLoader: true,
  };

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  handleSolarClose = () => this.setState({ solarShow: false });
  handleSolarShow = () => this.setState({ solarShow: true });

  componentDidMount() {
    localStorage.removeItem("refresh");
    this.getMetaTags("home");
    setTimeout(() => {
      this.setState({ solarShow: true });
    }, 4000);
  }

  // Meta Tags Set Sart
  getMetaTags = (page_name) => {
    axios
      .post(`${config.backend_URL}/admin/getMetaTags`, { page_name: page_name })
      .then((responseJson) => {
        //set Meta Tags
        helper.setMetaTitle(responseJson.data.data.meta_title);
        helper.setMetaDesc(responseJson.data.data.meta_desc);
        helper.setMetaKeyword(responseJson.data.data.meta_keyword);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  changeMainLoader = (bool) => {
    console.log("main loader called", bool);
    this.setState({ mainLoader: bool });
  };

  render() {
    return (
      <>
        {this.state.mainLoader ? (
          <>
            <Loader />
            <HeaderFront changeMainLoader={this.changeMainLoader} mainLoader={"j"}/>
          </>
        ) : (
          <>
            <HeaderFront changeMainLoader={this.changeMainLoader} />
            <div className="exceptAppWrapper">
              <section>
                {/* Banner Section Component*/}
                <Banner />

                {/* Search Form Component*/}
                <Search_Fields />

                {/* Amenities Section Component*/}
                <Amenities />

                {/* Property Images Swiper Component*/}
                <Properties_Img />

                {/* Testimonials Section Component*/}
                <Testimonials />

                {/* WhyChooseUs Section Component*/}
                <WhyChooseUs />

                <article
                  className="homeCallActBlock wrapper pb-80"
                  style={{
                    backgroundImage: `url(${
                      config.cdn_URL + "/images/propertiesBg.png"
                    })`,
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center justify-space-between">
                      <div className="col-md">
                        <div className="callActContent">
                          <div className="mainHeading m-0">
                            <h2>Your Feedback Is Welcome</h2>
                            <div className="hdngBrdrs">
                              <span className="hdngBrdr1"></span>
                              <span className="hdngBrdr2"></span>
                              <span className="hdngBrdr3"></span>
                            </div>
                            <p>
                              Please fill out the form and our Community Leader
                              will get in contact with you.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-auto">
                        <div className="callActBtn">
                          <Button
                            variant="primary"
                            className="btn btnCommon"
                            onClick={this.handleShow}
                          >
                            Provide feedback
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>
                <Modal
                  show={this.state.show}
                  onHide={this.handleClose}
                  dialogClassName="homeCntctDiv fedBackPopDialog"
                >
                  <Modal.Header closeButton>
                    <Modal.Title className="mdlHeading">
                      Provide Us Your Feedback
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="feedBackPopup">
                      <div className="homeCntctForm">
                        <div className="mainHeading m-0">
                          <p className="m-0">
                            Please fill out the form and our Community Leader
                            will get in contact with you.
                          </p>
                        </div>

                        <Feedback_Form />
                      </div>
                    </div>
                  </Modal.Body>
                </Modal>
              </section>
              {/*  <Modal*/}
              {/*      show={this.state.solarShow}*/}
              {/*      onHide={this.handleSolarClose}*/}
              {/*      dialogClassName="solaPopupDialog fedBackPopDialog"*/}
              {/*  >*/}
              {/*      <Modal.Header closeButton>*/}
              {/*          <Modal.Title className="mdlHeading d-none">Solar Homes Tour</Modal.Title>*/}
              {/*      </Modal.Header>*/}
              {/*      <Modal.Body>*/}
              {/*        <div className="solaPopup">*/}
              {/*          <div className="solaPopupHdr">*/}
              {/*            <div className="solaPopupTtl">*/}
              {/*              <h2>GSK Properties Now Introduces Solar Powered</h2>*/}
              {/*              <h3>Rental Homes in ST Albert - The first of its kind</h3>*/}
              {/*            </div>*/}
              {/*            <div className="solaPopupMediaOuter">*/}
              {/*              <div className="solaPopupMedia" style={{backgroundImage: `url(images/solarMainImg.jpg)`}}></div>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*          <div className="solaPopupBody">*/}
              {/*            <div className="solaFeauList">*/}
              {/*              <Row className="g-4">*/}
              {/*                <Col md>*/}
              {/*                    <div className="solaFeauCol mt-md-4 mt-xl-5">*/}
              {/*                      <span>*/}
              {/*                        <img src="images/solarFeauImg1.jpg" />*/}
              {/*                      </span>*/}
              {/*                      <p>GSK Properties leads the path towards Green Revolution</p>*/}
              {/*                    </div>*/}
              {/*                </Col>*/}
              {/*                <Col md>*/}
              {/*                  <div className="solaFeauCol">*/}
              {/*                    <span>*/}
              {/*                      <img src="images/solarFeauImg2.jpg" />*/}
              {/*                    </span>*/}
              {/*                    <p>One of its kind GSK Properties introduces Environment Friendly Homes</p>*/}
              {/*                  </div>*/}
              {/*                </Col>*/}
              {/*                <Col md>*/}
              {/*                  <div className="solaFeauCol mt-md-4 mt-xl-5">*/}
              {/*                    <span>*/}
              {/*                      <img src="images/solarFeauImg3.jpg" />*/}
              {/*                    </span>*/}
              {/*                    <p>Powering Canada's Future with Solar Energy</p>*/}
              {/*                  </div>*/}
              {/*                </Col>*/}
              {/*              </Row>*/}
              {/*            </div>*/}
              {/*            <div className="solarSaving">*/}
              {/*              <i>*/}
              {/*                <img src="images/saveIcon.png" />*/}
              {/*              </i>*/}
              {/*              <div>*/}
              {/*                <h5>GSK solar powered rentals help to save your utilities bill upto $400 per month.</h5>*/}
              {/*                <h4>That’s a saving of whooping: 12*$400 = $4800 per year compared to a typical single family home.</h4>*/}
              {/*              </div>*/}
              {/*            </div>*/}
              {/*            <div className="solarBtn">*/}
              {/*              <Link to={`riverside-villas`} className="btnCommon">Solar Homes Tour</Link>*/}
              {/*            </div>*/}
              {/*          </div>*/}
              {/*          <div className="solaPopupFtr">*/}
              {/*            <p>Save Money, Save The Earth, Go Solar with GSK Rental Homes</p>*/}
              {/*          </div>*/}
              {/*        </div>*/}
              {/*      </Modal.Body>*/}
              {/*  </Modal>*/}
            </div>
            <FooterFront />
          </>
        )}
      </>
    );
  }
}

export default Home;
