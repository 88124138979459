import React from "react";

class Links extends React.Component{
    
    render(){
        return (
            <>
            <link rel="stylesheet" href="../plugins/fontawesome-free/css/all.min.css" />
             
            <link rel="stylesheet" href="../plugins/ionicons.min.css" />
             
            <link rel="stylesheet" href="../plugins/tempusdominus-bootstrap-4/css/tempusdominus-bootstrap-4.min.css" />
             
            <link rel="stylesheet" href="../plugins/icheck-bootstrap/icheck-bootstrap.min.css" />
             
            <link rel="stylesheet" href="../plugins/jqvmap/jqvmap.min.css" />
             
            <link rel="stylesheet" href="../dist/css/adminlte.min.css" />
            
            <link rel="stylesheet" href="../plugins/overlayScrollbars/css/OverlayScrollbars.min.css" />
             
            <link rel="stylesheet" href="../plugins/daterangepicker/daterangepicker.css"/>
             
            <link rel="stylesheet" href="../plugins/summernote/summernote-bs4.css"/>
             
            <link href="https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700" rel="stylesheet"/>
            <link rel="stylesheet" href="../plugins/jquery-ui.css"/>
            
            <link rel="stylesheet" href="../plugins/jquery.timepicker.min.css"/>
            <link rel="stylesheet" href="../css/selectp.css"/>
             
            <link rel="stylesheet" href="../plugins/toastr/toastr.min.css"/>
            
             
            <link rel="stylesheet" href="../plugins/datatables-bs4/css/dataTables.bootstrap4.min.css"/>



            <script src="../plugins/jquery/jquery.min.js" />

            <script src="../plugins/bootstrap/js/bootstrap.bundle.min.js" />

            <script src="../dist/js/adminlte.min.js" />
            </>
        );
    }
}
//export default withRouter(Login);

export default Links;