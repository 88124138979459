import React from "react";
import { ProgressBar } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.js";
import Loader from "../../loader.js";
//import ProgressBar from 'react-bootstrap/ProgressBar'
import "bootstrap/dist/css/bootstrap.min.css";

import slugify from "react-slugify";
const axios = require("axios");

class Properties extends React.Component {
  state = {
    text: "",
    description: "",
    link: "",
    logo: "",
    _id: "",
    Pid: "",
    categoryData: [],
    property_features: [{ feature: "" }],
    property_virtualTours: [
      { virtual_tours_title: "", virtual_tours_link: "" },
    ],
    property_highlights: [
      { highlight_icon: "", highlight_text: "", highlight_amount: "" },
    ],
    property_youtube: [{ youtube_video_heading: "", youtube_video_link: "" }],
    manager_emails: [{ manager_email: "" }],
    property_blocks: [
      {
        Block_image: [],
        block_price: "",
        security_deposit: "",
        bedroom: "",
        bathroom: "",
      },
    ],
    vendors: [
      {
        logo: "",
        description: "",
        buttonText: "",
        buttonLink: "",
        vendorOf: "",
      },
    ],
    cityData: [],
    cityId: "",
    categoryId: "",
    imageName: "",
    property_name: "",

    address: "",
    manager_number: "",
    property_image: "",
    property_title: "",
    property_desc: "",
    //property_features:[],
    google_rating: "",
    google_page_link: "",
    google_text: "",
    facebook_rating: "",
    facebook_page_link: "",
    facebook_text: "",
    errors: [],
    meta_title: "",
    meta_keyword: "",
    meta_desc: "",
    submitBtn: true,
    loader_flag: false,
    bathroomData: [],
    bedroomData: [],
  };

  initialState = {
    _id: "",
    text: "",
    description: "",
    link: "",
    logo: "",
    property_features: [{ feature: "" }],
    property_virtualTours: [
      { virtual_tours_title: "", virtual_tours_link: "" },
    ],
    property_highlights: [
      { highlight_icon: "", highlight_text: "", highlight_amount: "" },
    ],
    property_youtube: [{ youtube_video_heading: "", youtube_video_link: "" }],
    manager_emails: [{ manager_email: "" }],
    property_blocks: [
      {
        Block_image: [],
        block_price: "",
        security_deposit: "",
        bedroom: "",
        bathroom: "",
      },
    ],
    vendors: [
      {
        logo: "",
        description: "",
        buttonText: "",
        buttonLink: "",
        vendorOf: "",
      },
    ],
    cityId: "",
    vendorOf: "",
    categoryId: "",
    imageName: "",
    property_name: "",
    address: "",
    manager_number: "",
    property_image: "",
    property_title: "",
    property_desc: "",
    //property_features:[],
    google_rating: "",
    google_page_link: "",
    google_text: "",
    facebook_rating: "",
    facebook_page_link: "",
    facebook_text: "",
    errors: [],
    submitBtn: true,
    loader_flag: false,
  };

  openModal = (e) => {
    e.preventDefault();
    this.setState({ modalIsOpen: true });
  };

  closeModal = async (e) => {
    this.setState({ modalIsOpen: false });
  };

  changedata = (e) => {
    if (e.target.name === "property_name") {
      this.setState({ slug: slugify(e.target.value) });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  componentDidMount = async () => {
    console.log("test", this.state.property_youtube);
    this.setState({ loader_flag: true });
    await this.getCities();
    await this.getCategory();
    const queryParams = new URLSearchParams(window.location.search);
    const id = queryParams.get("id");
    await this.getProperty(id);

    await this.getBedroom();
    await this.getBathroom();
    this.setState({ loader_flag: false });
  };
  getBedroom = () => {
    axios
      .get(`${config.backend_URL}/admin/getBedroom`)
      .then((responseJson) => {
        console.log(responseJson.data.data);
        // return
        this.setState({ bedroomData: responseJson.data.data });
        console.log(this.state.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getBathroom = () => {
    axios
      .get(`${config.backend_URL}/admin/getBathroom`)
      .then((responseJson) => {
        console.log(responseJson.data.data);
        // return
        this.setState({ bathroomData: responseJson.data.data });
        console.log(this.state.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getProperty = (id) => {
    axios
      .post(`${config.backend_URL}/admin/getProperty`, { _id: id })
      .then((responseJson) => {
        console.log("test main", responseJson.data.data);
        this.setState(responseJson.data.data);

        if (responseJson.data.data.property_youtube.length == 0) {
          this.setState({
            property_youtube: [
              { youtube_video_heading: "", youtube_video_link: "" },
            ],
          });
        }
        if (responseJson.data.data.property_virtualTours.length == 0) {
          this.setState({
            property_virtualTours: [
              { virtual_tours_title: "", virtual_tours_link: "" },
            ],
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCities = () => {
    axios
      .get(`${config.backend_URL}/admin/getCities`)
      .then((responseJson) => {
        this.setState({ cityData: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCategory = () => {
    axios
      .get(`${config.backend_URL}/admin/getCategories`)
      .then((responseJson) => {
        this.setState({ categoryData: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  mySubmit = (e) => {
    e.preventDefault();

    //return ;
    //alert(this.state.imageName);
    let newObj = {
      property_image: this.state.property_image,
      background_video: this.state.background_video,
      property_name: this.state.property_name,
      slug: slugify(this.state.property_name),
      cityId: this.state.cityId,
      address: this.state.address,
      categoryId: this.state.categoryId,
      manager_number: this.state.manager_number,
      property_title: this.state.property_title,
      property_desc: this.state.property_desc,
      review_title: this.state.review_title,
      google_rating: Number(this.state.google_rating),
      google_page_link: this.state.google_page_link,
      google_text: this.state.google_text,
      facebook_rating: Number(this.state.facebook_rating),
      facebook_page_link: this.state.facebook_page_link,
      youtube_video_main_heading: this.state.youtube_video_main_heading,
      facebook_text: this.state.facebook_text,
      meta_title: this.state.meta_title,
      meta_keyword: this.state.meta_keyword,
      meta_desc: this.state.meta_desc,
      application_link: this.state.application_link,
    };

    let VirtualTours = [];
    this.state.property_virtualTours.map((x) => {
      if (x.virtual_tours_title !== "" && x.virtual_tours_link !== "") {
        VirtualTours.push(x);
      }
    });
    newObj["property_virtualTours"] = VirtualTours;
    // *****************************************************

    let Features = [];
    const newArr = this.state.property_features.map((x) => {
      if (x.feature !== "") {
        Features.push(x);
      }
    });
    newObj["property_features"] = Features;
    // *****************************************************

    let manager_emails = [];
    const newArr1 = this.state.manager_emails.map((x) => {
      if (x.manager_email !== "") {
        manager_emails.push(x);
      }
    });

    newObj["manager_emails"] = manager_emails;
    // *****************************************************

    let Highlights = [];
    const newArr3 = this.state.property_highlights.map((x) => {
      if (x.highlight_text !== "" && x.highlight_amount !== "") {
        Highlights.push(x);
      }
    });
    newObj["property_highlights"] = Highlights;
    // *****************************************************

    let Blocks = [];
    const newArr4 = this.state.property_blocks.map((x) => {
      if (x.highlight_text !== "" && x.highlight_amount !== "") {
        Blocks.push(x);
      }
    });

    newObj["property_blocks"] = Blocks;

    let Blocks1 = [];
    const newArr6 = this.state.vendors.map((x) => {
      if (x.highlight_text !== "" && x.highlight_amount !== "") {
        Blocks1.push(x);
      }
    });

    newObj["vendors"] = Blocks1;

    let property_youtube = [];
    const newArr5 = this.state.property_youtube.map((x) => {
      if (x.youtube_video_heading !== "" && x.youtube_video_link !== "") {
        property_youtube.push(x);
      }
    });

    newObj["property_youtube"] = property_youtube;
    // *****************************************************

    if (this.state._id !== "") {
      newObj["_id"] = this.state._id;
      //Update Property
      this.setState({ loader_flag: true });
      axios
        .post(`${config.backend_URL}/admin/updateProperty`, newObj)
        .then((data) => {
          // console.log("test property", data.data.status);
          //alert("hdhdhd");
          if (data.data.status == true) {
            toast(data.data.message);
          } else {
            toast(data.data.message);
          }
          this.setState({ loader_flag: false });
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loader_flag: false });
        });
    } else {
      //Insert Property
      this.setState({ loader_flag: true });
      axios
        .post(`${config.backend_URL}/admin/addProperty`, newObj)
        .then(async (data) => {
          if (data.data.status === true) {
            toast(data.data.message);
            await this.setState(this.initialState);
          } else {
            toast(data.data.message);
          }
          this.setState({ loader_flag: false });
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loader_flag: false });
        });
      // console.log('aniket', logo)
    }
  };

  onFileChange = (e) => {
    let newObj = new FormData();
    newObj.append("file", e.target.files[0]);
    axios
      .post(`${config.backend_URL}/admin/upload_single_image`, newObj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((data) => {
        this.setState({ property_image: data.data.data });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  onVideoChange = (e) => {
    let newObj = new FormData();
    newObj.append("file", e.target.files[0]);
    axios
      .post(`${config.backend_URL}/admin/uploadS3single`, newObj, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //Set the progress value to show the progress bar
          let progressNum = Math.round((100 * data.loaded) / data.total);
          if (progressNum < 100) {
            this.setState({ progress: progressNum, submitBtn: false });
          } else {
            this.setState({ progress: progressNum, submitBtn: true });
          }
        },
      })
      .then((data) => {
        this.setState({ background_video: data.data.data });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  onFileChange1 = (e) => {
    let index = e.target.getAttribute("data-key");
    let newObj = new FormData();
    newObj.append("file", e.target.files[0]);
    axios
      .post(`${config.backend_URL}/admin/upload_single_image`, newObj)
      .then((data) => {
        let property_highlights = this.state.property_highlights;
        property_highlights[index]["highlight_icon"] = data.data.data;
        this.setState({ property_highlights });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  onFileChange2 = (e) => {
    let index = e.target.getAttribute("data-key");
    let newObj = new FormData();
    for (let i = 0; i < e.target.files.length; i++) {
      newObj.append("file", e.target.files[i]);
    }

    axios
      .post(`${config.backend_URL}/admin/upload_multiple_image`, newObj)
      .then((data) => {
        let property_blocks = this.state.property_blocks;
        property_blocks[index]["Block_image"] = data.data.data;
        console.log({ property_blocks });
        this.setState({ property_blocks });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  onFileChange3 = (e) => {
    let index = e.target.getAttribute("data-key");
    let newObj = new FormData();
    newObj.append("file", e.target.files[0]);
    axios
      .post(`${config.backend_URL}/admin/upload_single_image`, newObj)
      .then((data) => {
        let vendors = this.state.vendors;
        vendors[index]["logo"] = data.data.data;
        this.setState({ vendors });
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // add More Features
  addEmailsFields() {
    this.setState({
      manager_emails: [...this.state.manager_emails, { manager_email: "" }],
    });
  }

  removeEmailsFields(i) {
    let manager_emails = this.state.manager_emails;
    manager_emails.splice(i, 1);
    this.setState({ manager_emails });
  }

  EmailsChange(i, e) {
    //alert(e.target.value);
    let manager_emails = this.state.manager_emails;
    manager_emails[i][e.target.name] = e.target.value;
    this.setState({ manager_emails });
  }

  // add More Features
  addFeaturesFields() {
    this.setState({
      property_features: [...this.state.property_features, { feature: "" }],
    });
  }

  removeFeaturesFields(i) {
    let property_features = this.state.property_features;
    property_features.splice(i, 1);
    this.setState({ property_features });
  }

  FeaturesChange(i, e) {
    //alert(e.target.value);
    let property_features = this.state.property_features;
    property_features[i][e.target.name] = e.target.value;
    this.setState({ property_features });
  }

  // add More VirtualTours
  addVirtualToursFields() {
    this.setState({
      property_virtualTours: [
        ...this.state.property_virtualTours,
        { virtual_tours_title: "", virtual_tours_link: "" },
      ],
    });
  }

  removeVirtualToursFields(i) {
    let property_virtualTours = this.state.property_virtualTours;
    property_virtualTours.splice(i, 1);
    this.setState({ property_virtualTours });
  }

  VirtualToursChange(i, e) {
    let property_virtualTours = this.state.property_virtualTours;
    property_virtualTours[i][e.target.name] = e.target.value;
    this.setState({ property_virtualTours });
  }

  //Youtube
  addYoutubeFields() {
    this.setState({
      property_youtube: [
        ...this.state.property_youtube,
        { youtube_video_heading: "", youtube_video_link: "" },
      ],
    });
  }

  removeYoutubeFields(i) {
    let property_youtube = this.state.property_youtube;
    property_youtube.splice(i, 1);
    this.setState({ property_youtube });
  }

  YoutubeChange(i, e) {
    let property_youtube = this.state.property_youtube;
    property_youtube[i][e.target.name] = e.target.value;
    this.setState({ property_youtube });
  }

  // add More Highlight
  addHighlightsFields() {
    this.setState({
      property_highlights: [
        ...this.state.property_highlights,
        { highlight_icon: "", highlight_text: "", highlight_amount: "" },
      ],
    });
  }

  removeHighlightsFields(i) {
    let property_highlights = this.state.property_highlights;
    property_highlights.splice(i, 1);
    this.setState({ property_highlights });
  }

  HighlightsChange(i, e) {
    let property_highlights = this.state.property_highlights;
    property_highlights[i][e.target.name] = e.target.value;
    this.setState({ property_highlights });
  }

  // add More Blocks
  addVendorFields() {
    this.setState({
      vendors: [
        ...this.state.vendors,
        {
          logo: "",
          description: "",
          buttonText: "",
          buttonLink: "",
        },
      ],
    });
  }
  removeVendorFields(i) {
    let vendors = this.state.vendors;
    vendors.splice(i, 1);
    this.setState({ vendors });
  }
  addBlocksFields() {
    this.setState({
      property_blocks: [
        ...this.state.property_blocks,
        {
          Block_image: [],
          block_price: "",
          security_deposit: "",
          bedroom: "",
          bathroom: "",
        },
      ],
    });
  }

  removeBlocksFields(i) {
    let property_blocks = this.state.property_blocks;
    property_blocks.splice(i, 1);
    this.setState({ property_blocks });
  }

  BlocksChange(i, e) {
    //alert(e.target.value);

    let property_blocks = this.state.property_blocks;
    if (e.target.name === "bedroom" || e.target.name === "bathroom") {
      property_blocks[i][e.target.name] = e.target.value;
    } else {
      property_blocks[i][e.target.name] = Number(e.target.value);
    }
    this.setState({ property_blocks });
  }
  BlocksChange1(i, e) {
    let vendors = this.state.vendors;
    vendors[i][e.target.name] = e.target.value;
    this.setState({ vendors });
  }

  render() {
    return (
      <>
        <ToastContainer />
        {this.state.loader_flag ? (
          <>
            <Loader />
          </>
        ) : (
          <>
            <div class="content-wrapper">
              <section class="content-header">
                <div class="container-fluid">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <h1> Add Properties </h1>
                    </div>

                    <div class="col-sm-6">
                      <ol class="breadcrumb float-sm-right">
                        <Link to="/admin/properties_list">
                          <button class="pull-right btn btn-success">
                            Property List
                          </button>
                        </Link>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section class="content">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title">Add Properties</h3>
                        </div>
                        <form
                          onSubmit={this.mySubmit}
                          encType="multipart/form-data"
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="form-group col-md-3">
                                <label>City</label>
                                <select
                                  class="form-control"
                                  name="cityId"
                                  onChange={this.changedata}
                                  value={this.state.cityId}
                                  required
                                >
                                  <option value="">Select City</option>
                                  {this.state.cityData &&
                                    this.state.cityData.map((x) => {
                                      return (
                                        <>
                                          <option value={x._id}>
                                            {x.city}
                                          </option>
                                        </>
                                      );
                                    })}
                                </select>
                              </div>

                              <div class="form-group col-md-3">
                                <label>Category</label>
                                <select
                                  class="form-control"
                                  name="categoryId"
                                  onChange={this.changedata}
                                  value={this.state.categoryId}
                                  required
                                >
                                  <option value="">Select Category</option>
                                  {this.state.categoryData &&
                                    this.state.categoryData.map((x) => {
                                      return (
                                        <>
                                          <option value={x._id}>
                                            {x.category}
                                          </option>
                                        </>
                                      );
                                    })}
                                  <option value="both">Both</option>
                                </select>
                                {/* <input type="file" name='image' id='image' defaultValue={this.state.image} class="form-control" accept="image/*" onChange={this.onFileChange} /> */}
                              </div>

                              <div class="form-group col-md-6">
                                <label>Property Name</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="property_name"
                                  required
                                  value={this.state.property_name}
                                  onChange={this.changedata}
                                />
                              </div>

                              <div class="form-group col-md-8">
                                <label>Address</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="address"
                                  value={this.state.address}
                                  onChange={this.changedata}
                                  required
                                />
                              </div>

                              <div class="form-group col-md-4">
                                <label>Property Background Video</label>
                                <input
                                  type="file"
                                  class="form-control"
                                  name="video"
                                  onChange={this.onVideoChange}
                                />
                                {this.state.progress && (
                                  <ProgressBar
                                    now={this.state.progress}
                                    label={`${this.state.progress}%`}
                                  />
                                )}
                              </div>

                              <div class="form-group col-md-3">
                                <label>Manager Contact Number</label>
                                <input
                                  type="text"
                                  class="form-control number"
                                  name="manager_number"
                                  required
                                  value={this.state.manager_number}
                                  onChange={this.changedata}
                                />
                              </div>

                              <div class="form-group col-md-3">
                                <label>Resident Application Link</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="application_link"
                                  value={this.state.application_link}
                                  onChange={this.changedata}
                                />
                              </div>
                            </div>

                            <hr
                              style={{
                                backgroundColor: "rgb(0 123 255 / 42%)",
                                height: 1.5,
                              }}
                            />
                            {this.state.manager_emails.map((element, index) => (
                              <div class="row" key={index}>
                                <div class="form-group col-md-3">
                                  <label>Manager Email</label>
                                  <input
                                    type="email"
                                    class="form-control"
                                    name="manager_email"
                                    value={element.manager_email}
                                    onChange={(e) =>
                                      this.EmailsChange(index, e)
                                    }
                                  />
                                </div>

                                {index === 0 ? (
                                  <div class="form-group col-md-1">
                                    <label>&nbsp;&nbsp;&nbsp;</label>
                                    <button
                                      type="button"
                                      class="btn btn-warning btn-sm "
                                      onClick={() => this.addEmailsFields()}
                                    >
                                      {" "}
                                      <i class="fa fa-plus"></i>{" "}
                                    </button>
                                  </div>
                                ) : (
                                  <div class="form-group col-md-1">
                                    <label>&nbsp;&nbsp;&nbsp;</label>
                                    <button
                                      type="button"
                                      class="btn btn-warning btn-sm "
                                      onClick={() =>
                                        this.removeEmailsFields(index)
                                      }
                                    >
                                      {" "}
                                      <i class="fa fa-minus"></i>{" "}
                                    </button>
                                  </div>
                                )}
                              </div>
                            ))}

                            <hr
                              style={{
                                backgroundColor: "rgb(0 123 255 / 42%)",
                                height: 0.5,
                              }}
                            />

                            {this.state.property_highlights.map(
                              (element, index) => {
                                let imgPath =
                                  config.cdn_backend_URL +
                                  "/" +
                                  element.highlight_icon;

                                let requi1 =
                                  element.highlight_icon === "" ? true : false;
                                return (
                                  <div class="row" key={index}>
                                    <div class="form-group col-md-12">
                                      <b>Year And Amount Details</b>
                                    </div>
                                    <br />

                                    <div class="form-group col-md-3">
                                      <label>Icon</label>
                                      <input
                                        type="file"
                                        class="form-control"
                                        name="file"
                                        data-key={index}
                                        onChange={this.onFileChange1}
                                        required={requi1}
                                      />

                                      {element.highlight_icon !== "" ? (
                                        <img
                                          src={imgPath}
                                          height={80}
                                          width={80}
                                          alt="highlight icon"
                                        />
                                      ) : (
                                        ""
                                      )}
                                    </div>

                                    <div class="form-group col-md-5">
                                      <label>Text</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="highlight_text"
                                        value={element.highlight_text}
                                        onChange={(e) =>
                                          this.HighlightsChange(index, e)
                                        }
                                      />
                                    </div>

                                    <div class="form-group col-md-2">
                                      <label>Value</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="highlight_amount"
                                        value={element.highlight_amount}
                                        onChange={(e) =>
                                          this.HighlightsChange(index, e)
                                        }
                                      />
                                    </div>

                                    {index === 0 ? (
                                      <div class="form-group col-md-1">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <button
                                          type="button"
                                          class="btn btn-warning btn-sm "
                                          onClick={() =>
                                            this.addHighlightsFields()
                                          }
                                        >
                                          {" "}
                                          <i class="fa fa-plus"></i>{" "}
                                        </button>
                                      </div>
                                    ) : (
                                      <div class="form-group col-md-1">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <button
                                          type="button"
                                          class="btn btn-warning btn-sm "
                                          onClick={() =>
                                            this.removeHighlightsFields(index)
                                          }
                                        >
                                          {" "}
                                          <i class="fa fa-minus"></i>{" "}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}

                            <hr
                              style={{
                                backgroundColor: "rgb(0 123 255 / 42%)",
                                height: 1.5,
                              }}
                            />

                            <div class="row">
                              <div class="form-group col-md-6">
                                <label>Property Image</label>
                                <input
                                  type="file"
                                  class="form-control"
                                  name="file"
                                  onChange={this.onFileChange}
                                  required={
                                    "property_image" in this.state
                                      ? false
                                      : true
                                  }
                                />

                                {this.state.property_image !== "" ? (
                                  <img
                                    src={
                                      config.cdn_backend_URL +
                                      "/" +
                                      this.state.property_image
                                    }
                                    height={100}
                                    width={100}
                                    alt="property_image"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              <div class="form-group col-md-6">
                                <label>Property Title</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="property_title"
                                  placeholder="Enter Title"
                                  value={this.state.property_title}
                                  onChange={this.changedata}
                                  required
                                />
                              </div>

                              <div class="form-group col-md-12">
                                <label>Description</label>
                                <textarea
                                  class="form-control"
                                  name="property_desc"
                                  placeholder="Enter Description"
                                  value={this.state.property_desc}
                                  onChange={this.changedata}
                                  required
                                />
                              </div>
                            </div>

                            {this.state.property_features.map(
                              (element, index) => (
                                <div class="row" key={index}>
                                  <div class="form-group col-md-11">
                                    <label>Property Features {index + 1}</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="feature"
                                      value={element.feature}
                                      onChange={(e) =>
                                        this.FeaturesChange(index, e)
                                      }
                                    />
                                  </div>

                                  {index === 0 ? (
                                    <div class="form-group col-md-1">
                                      <label>&nbsp;&nbsp;&nbsp;</label>
                                      <button
                                        type="button"
                                        class="btn btn-warning btn-sm "
                                        onClick={() => this.addFeaturesFields()}
                                      >
                                        {" "}
                                        <i class="fa fa-plus"></i>{" "}
                                      </button>
                                    </div>
                                  ) : (
                                    <div class="form-group col-md-1">
                                      <label>&nbsp;&nbsp;&nbsp;</label>
                                      <button
                                        type="button"
                                        class="btn btn-warning btn-sm "
                                        onClick={() =>
                                          this.removeFeaturesFields(index)
                                        }
                                      >
                                        {" "}
                                        <i class="fa fa-minus"></i>{" "}
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )
                            )}

                            <hr
                              style={{
                                backgroundColor: "rgb(0 123 255 / 42%)",
                                height: 1.5,
                              }}
                            />
                            <div class="form-group col-md-12">
                              <label>Youtube Video Main Heading</label>
                              <input
                                type="text"
                                class="form-control"
                                name="youtube_video_main_heading"
                                value={this.state.youtube_video_main_heading}
                                onChange={this.changedata}
                              />
                            </div>

                            {this.state.property_youtube.map(
                              (element, index) => (
                                <div class="row" key={index}>
                                  <div class="form-group col-md-5">
                                    <label>Youtube Video Heading</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="youtube_video_heading"
                                      value={element.youtube_video_heading}
                                      onChange={(e) =>
                                        this.YoutubeChange(index, e)
                                      }
                                    />
                                  </div>

                                  <div class="form-group col-md-5">
                                    <label>Youtube Video Link</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="youtube_video_link"
                                      value={element.youtube_video_link}
                                      onChange={(e) =>
                                        this.YoutubeChange(index, e)
                                      }
                                    />
                                  </div>

                                  {index === 0 ? (
                                    <div class="form-group col-md-1">
                                      <label>&nbsp;&nbsp;&nbsp;</label>
                                      <button
                                        type="button"
                                        class="btn btn-warning btn-sm "
                                        onClick={() => this.addYoutubeFields()}
                                      >
                                        {" "}
                                        <i class="fa fa-plus"></i>{" "}
                                      </button>
                                    </div>
                                  ) : (
                                    <div class="form-group col-md-1">
                                      <label>&nbsp;&nbsp;&nbsp;</label>
                                      <button
                                        type="button"
                                        class="btn btn-warning btn-sm "
                                        onClick={() =>
                                          this.removeYoutubeFields(index)
                                        }
                                      >
                                        {" "}
                                        <i class="fa fa-minus"></i>{" "}
                                      </button>
                                    </div>
                                  )}
                                </div>
                              )
                            )}

                            <hr
                              style={{
                                backgroundColor: "rgb(0 123 255 / 42%)",
                                height: 1.5,
                              }}
                            />

                            {this.state.property_virtualTours.map(
                              (element, index) => {
                                return (
                                  <div class="row" key={index}>
                                    <div class="form-group col-md-5">
                                      <label>Virtual Tours Title</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="virtual_tours_title"
                                        value={element.virtual_tours_title}
                                        onChange={(e) =>
                                          this.VirtualToursChange(index, e)
                                        }
                                      />
                                    </div>

                                    <div class="form-group col-md-5">
                                      <label>Virtual Tours link</label>
                                      <input
                                        type="text"
                                        class="form-control"
                                        name="virtual_tours_link"
                                        value={element.virtual_tours_link}
                                        onChange={(e) =>
                                          this.VirtualToursChange(index, e)
                                        }
                                      />
                                    </div>

                                    {index === 0 ? (
                                      <div class="form-group col-md-1">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <button
                                          type="button"
                                          class="btn btn-warning btn-sm "
                                          onClick={() =>
                                            this.addVirtualToursFields()
                                          }
                                        >
                                          {" "}
                                          <i class="fa fa-plus"></i>{" "}
                                        </button>
                                      </div>
                                    ) : (
                                      <div class="form-group col-md-1">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <button
                                          type="button"
                                          class="btn btn-warning btn-sm "
                                          onClick={() =>
                                            this.removeVirtualToursFields(index)
                                          }
                                        >
                                          {" "}
                                          <i class="fa fa-minus"></i>{" "}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}

                            <hr
                              style={{
                                backgroundColor: "rgb(0 123 255 / 42%)",
                                height: 1.5,
                              }}
                            />

                            <div class="row">
                              <div class="form-group col-md-7">
                                <label>Content Line</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="review_title"
                                  placeholder="Enter Content Line"
                                  value={this.state.review_title}
                                  onChange={this.changedata}
                                />
                              </div>

                              <div class="form-group col-md-7">
                                <label>Google Page Link</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="google_page_link"
                                  placeholder="Enter Google Page Link"
                                  value={this.state.google_page_link}
                                  onChange={this.changedata}
                                />
                              </div>

                              <div class="form-group col-md-2">
                                <label>Google Rating</label>
                                <input
                                  type="text"
                                  min="0"
                                  max="5"
                                  step="0.1"
                                  class="form-control number"
                                  pattern="^\d+(?:\.\d{1,2})?$"
                                  name="google_rating"
                                  placeholder="eg 5"
                                  value={this.state.google_rating}
                                  onChange={this.changedata}
                                />
                              </div>

                              <div class="form-group col-md-3">
                                <label>Text For Google Rating</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="google_text"
                                  placeholder="eg Based on 10+ reviews"
                                  value={this.state.google_text}
                                  onChange={this.changedata}
                                />
                              </div>

                              <div class="form-group col-md-7">
                                <label>Facebook Page Link</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="facebook_page_link"
                                  placeholder="Enter Facebook Page Link"
                                  value={this.state.facebook_page_link}
                                  onChange={this.changedata}
                                />
                              </div>

                              <div class="form-group col-md-2">
                                <label>Facebook Rating</label>
                                <input
                                  type="text"
                                  min="0"
                                  max="5"
                                  step="0.1"
                                  class="form-control number"
                                  pattern="^\d+(?:\.\d{1,2})?$"
                                  name="facebook_rating"
                                  placeholder="eg 5"
                                  value={this.state.facebook_rating}
                                  onChange={this.changedata}
                                />
                              </div>

                              <div class="form-group col-md-3">
                                <label>Text For Facebook Rating</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="facebook_text"
                                  placeholder="eg Based on 10+ reviews"
                                  value={this.state.facebook_text}
                                  onChange={this.changedata}
                                />
                              </div>
                            </div>

                            {/* Last Section  Start*/}

                            <hr
                              style={{
                                backgroundColor: "rgb(0 123 255 / 42%)",
                                height: 1.5,
                              }}
                            />

                            {this.state.property_blocks.map(
                              (element, index) => {
                                let blkimgPath =
                                  config.cdn_backend_URL +
                                  "/" +
                                  element.Block_image;

                                let requi =
                                  element.Block_image === "" ? true : false;

                                return (
                                  <div class="row" key={index}>
                                    <div class="form-group col-md-3">
                                      <label>Block Image</label>
                                      <input
                                        type="file"
                                        class="form-control"
                                        name="file"
                                        data-key={index}
                                        onChange={this.onFileChange2}
                                        required={requi}
                                        multiple
                                      />

                                      {element.Block_image !== "" &&
                                      typeof element.Block_image !==
                                        typeof "string"
                                        ? element.Block_image.map((image) => {
                                            return (
                                              <img
                                                src={
                                                  config.cdn_backend_URL +
                                                  "/" +
                                                  image
                                                }
                                                height={100}
                                                width={100}
                                                alt="block_image"
                                              />
                                            );
                                          })
                                        : [element.Block_image].map((image) => {
                                            return (
                                              <img
                                                src={
                                                  config.cdn_backend_URL +
                                                  "/" +
                                                  image
                                                }
                                                height={100}
                                                width={100}
                                                alt="block_image"
                                              />
                                            );
                                          })}
                                    </div>

                                    <div class="form-group col-md-2">
                                      <label>Price</label>
                                      <input
                                        type="text"
                                        min="1"
                                        class="form-control"
                                        name="block_price"
                                        value={element.block_price}
                                        onChange={(e) =>
                                          this.BlocksChange(index, e)
                                        }
                                        required
                                      />
                                    </div>

                                    <div class="form-group col-md-2">
                                      <label>Security Deposit</label>
                                      <input
                                        type="text"
                                        min="1"
                                        class="form-control"
                                        name="security_deposit"
                                        value={element.security_deposit}
                                        onChange={(e) =>
                                          this.BlocksChange(index, e)
                                        }
                                        required
                                      />
                                    </div>

                                    <div class="form-group col-md-2">
                                      <label>Total Bedrooms</label>
                                      <select
                                        class="form-control"
                                        name="bedroom"
                                        value={element.bedroom}
                                        onChange={(e) =>
                                          this.BlocksChange(index, e)
                                        }
                                        required
                                      >
                                        <option value="">
                                          Select Bathroom
                                        </option>{" "}
                                        {this.state.bedroomData.map((x) => {
                                          return (
                                            <>
                                              <option value={x._id}>
                                                {x.bedroom}
                                              </option>
                                            </>
                                          );
                                        })}
                                      </select>
                                      {/* <input
                                        type="text"
                                        class="form-control"
                                        name="bedroom"
                                        value={element.bedroom}
                                        onChange={(e) =>
                                          this.BlocksChange(index, e)
                                        }
                                        required
                                      /> */}
                                    </div>

                                    <div class="form-group col-md-2">
                                      <label>Total Bathrooms</label>
                                      <select
                                        class="form-control"
                                        name="bathroom"
                                        value={element.bathroom}
                                        onChange={(e) =>
                                          this.BlocksChange(index, e)
                                        }
                                        required
                                      >
                                        <option value="">
                                          Select Bathroom
                                        </option>{" "}
                                        {this.state.bathroomData.map((x) => {
                                          return (
                                            <>
                                              <option value={x._id}>
                                                {x.bathroom}
                                              </option>
                                            </>
                                          );
                                        })}
                                      </select>
                                      {/* <input
                                        type="text"
                                        class="form-control"
                                        name="bathroom"
                                        value={element.bathroom}
                                        onChange={(e) =>
                                          this.BlocksChange(index, e)
                                        }
                                        required
                                      /> */}
                                    </div>

                                    {index === 0 ? (
                                      <div class="form-group col-md-1">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <button
                                          type="button"
                                          class="btn btn-warning btn-sm "
                                          onClick={() => this.addBlocksFields()}
                                        >
                                          {" "}
                                          <i class="fa fa-plus"></i>{" "}
                                        </button>
                                      </div>
                                    ) : (
                                      <div class="form-group col-md-1">
                                        <label>&nbsp;&nbsp;&nbsp;</label>
                                        <button
                                          type="button"
                                          class="btn btn-warning btn-sm "
                                          onClick={() =>
                                            this.removeBlocksFields(index)
                                          }
                                        >
                                          {" "}
                                          <i class="fa fa-minus"></i>{" "}
                                        </button>
                                      </div>
                                    )}
                                  </div>
                                );
                              }
                            )}

                            {/* Last Section End*/}

                            {/* Vendor Section Start*/}

                            <hr
                              style={{
                                backgroundColor: "rgb(0 123 255 / 42%)",
                                height: 1.5,
                              }}
                            />

                            <div class="form-group">
                              <b>Vendors</b>
                            </div>
                            {this.state.vendors.map((element, index) => {
                              let requi1 = element.logo !== "" ? false : true;
                              return (
                                <div class="row" key={index}>
                                  <div class="form-group col-md-3">
                                    <label>Vendor Logo</label>
                                    <input
                                      type="file"
                                      class="form-control"
                                      name="file"
                                      data-key={index}
                                      onChange={this.onFileChange3}
                                    />
                                    {element.logo !== "" ? (
                                      <img
                                        src={
                                          config.cdn_backend_URL +
                                          "/" +
                                          element.logo
                                        }
                                        height={80}
                                        width={80}
                                        alt="logo"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div class="form-group col-md-4">
                                    <label>Vendor description</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="description"
                                      value={element.description}
                                      onChange={(e) =>
                                        this.BlocksChange1(index, e)
                                      }
                                    />
                                  </div>

                                  <div class="form-group col-md-2">
                                    <label>Button Text</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      value={element.buttonText}
                                      name="buttonText"
                                      onChange={(e) =>
                                        this.BlocksChange1(index, e)
                                      }
                                    />
                                  </div>

                                  <div class="form-group col-md-2">
                                    <label>Button Link</label>
                                    <input
                                      type="text"
                                      class="form-control"
                                      name="buttonLink"
                                      value={element.buttonLink}
                                      onChange={(e) =>
                                        this.BlocksChange1(index, e)
                                      }
                                    />
                                  </div>
                                  {index === 0 ? (
                                    <div class="form-group col-md-1">
                                      <label>&nbsp;&nbsp;&nbsp;</label>
                                      <button
                                        type="button"
                                        class="btn btn-warning btn-sm "
                                        onClick={() => this.addVendorFields()}
                                      >
                                        <i class="fa fa-plus"></i>
                                      </button>
                                    </div>
                                  ) : (
                                    <div class="form-group col-md-1">
                                      <label>&nbsp;&nbsp;&nbsp;</label>
                                      <button
                                        type="button"
                                        class="btn btn-warning btn-sm "
                                        onClick={() =>
                                          this.removeVendorFields(index)
                                        }
                                      >
                                        <i class="fa fa-minus"></i>
                                      </button>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                            {/* Vendor Section End*/}

                            <hr
                              style={{
                                backgroundColor: "rgb(0 123 255 / 42%)",
                                height: 1.5,
                              }}
                            />
                            <div class="row">
                              <div class="form-group col-md-6">
                                <label>Meta Title </label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="meta_title"
                                  placeholder=""
                                  value={this.state.meta_title}
                                  onChange={this.changedata}
                                />
                              </div>

                              <div class="form-group col-md-6">
                                <label>Meta Keyword</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="meta_keyword"
                                  placeholder=""
                                  value={this.state.meta_keyword}
                                  onChange={this.changedata}
                                />
                              </div>

                              <div class="form-group col-md-12">
                                <label>Meta Description</label>
                                <input
                                  type="text"
                                  class="form-control"
                                  name="meta_desc"
                                  placeholder=""
                                  value={this.state.meta_desc}
                                  onChange={this.changedata}
                                />
                              </div>
                            </div>

                            <div class="form-group col-md-2">
                              <label>&nbsp;&nbsp;&nbsp;</label>
                              <button
                                type="submit"
                                class="btn btn-primary"
                                disabled={!this.state.submitBtn}
                              >
                                {" "}
                                {this.state._id ? "Update" : "Submit"}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </>
    );
  }
}
export default withRouter(Properties);
