import React from "react";
import { withRouter } from "react-router-dom";
import config from "../config.js";
import _ from "lodash";

const axios = require("axios");
class Search_Fields extends React.Component {
  state = {
    cities: [],
    categories: [],
    cityId: "",
    categoryId: "",
    bedroom: "",
    bathroom: "",
    price: "",
    price_min: "",
    price_max: "",
    viewAll: false,
    timestamp: -1,
    bathroomOptn: [],
    bedroomOptn: [],
    priceOptn: [],
    bathroomData: [],
    bedroomData: [],
    viewAll: false,
    categoryOptn: [],
    headerHide: false,
  };

  componentDidMount = async () => {
    await this.getCities();
    await this.getCategory();
    //await this.searchOptions();
    await this.getBedroom();
    await this.getBathroom();
    if (window.location.pathname === "/SearchResult") {
      await this.searchOptions(
        this.props.data.categoryId,
        this.props.data.cityId
      );
      console.log(this.props.data);
      this.setState({
        cityId: this.props.data.cityId,
        categoryId: this.props.data.categoryId,
        bedroom: this.props.data.bedroom,
        bathroom: this.props.data.bathroom,
        price: this.props.data.price,
        viewAll: true,
        price_min: this.props.data.price_min,
        price_max: this.props.data.price_max,
        categoryOptn: this.props.data.categoryOptn,
        priceOptn: this.props.data.priceOptn,
        bathroomOptn: this.props.data.bathroomOptn,
      });
    }
    //alert(this.state.bedroom);
    console.log("state-->", this.state);
  };
  getBedroom = () => {
    axios
      .get(`${config.backend_URL}/admin/getBedroom`)
      .then((responseJson) => {
        console.log(responseJson.data.data);
        // return
        this.setState({ bedroomData: responseJson.data.data });

        console.log(this.state.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getBathroom = () => {
    axios
      .get(`${config.backend_URL}/admin/getBathroom`)
      .then((responseJson) => {
        console.log(responseJson.data.data);
        // return
        this.setState({ bathroomData: responseJson.data.data });
        console.log(this.state.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  checkCategory = (e) => {
    //alert(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
    this.searchOptions(e.target.value, this.state.cityId);
  };
  checkCity = (e) => {
    //alert(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
    this.searchOptions1(e.target.value);
  };
  checkBedroom = (e) => {
    //alert(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
    this.searchOptions2(
      e.target.value,
      this.state.categoryId,
      this.state.cityId
    );
  };
  checkBathroom = (e) => {
    //alert(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
    this.searchOptions3(
      this.state.bedroom,
      e.target.value,
      this.state.categoryId,
      this.state.cityId
    );
  };
  searchOptions = (id, cid) => {
    // alert(id);
    this.setState({
      bedroomOptn: [],
      bathroomOptn: [],
      priceOptn: [],
      bedroom: "",
      bathroom: "",

      price_min: "",
      price_max: "",
    });
    if (id != "" && cid != "") {
      let newObj = {
        categoryId: id,
        cityId: cid,
      };
      axios
        .post(`${config.backend_URL}/admin/searchOptions`, newObj)
        .then((responseJson) => {
          // console.log("manish", responseJson.data);
          this.setState({
            bedroomOptn: responseJson.data.data[0].bedroom.sort(),
            /*   bathroomOptn: responseJson.data.data[0].bathroom.sort(),
            priceOptn: responseJson.data.data[0].price.sort(), */
          });

          console.log("aaaaaa", this.state);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  searchOptions1 = (cid) => {
    //alert(cid);
    this.setState({
      bedroomOptn: [],
      bathroomOptn: [],
      categoryOptn: [],
      priceOptn: [],

      categoryId: "",
      bedroom: "",
      bathroom: "",

      price_min: "",
      price_max: "",
    });

    let newObj = {
      cityId: cid,
    };
    
    axios
      .post(`${config.backend_URL}/admin/searchcityfromcategory`, newObj)
      .then((responseJson) => {
        console.log("manish", responseJson.data);
        this.setState({
          categoryOptn: responseJson.data.data[0].categoryId.sort(),
        });
        //console.log("aaaaaa", this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  searchOptions2 = (bedid, id, cid) => {
    //alert(bedid);
    this.setState({
      bathroomOptn: [],
      priceOptn: [],

      bathroom: "",

      price_min: "",
      price_max: "",
    });

    let newObj = {
      bedroomId: bedid,
      categoryId: id,
      cityId: cid,
    };
    axios
      .post(`${config.backend_URL}/admin/searchcityfrombedroom`, newObj)
      .then((responseJson) => {
        console.log("manish bathroom", responseJson.data);
        this.setState({
          bathroomOptn: responseJson.data.data[0].bathroom.sort(),
        });

        //console.log("aaaaaa", this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  searchOptions3 = (bedid, bathid, id, cid) => {
    //alert(bedid);
    this.setState({
      priceOptn: [],
      price: "",
      price_max: "",
    });

    let newObj = {
      bedroomId: bedid,
      bathroomId: bathid,
      categoryId: id,
      cityId: cid,
    };
    axios
      .post(`${config.backend_URL}/admin/searchpricefrombathroom`, newObj)
      .then((responseJson) => {
        console.log("price", responseJson.data);
        this.setState({
          priceOptn: responseJson.data.data[0].price.sort(),
        });

        //console.log("aaaaaa", this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getCities = () => {
    axios
      .get(`${config.backend_URL}/admin/getCities`)
      .then((responseJson) => {
        this.setState({ cities: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCategory = () => {
    axios
      .get(`${config.backend_URL}/admin/getCategories`)
      .then((responseJson) => {
        this.setState({ categories: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  mySubmit = (e) => {
    e.preventDefault();
    this.setState({ viewAll: false });
    let { categories, cities, ...newObj } = this.state;
    if (window.location.pathname !== "/SearchResult") {
      this.props.history.push("/SearchResult", newObj);
    } else {
      //alert("gh");
      this.fetchproperties(newObj);
    }
  };

  fetchproperties = (newObj) => {
    axios
      .post(`${config.backend_URL}/admin/filterProperty`, newObj)
      .then(async (responseJson) => {
        this.props.onFormSubmit(responseJson.data.data);

        this.props.viewallsubmit(this.state.viewAll);

        // console.log('result', responseJson.data.data);
        let ltlngObj = [];
        responseJson.data.data.map((x, key) => {
          ltlngObj.push(
            _.pick(x, "_id", "lat", "lng", "property_name", "slug")
          );
        });
        //this.setState({ prtySmlObj: ltlngObj });

        this.props.onsetLtLngProps(ltlngObj);
        // console.log("mpa",this.state)
      })
      .catch((error) => {
        console.error(error);
      });
  };
  changedata = (e) => {
    if (e.target.name === "price") {
      var min = e.target[e.target.selectedIndex].getAttribute("data-min");

      this.setState({ price_min: Number(min), price: e.target.value });
    } else if (e.target.name === "pricemax") {
      var max = e.target[e.target.selectedIndex].getAttribute("data-max");
      this.setState({
        price_max: Number(max),
        pricemax: e.target.value,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  showAllProperties = (e) => {
    e.preventDefault();
    //alert(";fhfh");
    this.setState({ viewAll: true });
    if (window.location.pathname !== "/SearchResult") {
      this.props.history.push("/SearchResult", {});
    } else {
      this.fetchproperties({ timestamp: -1 });
    }
  };
  toggle() {
    this.setState({ addClass: !this.state.addClass });
    // this.setState({ headerHide: !this.state.headerHide });
    // this.state.headerHide ? document.body.classList.add('headerHide') : document.body.classList.remove('headerHide')
    
  }
  headerhidefun(){
    document.body.classList.add('headerHide')
  }
  headershowfun(){
    document.body.classList.remove('headerHide')
  }

  render() {
    let boxClass = ["searchMobileFilter"];
    if (this.state.addClass) {
      boxClass.push("active");
    }
    return (
      <>
        <div className="srchPrprtyBtnBlock homeCallActBlock wrapper py-40 d-md-none">
          <div className="container">
            <a
              href="javascript:void(0);"
              className="btnCommon srchPrptyBtn"
              onClick={() => {
                this.toggle();
                this.headerhidefun();
              }}
            >
              Search Property
            </a>
          </div> 
        </div>
        <article
          className={`${boxClass.join(
            " "
          )} homeCallActBlock searchAdvanceBlk wrapper py-40`}

          style={{backgroundImage: `url(${config.cdn_URL + "/images/propertiesBg.png"})`}}
        >
          <div className="container">
            <form className="advncSearchForm" onSubmit={this.mySubmit}>
              <div className="fltrHdr d-md-none mb-3">
                <h4 class="d-flex align-items-center justify-content-between m-0">
                  Filters
                  {this.state.viewAll ? (
                    <small>
                      <a
                        href="javascript:void(0);"
                        class="clear-all-filter"
                        onClick={this.showAllProperties}
                      >
                        View All
                      </a>
                    </small>
                  ) : (
                    ""
                  )}
                </h4>
                <div class="fltCnclBtn">
                  <a
                    href="javascript:void(0);"
                    onClick={() => {
                      this.toggle();
                      this.headershowfun();
                    }}
                  >
                    x
                  </a>
                </div>
              </div>
              <div className="row d-flex align-items-end">
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>
                      City <span className="astrict">*</span>
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="cityId"
                      onChange={this.checkCity}
                      value={this.state.cityId}
                      required
                    >
                      <option value="" selected="">
                        Select City
                      </option>
                      {this.state.cities.length > 0
                        ? this.state.cities.map((x, key) => {
                            return (
                              <option value={`${x._id}`}> {x.city} </option>
                            );
                          })
                        : ""}
                    </select>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>
                      Category <span className="astrict">*</span>
                    </label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="categoryId"
                      onChange={this.checkCategory}
                      value={this.state.categoryId}
                      required
                    >
                      <option value="" selected="">
                        Select Category
                      </option>
                      {this.state.categoryOptn.length > 0
                        ? this.state.categories
                            .sort((a, b) => a - b)
                            .map((x, key) => {
                              var tm = this.state.categoryOptn.includes(x._id)
                                ? x.category
                                : "hidden";
                              var mt =
                                tm != "hidden" ? (
                                  <option value={x._id}> {tm} </option>
                                ) : (
                                  ""
                                );
                              return mt;
                            })
                        : ""}

                      {/* {this.state.categories.length > 0
                        ? this.state.categories.map((x, key) => {
                            return (
                              <option value={`${x._id}`}> {x.category} </option>
                            );
                          })
                        : ""} */}
                    </select>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>Bedroom</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="bedroom"
                      onChange={this.checkBedroom}
                      value={this.state.bedroom}
                    >
                      <option value="" selected="">
                        Select
                      </option>

                      {this.state.bedroomOptn.length > 0
                        ? this.state.bedroomData
                            .sort((a, b) => a - b)
                            .map((x, key) => {
                              var tm = this.state.bedroomOptn.includes(x._id)
                                ? x.bedroom
                                : "hidden";
                              var mt =
                                tm != "hidden" ? (
                                  <option value={x._id}> {tm} </option>
                                ) : (
                                  ""
                                );
                              return mt;
                            })
                        : ""}
                    </select>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>Bathroom</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="bathroom"
                      onChange={this.checkBathroom}
                      value={this.state.bathroom}
                    >
                      <option value="" selected="">
                        Select
                      </option>
                      {this.state.bathroomOptn.length > 0
                        ? this.state.bathroomData
                            .sort((a, b) => a - b)
                            .map((x, key) => {
                              var tm = this.state.bathroomOptn.includes(x._id)
                                ? x.bathroom
                                : "hidden";
                              var mt =
                                tm != "hidden" ? (
                                  <option value={x._id}> {tm} </option>
                                ) : (
                                  ""
                                );
                              return mt;
                            })
                        : ""}
                    </select>
                  </div>
                </div>

                {/*  <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>Min Price Range</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="price"
                      onChange={this.changedata}
                      value={this.state.price}
                    >
                      <option value="" selected="">
                        Select
                      </option>
                      <option value={0} data-min={0}>
                        From $0{" "}
                      </option>
                      {this.state.priceOptn.length > 0
                        ? this.state.priceOptn
                            .sort((a, b) => a - b)
                            .map((x, key) => {
                              return (
                                <option value={x} data-min={x}>
                                  From ${x}{" "}
                                </option>
                              );
                            })
                        : ""}
                    </select>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>Max Price Range</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="price"
                      onChange={this.changedata}
                      value={this.state.price_max}
                    >
                      <option value="" selected="">
                        Select
                      </option>

                      {this.state.priceOptn.length > 0
                        ? this.state.priceOptn
                            .sort((a, b) => a - b)
                            .map((x, key) => {
                              return (
                                <option value={x} data-max={x}>
                                  Upto ${x}{" "}
                                </option>
                              );
                            })
                        : ""}
                    </select>
                  </div>
                </div>
              </div> */}
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>Min Price Range</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="price"
                      onChange={this.changedata}
                      value={this.state.price}
                    >
                      <option value="" selected="">
                        Select
                      </option>
                      {/*    <option value={0} data-min={0}>
                        From $0{" "}
                      </option> */}
                      {this.state.priceOptn.length > 0
                        ? this.state.priceOptn
                            .sort((a, b) => a - b)
                            .map((x, key) => {
                              return (
                                <>
                                  {key == 0 ? (
                                    <>
                                      <option value={x} data-min={x}>
                                        From ${x}{" "}
                                      </option>
                                    </>
                                  ) : (
                                    <> </>
                                  )}
                                </>
                              );
                            })
                        : ""}
                    </select>
                  </div>
                </div>

                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6">
                  <div className="form-group">
                    <label>Max Price Range</label>
                    <select
                      className="form-select"
                      aria-label="Default select example"
                      name="price_max"
                      onChange={this.changedata}
                      value={this.state.price_max}
                    >
                      <option value="" selected="">
                        Select
                      </option>

                      {this.state.priceOptn.length > 0
                        ? this.state.priceOptn
                            .sort((a, b) => a - b)
                            .map((x, key) => {
                              return (
                                <>
                                  {key == this.state.priceOptn.length - 1 ? (
                                    <>
                                      <option value={x} data-min={x}>
                                        From ${x}{" "}
                                      </option>
                                    </>
                                  ) : (
                                    <> </>
                                  )}
                                </>
                              );
                            })
                        : ""}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mt-xl-4">
                <div className="col-md-auto">
                  <div className="srchBtn">
                    <button
                      className="btnCommon srchMblBtn"
                      onClick={this.toggle.bind(this)}
                    >
                      Search
                    </button>
                    {this.state.viewAll ? (
                      <button
                        className="btnCommon btnDark ms-3 d-none d-md-inline-block"
                        onClick={this.showAllProperties}
                      >
                        View All
                      </button>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </article>
      </>
    );
  }
}

export default withRouter(Search_Fields);
