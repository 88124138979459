import React from "react";
import { Link } from "react-router-dom";
import { HeaderFront, FooterFront } from "../components";
import config from "../config.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import Search_Fields from "./Search_Fields.js";
import Filter_Property from "./Filter_Property.js";
import Map from "./Map.js";
import helper from "../helper.js";
import Loader from "../loader.js";
const axios = require("axios");

class SearchResult extends React.Component {
  state = {
    prtySmlObj: [],
    properties: [],
    selectedPropertyId: "",
    status: false,
    viewAll: false,
    loader_flag: false,
  };

  submitForm = (values) => {
    console.log("properties", values);
    this.setState({ properties: values });
  };
  viewallsubmit = (values) => {
    console.log("viewAll", values);
    //alert(values);
    this.setState({ viewAll: values });
  };

  setLtLngProps = (values) => {
    this.setState({ status: false });
    console.log("prtySmlObj", values);
    this.setState({ prtySmlObj: values, status: true });
  };

  componentDidMount() {
    this.setState({ status: false });
    this.filterProperty();
    this.getMetaTags("search");
    this.setState({ status: true });
  }

  // Meta Tags Set Sart
  getMetaTags = (page_name) => {
    axios
      .post(`${config.backend_URL}/admin/getMetaTags`, { page_name: page_name })
      .then((responseJson) => {
        //set Meta Tags
        helper.setMetaTitle(responseJson.data.data.meta_title);
        helper.setMetaDesc(responseJson.data.data.meta_desc);
        helper.setMetaKeyword(responseJson.data.data.meta_keyword);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  filterProperty = (timestampObj = { timestamp: -1 }) => {
    this.setState({ loader_flag: true });
    let filterObj = { ...this.props.location.state, ...timestampObj };
    console.log("filterObj", filterObj);
    axios
      .post(`${config.backend_URL}/admin/filterProperty`, filterObj)
      .then(async (responseJson) => {
        this.setState({ properties: responseJson.data.data });
        // console.log('result', responseJson.data.data);
        let newObj = [];
        const resNew = responseJson.data.data.map((x, key) => {
          newObj.push(_.pick(x, "_id", "lat", "lng", "property_name", "slug"));
        });
        this.setState({ prtySmlObj: newObj });
        this.setState({ loader_flag: false });

        // console.log("mpa",this.state)
      })
      .catch((error) => {
        console.error(error);
      });
  };

  setPropertyId = (id) => {
    // this.setState({selectedPropertyId:id});
    console.log("id", id);
    let newData = this.state.properties;
    let mapNewData = this.state.prtySmlObj;
    const resNew = newData.map((x) => {
      if (x._id === id) {
        x["border"] = true;
      } else {
        x["border"] = false;
      }
    });

    const resNew1 = mapNewData.map((x) => {
      if (x._id === id) {
        x["border"] = true;
      } else {
        x["border"] = false;
      }
    });
    this.setState({ properties: newData, prtySmlObj: mapNewData });
    // console.log("properties",this.state)
  };

  render() {
    return (
      <>
        <HeaderFront/>
        <div className="exceptAppWrapper">
          <ToastContainer />
          {this.state.loader_flag ? (
            <><Loader /></>
          ) : (
            <>
              <section>
                <article
                  className="pageHdrBlock wrapper py-80"
                  style={{ backgroundImage: `url(${config.cdn_URL + "/images/subpageBg.jpg"})` }}
                >
                  <div className="pageHdrOverlay"></div>
                  <div className="container">
                    <div className="row align-items-center justify-space-beetween">
                      <div className="col-sm">
                        <h3>Search Properties</h3>
                      </div>
                      <div className="col-sm-auto">
                        <div className="pagebrdcrmbs">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                              >
                                Search Properties
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>

                <Search_Fields
                  data={this.props.location.state}
                  onFormSubmit={this.submitForm}
                  onsetLtLngProps={this.setLtLngProps}
                  viewallsubmit={this.viewallsubmit}
                />

                <article className="propertyListingBlock wrapper">
                  <div className="container-fluid p-0">
                    <div className="row g-0">
                      {this.state.prtySmlObj.length > 0 ? (
                        <>
                          <div className="col-sm-12 col-lg-7">
                            {this.state.status ? (
                              <>
                                <Map
                                  data={this.state.prtySmlObj}
                                  selectedPropertyId={
                                    this.state.selectedPropertyId
                                  }
                                  setPropertyId={this.setPropertyId}
                                  viewAllstatus={this.state.viewAll}
                                />
                              </>
                            ) : (
                              <><Loader /></>
                            )}
                          </div>
                          <div className="col-sm-12 col-lg-5">
                            <Filter_Property
                              data={this.state.properties}
                              selectedPropertyId={this.state.selectedPropertyId}
                              setPropertyId={this.setPropertyId}
                              filterProperty={this.filterProperty}
                            />
                          </div>
                        </>
                      ) : (
                        <div class="mainHeading text-center mt-5 pt-3 mb-0">
                          <h2>Property Not Found</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </article>
              </section>
            </>
          )}
        </div>
        <FooterFront/>
      </>
    );
  }
}

export default SearchResult;
