import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import axios from "axios";
import React from "react";
import { Link, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.js";
import Loader from "../../loader.js";

class AddBlogCategory extends React.Component {
  state = {
    loader_flag: false,
    blog_cat_name: "",
    blog_cat_description: "",
    blog_cat_image: undefined,
    blog_cat_status: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    meta_image: undefined,
    category_id: "",
  };

  initialState = {
    loader_flag: false,
    blog_cat_name: "",
    blog_cat_description: "",
    blog_cat_image: undefined,
    blog_cat_status: "",
    meta_title: "",
    meta_description: "",
    meta_keyword: "",
    meta_image: undefined,
  };

  componentDidMount = async () => {
    console.log("this.state: ", this.props.location?.state?.id);
    if (this.props.location.state.id) {
      let category_id = this.props.location.state.id;
      this.setState({ category_id: category_id });
      this.getcategory(category_id);
    }
  };

  getcategory = async (id) => {
    axios
      .get(`${config.backend_URL}/admin/blog/fetchCategory/${id}`)
      .then(async (data) => {
        console.log(data);
        if (data.status === 200) {
          this.setState({
            blog_cat_name: data.data.result.name,
            blog_cat_description: data.data.result.description,
            blog_cat_image: data.data.result.image,
            blog_cat_status: data.data.result.status,
            meta_title: data.data.result.meta_content.title,
            meta_description: data.data.result.meta_content.description,
            meta_keyword: data.data.result.meta_content.keyword,
            meta_image: data.data.result.meta_content.image,
          });
        } else {
          toast("Something wrong!");
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  addCategory = (e) => {
    e.preventDefault();
    let uploadObj = {
      name: this.state.blog_cat_name,
      description: this.state.blog_cat_description,
      image: this.state.blog_cat_image,
      status: this.state.blog_cat_status,
      meta_content: {
        title: this.state.meta_title,
        keyword: this.state.meta_keyword,
        description: this.state.meta_description,
        image: this.state.meta_image,
      },
    };
    if(uploadObj.name == ''){
      return toast("Please enter Blog Category Name");
    }else if(uploadObj.description == ''){
      return toast("Please enter Blog Category Description");
    }else if(!uploadObj.image){
      return toast("Please add Blog Category Image");
    }else if(uploadObj.meta_content.title == ''){
     return toast("Please enter Meta Title");
    }else if(uploadObj.meta_content.description == ''){
      return  toast("Please enter Meta Description");
    }else if(uploadObj.meta_content.keyword == ''){
      return  toast("Please enter Meta Keyword");
    }else if(!uploadObj.meta_content.image){
      return  toast("Please add Meta Image");
    }
    axios
      .post(`${config.backend_URL}/admin/blog/addCategory`, uploadObj)
      .then(async (data) => {
        console.log(data);
        if (data.status === 200) {
          toast(data.data.msg);
          this.setState(this.initialState);
          this.props.history.push("/admin/blogCategoryList");
        } else {
          toast("Something wrong!");
        }
      })
      .catch((err) => {
        console.log("error", err);
        
          toast("Something wrong! Please try again.");
        
        this.setState({ loader_flag: false });
      });
  };

  uploadImage = (e) => {
    e.preventDefault();
    const { name, files } = e.target;
    // let file = e.target?.files[0]
    console.log("file: ", files, "/n", name);
    let form = new FormData();
    form.append("file", files[0]);
    axios
      .post(`${config.backend_URL}/admin/upload_single_image`, form)
      .then(async (data) => {
        console.log(data);
        if (data.status === 200) {
          this.setState({ [name]: data.data.data });
        } else {
          toast("Something wrong!");
        }
        // this.setState({ loader_flag: false });
      })
      .catch((err) => {
        console.log("error", err);
        toast("Something wrong! Please try again.");
      });
  };

  updateCategory = (e) => {
    e.preventDefault();
    let uploadObj = {
      name: this.state.blog_cat_name,
      description: this.state.blog_cat_description,
      image: this.state.blog_cat_image,
      status: this.state.blog_cat_status,
      meta_content: {
        title: this.state.meta_title,
        keyword: this.state.meta_keyword,
        description: this.state.meta_description,
        image: this.state.meta_image,
      },
    };
    if(uploadObj.name == ''){
      return toast("Please enter Blog Category Name");
    }else if(uploadObj.description == ''){
      return toast("Please enter Blog Category Description");
    }else if(!uploadObj.image){
      return toast("Please add Blog Category Image");
    }else if(uploadObj.meta_content.title == ''){
     return toast("Please enter Meta Title");
    }else if(uploadObj.meta_content.description == ''){
      return  toast("Please enter Meta Description");
    }else if(uploadObj.meta_content.keyword == ''){
      return  toast("Please enter Meta Keyword");
    }else if(!uploadObj.meta_content.image){
      return  toast("Please add Meta Image");
    }
    axios
      .post(
        `${config.backend_URL}/admin/blog/updateCategory/${this.state.category_id}`,
        uploadObj
      )
      .then(async (data) => {
        console.log(data);
        if (data.status === 200) {
          toast(data.data.msg);
          this.setState(this.initialState);
          this.props.history.push("/admin/blogCategoryList");

        } else {
          toast("Something wrong!");
        }
      })
      .catch((err) => {
        console.log("error", err);
        toast("Something wrong! Please try again.");
        this.setState({ loader_flag: false });
      });
  };

  render() {
    const customStyles = {
      content: {
        width: "30%",
        top: "50%",
        left: "55%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    return (
      <>
        <ToastContainer />
        {this.state.loader_flag ? (
          <><Loader /></>
        ) : (
          <>
            <div class="content-wrapper">
              <section class="content-header">
                <div class="container-fluid">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <h1>Blog / Add Blog Category</h1>
                    </div>
                    <div class="col-sm-6">
                      <ol class="breadcrumb float-sm-right">
                        <Link
                          to="/admin/blogCategoryList"
                          class="pull-right btn btn-success"
                        >
                          Blog Category List
                        </Link>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section class="content">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title">Add Blog Category</h3>
                        </div>
                        <div class="card-body">
                          <form
                            onSubmit={
                              this.state.category_id
                                ? this.updateCategory
                                : this.addCategory
                            }
                          >
                            <div class="form-group row">
                              <div className="col-md-3 text-end"></div>
                              <div className="col-md">
                                <b>Add Blog Category</b>
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">
                                  Blog Category Name
                                </label>
                              </div>
                              <div className="col-md">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={this.state.blog_cat_name}
                                  onChange={(e) => {
                                    this.setState({
                                      blog_cat_name: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>

                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">
                                  Blog Category Description
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="ckEditor">
                                  <CKEditor
                                    editor={ClassicEditor}
                                    config={{
                                      link: {
                                        decorators: {
                                          addTargetToExternalLinks: {
                                            mode: "automatic",
                                            callback: (url) =>
                                              /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/.test(
                                                url
                                              ),
                                            attributes: {
                                              target: "_blank",
                                              rel: "noopener noreferrer",
                                            },
                                          },
                                        },
                                      },
                                    }}
                                    data={this.state.blog_cat_description}
                                    onChange={(event, editor) => {
                                      const data = editor.getData();
                                      this.setState({
                                        blog_cat_description: data,
                                      });
                                    }}
                                    name="blog_cat_description"
                                    onFocus={(event, editor) => {}}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">
                                  Blog Category Image
                                </label>
                              </div>
                              <div className="col-md">
                                <div className="d-flex align-items-start align-items-sm-center gap-4">
                                  <img
                                    src={
                                      this.state.blog_cat_image
                                        ? `${config.backend_URL}/${this.state.blog_cat_image}`
                                        : "../images/noImage.jpg"
                                    }
                                    alt="blog-category-image"
                                    class="d-block rounded"
                                    height="100"
                                    width="100"
                                  />
                                  <input
                                    type="file"
                                    class="form-control"
                                    name="blog_cat_image"
                                    data-key="0"
                                    onChange={this.uploadImage}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Meta Title</label>
                              </div>
                              <div className="col-md">
                                <input
                                  type="text"
                                  class="form-control"
                                  value={this.state.meta_title}
                                  onChange={(e) => {
                                    this.setState({
                                      meta_title: e.target.value,
                                    });
                                  }}
                                />
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Meta Description</label>
                              </div>
                              <div className="col-md">
                                <textarea
                                  class="form-control"
                                  name=""
                                  placeholder=""
                                  value={this.state.meta_description}
                                  onChange={(e) => {
                                    this.setState({
                                      meta_description: e.target.value,
                                    });
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Meta Keywords</label>
                              </div>
                              <div className="col-md">
                                <textarea
                                  class="form-control"
                                  name=""
                                  placeholder=""
                                  value={this.state.meta_keyword}
                                  onChange={(e) => {
                                    this.setState({
                                      meta_keyword: e.target.value,
                                    });
                                  }}
                                ></textarea>
                              </div>
                            </div>
                            <div className="form-group row align-items-center mb-4">
                              <div className="col-md-3 text-end">
                                <label className="m-0">Meta Image</label>
                              </div>
                              <div className="col-md">
                                <div className="d-flex align-items-start align-items-sm-center gap-4">
                                  <img
                                    src={
                                      this.state.meta_image
                                        ? `${config.backend_URL}/${this.state.meta_image}`
                                        : "../images/noImage.jpg"
                                    }
                                    alt="blog-category-image"
                                    class="d-block rounded"
                                    height="100"
                                    width="100"
                                  />
                                  <input
                                    type="file"
                                    class="form-control"
                                    name="meta_image"
                                    data-key="0"
                                    onChange={this.uploadImage}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="form-group row align-items-center">
                              <div className="col-md-3 text-end"></div>
                              <div className="col-md">
                                <button type="submit" class="btn btn-primary">
                                  Submit
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </>
        )}
      </>
    );
  }
}
export default withRouter(AddBlogCategory);
