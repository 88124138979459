import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import Loader from '../../loader';
import config from '../../config';
import axios from 'axios';
import Modal from "react-modal";


function RegionalOffers() {


    const [id, setId] = useState('');
    const [region, setRegion] = useState('');
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [loader_flag, setLoderFlag] = useState(false);
    const [regions, setRegions] = useState([]);

    const openModal = (id = "", region = "") => {
        setId(id);
        setRegion(region)
        setModalIsOpen(true)
    };

    const closeModal = async (e) => {
        setModalIsOpen(false)
    };

    const mySubmit = (e) => {
        e.preventDefault();

        if (region === "") {
            toast("region can not be empty");
        }

        let newObj = {
            region: region,
        };
        if (id === "") {
            //Insert Data
            setLoderFlag(true)
            axios
                .post(`${config.backend_URL}/admin/addRegion`, newObj)
                .then(async (data) => {
                    console.log(data);
                    if (data.data.status === true) {
                        toast(data.data.message);
                        setId('');
                        setRegion('')
                        setModalIsOpen(false)
                        getRegion();
                        // document.getElementById("testi_form").reset();
                    } else {
                        toast("Something wrong!");
                    }
                    setLoderFlag(false)
                })
                .catch((err) => {
                    console.log("error", err);
                    setLoderFlag(false)
                });
        } else {
            newObj["id"] = id;
            //Update Data
            setLoderFlag(true)
            axios
                .post(`${config.backend_URL}/admin/updateRegion`, newObj)
                .then(async (data) => {
                    if (data.data.status === true) {
                        toast(data.data.message);
                        setId('');
                        setRegion('')
                        setModalIsOpen(false)
                        getRegion();
                    } else {
                        toast("Something wrong!");
                    }
                    setLoderFlag(false)
                })
                .catch((err) => {
                    console.log("error", err);
                    setLoderFlag(false)
                });
        }
    };

    const getRegion = () => {
        axios
            .get(`${config.backend_URL}/admin/getRegion`)
            .then((responseJson) => {
                console.log(responseJson.data.data);
                // return
                setRegions(responseJson.data.data)
            })
            .catch((error) => {
                console.error(error);
            });
    };

    const delRegion = (id) => {
        if (window.confirm("Are you sure")) {
            let options = {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ id: id }),
            };
            setLoderFlag(true)
            fetch(`${config.backend_URL}/admin/delRegion`, options)
                .then((res) => {
                    return res.json();
                })
                .then((data) => {
                    if (data.status === true) {
                       getRegion()
                        toast(data.message);
                    } else {
                        toast("Something wrong!");
                    }
                    setLoderFlag(false)
                })
                .catch((err) => {
                    console.log("error", err);
                    setLoderFlag(false)
                });
        }
    };

    const customStyles = {
        content: {
            width: "30%",
            top: "50%",
            left: "55%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
        },
    };

    useEffect(() => {
        getRegion()
    },[])



    return (
        <>
            <ToastContainer />
            {
                loader_flag ? (
                    <Loader />
                ) : (
                    <div class="content-wrapper">
                        <section class="content">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card card-primary">
                                        <div class="card-header">
                                            <h3 class="card-title">Regions</h3>
                                            <button
                                                onClick={() => {
                                                    openModal("", "");
                                                }}
                                                class="pull-right btn btn-success"
                                            >
                                                Add Region
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body">
                                <table
                                    id="exampl e1"
                                    class="table table-bordered table-striped"
                                >
                                    <thead>
                                        <tr>
                                            <th width="15%">No</th>
                                            <th>Name</th>
                                            <th width="20%">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            regions.map((reg, i) => (
                                                <tr key={i}>
                                                    <td>{++i }</td>
                                                    <td>{ reg.region }</td>
                                                    <td>
                                                        <Link to={`/admin/regional-offer?region=${reg._id}`}>
                                                            <a
                                                                href="#"
                                                                class="btn btn-warning btn-sm"
                                                            >
                                                                <i class="fa fa-plus"></i>
                                                            </a>
                                                        </Link>
                                                        {" "}
                                                        &nbsp;&nbsp;
                                                        <a
                                                            href="#"
                                                            onClick={() => {
                                                                openModal(reg._id, reg.region);
                                                            }}
                                                            class="btn btn-success btn-sm"
                                                        >
                                                            <i class="fa fa-edit"></i>
                                                        </a>{" "}
                                                        &nbsp;&nbsp;
                                                        <a
                                                            class="btn btn-danger btn-sm"
                                                            href="#"
                                                            onClick={() => {
                                                                delRegion(reg._id);
                                                            }}
                                                        >
                                                            <i class="fas fa-trash"></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </section>
                        <Modal
                            isOpen={modalIsOpen}
                            // onAfterOpen={afterOpenModal}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Add Region  "
                            ariaHideApp={false}
                        >
                            {/* modal */}
                            <div class="modal-header">
                                <h2>Region</h2>
                                <button
                                    type="button"
                                    class="btn-close"
                                    onClick={closeModal}
                                >
                                    x
                                </button>
                            </div>
                            <div class="modal-body">
                                <p id="error"></p>
                                <form
                                    onSubmit={mySubmit}
                                    encType="multipart/form-data"
                                    id="testi_form"
                                >
                                    <div class="row">
                                        <div class="col-md-12">
                                            <input
                                                class="form-control"
                                                placeholder="region"
                                                onChange={(e) => setRegion(e.target.value)}
                                                value={region}
                                                name="category"
                                                type="text"
                                                required
                                            />
                                            <br />
                                        </div>
                                    </div>
                                    <button type="submit" class="btn btn-success">
                                        Submit
                                    </button>
                                </form>
                            </div>
                        </Modal>
                    </div>
                )
            }
        </>
    )
}

export default RegionalOffers