import React from "react";
import { Link, withRouter } from "react-router-dom";
import config from "../../config.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Pagination } from "react-bootstrap";


const axios = require("axios");

class Properties_list extends React.Component {
  state = {

    filterdata: [],
    showall: true,
    modalIsOpen: false,
    data: [],
    id: "",
    name: "",
    desc: "",
    errors: [],
    activePage: 1,
    totalItemsCount: 0,

    cityId: "",
    cities: [],
    categoryId: "",
    categoryOptn: [],
    categories: [],
    bathroom: "",
    new_bathroom: "",
    bathroomOptn: [],
    new_bathroomOptn: [],
    bathroomData: [

    ],
    bedroom: "",
    new_bedroom: "",
    bedroomOptn: [],
    new_bedroomOptn: [],
    bedroomData: [],
    price: "",
    priceOptn: [],
    new_priceOptn: [],
    price_min: "",
    new_price_min: "",
    new_price_max: "",
    price_max: "",
    minPriceData: [

    ],
    maxPriceData: [

    ],
  };

  initialState = {
    modalIsOpen: false,
    data: [],
    id: "",
    name: "",
    desc: "",
    errors: [],
  };




  componentDidMount = async () => {
    await this.getProperties();
    await this.getCities();
    await this.getCategory();
    await this.searchOptions();
    await this.getBedroom();
    await this.getBathroom();

    // if (window.location.pathname === "/admin/properties_list" &&  this.state.categoryId && this.state.cityId) {
    //   alert('hello')
    //   await this.searchOptions(
    //     this.state.categoryId,
    //     this.state.cityId
    //   );
    //   console.log('ANIKET',this);
    //   this.setState({
    //     cityId: this.state.cityId,
    //     categoryId: this.state.categoryId,
    //     bedroom: this.state.bedroom,
    //     bathroom: this.state.bathroom,
    //     price: this.state.price,
    //     viewAll: true,
    //     price_min: this.state.price_min,
    //     price_max: this.state.price_max,
    //     categoryOptn: this.state.categoryOptn,
    //     priceOptn: this.state.priceOptn,
    //     bathroomOptn: this.state.bathroomOptn,
    //   });
    // }



  };






  getBedroom = () => {
    axios
      .get(`${config.backend_URL}/admin/getBedroom`)
      .then((responseJson) => {
        console.log(responseJson.data.data);
        // return
        this.setState({ bedroomData: responseJson.data.data });

        console.log(this.state.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  getBathroom = () => {
    axios
      .get(`${config.backend_URL}/admin/getBathroom`)
      .then((responseJson) => {
        console.log(responseJson.data.data);
        // return
        this.setState({ bathroomData: responseJson.data.data });
        console.log(this.state.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  checkCategory = (e) => {
    //alert(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
    this.searchOptions(e.target.value, this.state.cityId);
  };
  checkCity = (e) => {
    //alert(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
    this.searchOptions1(e.target.value);
  };
  checkBedroom = (e) => {
    //alert(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ new_bedroom: e.target.value });
    this.searchOptions2(
      e.target.value,
      this.state.categoryId,
      this.state.cityId
    );
  };
  checkBathroom = (e) => {
    //alert(e.target.value);
    this.setState({ [e.target.name]: e.target.value });
    this.setState({ new_bathroom: e.target.value });
    this.searchOptions3(
      this.state.bedroom,
      e.target.value,
      this.state.categoryId,
      this.state.cityId
    );
  };
  searchOptions = (id, cid) => {
    // alert(id);
    this.setState({
      bedroomOptn: [],
      bathroomOptn: [],
      priceOptn: [],
      bedroom: "",
      bathroom: "",

      price_min: "",
      price_max: "",
    });
    if (id != "" && cid != "") {
      let newObj = {
        categoryId: id,
        cityId: cid,
      };
      axios
        .post(`${config.backend_URL}/admin/searchOptions`, newObj)
        .then((responseJson) => {
          console.log("manish", responseJson.data);
          this.setState({
            bedroomOptn: responseJson.data.data[0].bedroom.sort(),
            /*   bathroomOptn: responseJson.data.data[0].bathroom.sort(),
            priceOptn: responseJson.data.data[0].price.sort(), */
          });
          this.setState({
            new_bedroomOptn: responseJson.data.data[0].bedroom.sort(),
          });

        })
        .catch((error) => {
          console.error(error);
        });
    }
  };
  searchOptions1 = (cid) => {
    //alert(cid);
    this.setState({
      bedroomOptn: [],
      bathroomOptn: [],
      categoryOptn: [],
      priceOptn: [],

      categoryId: "",
      bedroom: "",
      bathroom: "",

      price_min: "",
      price_max: "",
    });

    let newObj = {
      cityId: cid,
    };
    axios
      .post(`${config.backend_URL}/admin/searchcityfromcategory`, newObj)
      .then((responseJson) => {
        console.log("manish", responseJson.data);
        this.setState({
          categoryOptn: responseJson.data.data[0].categoryId.sort(),
        });

        //console.log("aaaaaa", this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  searchOptions2 = (bedid, id, cid) => {
    //alert(bedid);
    this.setState({
      bathroomOptn: [],
      priceOptn: [],

      bathroom: "",

      price_min: "",
      price_max: "",
    });

    let newObj = {
      bedroomId: bedid,
      categoryId: id,
      cityId: cid,
    };
    axios
      .post(`${config.backend_URL}/admin/searchcityfrombedroom`, newObj)
      .then((responseJson) => {
        console.log("manish bathroom", responseJson.data);
        this.setState({
          bathroomOptn: responseJson.data.data[0].bathroom.sort(),
        });
        this.setState({
          new_bathroomOptn: responseJson.data.data[0].bathroom.sort(),
        });

        //console.log("aaaaaa", this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };
  searchOptions3 = (bedid, bathid, id, cid) => {
    //alert(bedid);
    this.setState({
      priceOptn: [],
      price: "",
      price_max: "",
    });

    let newObj = {
      bedroomId: bedid,
      bathroomId: bathid,
      categoryId: id,
      cityId: cid,
    };
    axios
      .post(`${config.backend_URL}/admin/searchpricefrombathroom`, newObj)
      .then((responseJson) => {
        console.log("price", responseJson.data);
        this.setState({
          priceOptn: responseJson.data.data[0].price.sort(),
        });
        this.setState({
          new_priceOptn: responseJson.data.data[0].price.sort(),
        });

        //console.log("aaaaaa", this.state);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCities = () => {
    axios
      .get(`${config.backend_URL}/admin/getCities`)
      .then((responseJson) => {
        this.setState({ cities: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCategory = () => {
    axios
      .get(`${config.backend_URL}/admin/getCategories`)
      .then((responseJson) => {
        this.setState({ categories: responseJson.data.data });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  changedata = (e) => {
    if (e.target.name === "price") {
      var min = e.target[e.target.selectedIndex].getAttribute("data-min");

      this.setState({ price_min: Number(min), price: e.target.value });
      this.setState({ new_price_min: Number(min), price: e.target.value });
    } else if (e.target.name === "pricemax") {
      var max = e.target[e.target.selectedIndex].getAttribute("data-max");
      this.setState({
        price_max: Number(max),
        pricemax: e.target.value,
      });
      this.setState({
        new_price_max: Number(max),
        pricemax: e.target.value,
      });
    } else {
      this.setState({ [e.target.name]: e.target.value });
      this.setState({ new_price_max: e.target.value });

    }

  };

  getProperties = () => {
    axios
      .post(`${config.backend_URL}/admin/getProperties`, {
        page: this.state.activePage,
      })
      .then((responseJson) => {
        this.setState({
          data: responseJson.data.data,
          totalItemsCount: responseJson.data.total,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  handlePageChange = async (pageNumber) => {
    await this.setState({ activePage: pageNumber });
    this.getProperties();
  };

  delProperty = (id) => {
    if (window.confirm("Are you sure")) {
      let options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: id }),
      };
      fetch(`${config.backend_URL}/admin/delProperty`, options)
        .then((res) => {
          //console.log("response",res)
          return res.json();
        })
        .then((data) => {
          if (data.status === true) {
            this.getProperties();

            toast("Property deleted successfully!!");
            //this.setState(this)
          } else {
            toast("Something wrong!");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    }
  };

  getManagrEmail = (manager_emails) => {
    let manager_email = manager_emails
      .map((elem) => {
        return elem.manager_email;
      })
      .join("\n");

    return manager_email;
  };

  mySubmit = async (e) => {
    e.preventDefault();
    await this.searchOptions(
      this.state.categoryId,
      this.state.cityId
    );

    axios
      .post(`${config.backend_URL}/admin/filterProperty`, {

        cityId: this.state.cityId,
        categoryId: this.state.categoryId,
        bedroom: this.state.new_bedroom,
        bathroom: this.state.new_bathroom,
        price: this.state.price,
        price_min: this.state.new_price_min,
        price_max: this.state.new_price_max,
        categoryOptn: this.state.categoryOptn,
        priceOptn: this.state.new_priceOptn,
        bedroomOptn: this.state.new_bedroomOptn,
        bathroomOptn: this.state.new_bathroomOptn,
        bathroomData: this.state.bathroomData,
        bedroomData: this.state.bedroomData,
        addClass: false,
        timestamp: -1,



      })
      .then(async (responseJson) => {
        this.setState({ filterdata: responseJson.data.data })
        this.setState({ showall: false })
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <>
        <ToastContainer />
        <div class="content-wrapper">
          <section class="content-header">
            <div class="container-fluid">
              <div class="row mb-2">
                <div class="col-sm-6">
                  <h1> Properties </h1>
                </div>
                <div class="col-sm-6">
                  <ol class="breadcrumb float-sm-right">
                    <Link to="/admin/properties">
                      <button class="pull-right btn btn-success">
                        Add Property
                      </button>
                    </Link>
                  </ol>
                </div>
              </div>
            </div>
          </section>

          <section class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="card card-primary">
                    <div class="card-header">
                      <h3 class="card-title">Filter Properties</h3>
                    </div>
                    <form onSubmit={this.mySubmit}>
                      <div class="card-body">
                        <div class="row">
                          <div class="form-group col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <label>City *</label>
                            <select
                              class="form-control"
                              name="cityId"
                              onChange={this.checkCity}
                              value={this.state.cityId}
                              required
                            >
                              <option value="">Select</option>
                              {this.state.cities.length > 0
                                ? this.state.cities.map((x, key) => {
                                  return (
                                    <option value={`${x._id}`}> {x.city} </option>
                                  );
                                })
                                : ""}
                            </select>
                          </div>
                          <div class="form-group col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <label>Category *</label>
                            <select
                              class="form-control"
                              name="categoryId"
                              onChange={this.checkCategory}
                              value={this.state.categoryId}
                              required
                            >
                              <option value="">Select</option>
                              {this.state.categoryOptn.length > 0
                                ? this.state.categories
                                  .sort((a, b) => a - b)
                                  .map((x, key) => {
                                    var tm = this.state.categoryOptn.includes(x._id)
                                      ? x.category
                                      : "hidden";
                                    var mt =
                                      tm != "hidden" ? (
                                        <option value={x._id}> {tm} </option>
                                      ) : (
                                        ""
                                      );
                                    return mt;
                                  })
                                : ""}
                            </select>
                          </div>
                          <div class="form-group col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <label>Bedroom</label>
                            <select
                              class="form-control"
                              name="bedroom"
                              onChange={this.checkBedroom}
                              value={this.state.bedroom}

                            >
                              <option value="">Select</option>
                              {this.state.bedroomOptn.length > 0
                                ? this.state.bedroomData
                                  .sort((a, b) => a - b)
                                  .map((x, key) => {
                                    var tm = this.state.bedroomOptn.includes(x._id)
                                      ? x.bedroom
                                      : "hidden";
                                    var mt =
                                      tm != "hidden" ? (
                                        <option value={x._id}> {tm} </option>
                                      ) : (
                                        ""
                                      );
                                    return mt;
                                  })
                                : ""}
                            </select>
                          </div>
                          <div class="form-group col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <label>Bathroom</label>
                            <select
                              class="form-control"
                              name="bathroom"
                              onChange={this.checkBathroom}
                              value={this.state.bathroom}

                            >
                              <option value="">Select</option>
                              {this.state.bathroomOptn.length > 0
                                ? this.state.bathroomData
                                  .sort((a, b) => a - b)
                                  .map((x, key) => {
                                    var tm = this.state.bathroomOptn.includes(x._id)
                                      ? x.bathroom
                                      : "hidden";
                                    var mt =
                                      tm != "hidden" ? (
                                        <option value={x._id}> {tm} </option>
                                      ) : (
                                        ""
                                      );
                                    return mt;
                                  })
                                : ""}
                            </select>
                          </div>
                          <div class="form-group col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <label>Min Price Range</label>
                            <select
                              class="form-control"
                              name="price"
                              onChange={this.changedata}
                              value={this.state.price}

                            >
                              <option value="">Select</option>
                              {this.state.priceOptn.length > 0
                                ? this.state.priceOptn
                                  .sort((a, b) => a - b)
                                  .map((x, key) => {
                                    return (
                                      <>
                                        {key == 0 ? (
                                          <>
                                            <option value={x} data-min={x}>
                                              From ${x}{" "}
                                            </option>
                                          </>
                                        ) : (
                                          <> </>
                                        )}
                                      </>
                                    );
                                  })
                                : ""}
                            </select>
                          </div>
                          <div class="form-group col-xl-2 col-lg-3 col-md-4 col-sm-6">
                            <label>Max Price Range</label>
                            <select
                              class="form-control"
                              name="price_max"
                              onChange={this.changedata}
                              value={this.state.price_max}

                            >
                              <option value="">Select</option>
                              {this.state.priceOptn.length > 0
                                ? this.state.priceOptn
                                  .sort((a, b) => a - b)
                                  .map((x, key) => {
                                    return (
                                      <>
                                        {key == this.state.priceOptn.length - 1 ? (
                                          <>
                                            <option value={x} data-min={x}>
                                              From ${x}{" "}
                                            </option>
                                          </>
                                        ) : (
                                          <> </>
                                        )}
                                      </>
                                    );
                                  })
                                : ""}
                            </select>
                          </div>
                        </div>
                        <div class="form-group">
                          <button
                            type="submit"
                            class="btn btn-primary"
                          >Submit</button>


                          {this.state.filterdata.length > 0 ? (
                            <button
                              className="btn btn-success mx-2"
                              onClick={()=>window.location.replace('/admin/properties_list')}
                            >
                              Clear
                            </button>
                          ) : (
                            ""
                          )}

                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="content">
            <div class="container-fluid">
              <div class="row">
                <div class="col-md-12">
                  <div class="card card-primary">
                    <div class="card-header">
                      <h3 class="card-title">Properties List</h3>
                    </div>
                    <div class="card-body">
                      <table
                        id="exampl e1"
                        class="table table-bordered table-striped"
                      >
                        <thead>
                          <tr>
                            <th width="8%">Sr No.</th>
                            <th>Image</th>
                            <th>Name</th>
                            <th>Address</th>
                            <th>Manager Number</th>
                            <th>Manager email</th>
                            <th width="10%">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.showall ? this.state.data.length > 0 ? (
                            this.state.data.map((x, key) => {
                              let imagePath1 =
                                config.cdn_backend_URL + "/" + x.property_image;
                              let imgPath =
                                x.property_image === ""
                                  ? `${config.cdn_backend_URL}/no-property.jpg`
                                  : imagePath1;

                              return (
                                <tr key={key}>
                                  <td>{++key}</td>
                                  <td>
                                    <img
                                      src={imgPath}
                                      height={100}
                                      alt="property"
                                    />
                                  </td>
                                  <td>{x.property_name}</td>
                                  <td>{x.address}</td>
                                  <td>{x.manager_number}</td>
                                  <td>
                                    {"manager_emails" in x
                                      ? this.getManagrEmail(x.manager_emails)
                                      : ""}
                                  </td>
                                  <td>
                                    <Link to={`properties?id=${x._id}`}>
                                      <a
                                        href="#"
                                        class="btn btn-success btn-sm"
                                      >
                                        <i class="fa fa-edit"></i>
                                      </a>
                                    </Link>{" "}
                                    &nbsp;&nbsp;
                                    <a
                                      class="btn btn-danger btn-sm"
                                      href="#"
                                      onClick={() => {
                                        this.delProperty(x._id);
                                      }}
                                    >
                                      <i class="fas fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={7} style={{ color: "red" }}>
                                <center> Result Not Found </center>
                              </td>
                            </tr>
                          ) : this.state.filterdata.length > 0 ? (
                            this.state.filterdata.map((x, key) => {
                              let imagePath1 =
                                config.backend_URL + "/" + x.property_image;
                              let imgPath =
                                x.property_image === ""
                                  ? `${config.backend_URL}/no-property.jpg`
                                  : imagePath1;

                              return (
                                <tr key={key}>
                                  <td>{++key}</td>
                                  <td>
                                    <img
                                      src={imgPath}
                                      height={100}
                                      alt="property"
                                    />
                                  </td>
                                  <td>{x.property_name}</td>
                                  <td>{x.address}</td>
                                  <td>{x.manager_number}</td>
                                  <td>
                                    {"manager_emails" in x
                                      ? this.getManagrEmail(x.manager_emails)
                                      : ""}
                                  </td>
                                  <td>
                                    <Link to={`properties?id=${x._id}`}>
                                      <a
                                        href="#"
                                        class="btn btn-success btn-sm"
                                      >
                                        <i class="fa fa-edit"></i>
                                      </a>
                                    </Link>{" "}
                                    &nbsp;&nbsp;
                                    <a
                                      class="btn btn-danger btn-sm"
                                      href="#"
                                      onClick={() => {
                                        this.delProperty(x._id);
                                      }}
                                    >
                                      <i class="fas fa-trash"></i>
                                    </a>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td colSpan={7} style={{ color: "red" }}>
                                <center> Result Not Found </center>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>

                    <div className="d-flex justify-content-center">
                      <Pagination
                        itemClass="page-item"
                        linkClass="page-link"
                        activePage={this.state.activePage}
                        itemsCountPerPage={10}
                        totalItemsCount={this.state.totalItemsCount}
                        pageRangeDisplayed={5}
                        onChange={this.handlePageChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}
export default withRouter(Properties_list);
