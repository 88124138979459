import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import {
  AdminAboutus,
  AdminAddBlog,
  AdminAddBlogCategory,
  AdminAmeneties,
  AdminBanner,
  AdminBathroom,
  AdminBedroom,
  AdminBlogCategoryList,
  AdminBlogList,
  AdminCategory,
  AdminCity,
  AdminFeedback,
  AdminProperties,
  AdminProperties_list,
  AdminSeourl,
  Adminsetting,
  AdminSmtp,
  AdminSocialRating,
  AdminTestimonials,
  AdminVisitorParking,
  AdminWebsetting,
  AdminWhychoose,
  ChangePassword,
  Dashboard,
  EditLandingPage,
  ForgotPassword,
  Property_Appointment,
  RegionalOffers,
  RegionalOffer,
  ResetPassword,
  TwoFa,
} from "../../components";
import Author from "./Author";
import Footer from "./includes/Footer.js";
import Header from "./includes/Header.js";
import Sidebar from "./includes/Sidebar.js";
class Admin extends React.Component {
  render() {
    return (
      <>
        <div class="wrapper loginWrapper layout-fixed">
          <Header />
          <Sidebar />
          <Switch>
            <Route
              exact
              path={`${this.props.match.url}/`}
              component={Dashboard}
            ></Route>
            <Route
              exact
              path={`${this.props.match.url}/`}
              component={ForgotPassword}
            ></Route>
            <Route
              exact
              path={`${this.props.match.url}/`}
              component={ResetPassword}
            ></Route>
            <Route
              path={`${this.props.match.url}/dashboard`}
              component={Dashboard}
            ></Route>
            <Route
              path={`${this.props.match.url}/city`}
              component={AdminCity}
            ></Route>
            <Route
              path={`${this.props.match.url}/category`}
              component={AdminCategory}
            ></Route>
            <Route
              path={`${this.props.match.url}/banner`}
              component={AdminBanner}
            ></Route>
            <Route
              path={`${this.props.match.url}/properties`}
              component={AdminProperties}
            ></Route>
            <Route
              path={`${this.props.match.url}/properties_list`}
              component={AdminProperties_list}
            ></Route>
            <Route
              path={`${this.props.match.url}/addBlogCategory`}
              component={AdminAddBlogCategory}
            ></Route>
            <Route
              path={`${this.props.match.url}/blogCategoryList`}
              component={AdminBlogCategoryList}
            ></Route>
            <Route
              path={`${this.props.match.url}/addBlog`}
              component={AdminAddBlog}
            ></Route>
            <Route
              path={`${this.props.match.url}/blogList`}
              component={AdminBlogList}
            ></Route>

            <Route
              path={`${this.props.match.url}/author`}
              component={Author}
            ></Route>
            <Route
              path={`${this.props.match.url}/testimonials`}
              component={AdminTestimonials}
            ></Route>
            <Route
              path={`${this.props.match.url}/whychoose`}
              component={AdminWhychoose}
            ></Route>
            <Route
              path={`${this.props.match.url}/aboutus`}
              component={AdminAboutus}
            ></Route>
            <Route
              path={`${this.props.match.url}/feedback`}
              component={AdminFeedback}
            ></Route>
            <Route
              path={`${this.props.match.url}/visitor_parking`}
              component={AdminVisitorParking}
            ></Route>
            <Route
              path={`${this.props.match.url}/property_appointment`}
              component={Property_Appointment}
            ></Route>
            <Route
              path={`${this.props.match.url}/websetting`}
              component={AdminWebsetting}
            ></Route>
            <Route
              path={`${this.props.match.url}/landingpage`}
              component={EditLandingPage}
            ></Route>
            <Route
              path={`${this.props.match.url}/regional-offers`}
              component={RegionalOffers}
            ></Route>
            <Route
              path={`${this.props.match.url}/regional-offer`}
              component={RegionalOffer}
            ></Route>
            <Route
              path={`${this.props.match.url}/adminsetting`}
              component={Adminsetting}
            ></Route>
            <Route
              path={`${this.props.match.url}/change_password`}
              component={ChangePassword}
            ></Route>

            <Route
              path={`${this.props.match.url}/ameneties`}
              component={AdminAmeneties}
            ></Route>
            <Route
              path={`${this.props.match.url}/google_fb_rating`}
              component={AdminSocialRating}
            ></Route>
            <Route
              path={`${this.props.match.url}/meta_tags`}
              component={AdminSeourl}
            ></Route>
            <Route
              path={`${this.props.match.url}/bedroom`}
              component={AdminBedroom}
            ></Route>
            <Route
              path={`${this.props.match.url}/bathroom`}
              component={AdminBathroom}
            ></Route>
            <Route
              path={`${this.props.match.url}/smtp`}
              component={AdminSmtp}
            ></Route>
            <Route
              path={`${this.props.match.url}/?`}
              component={ResetPassword}
            ></Route>
            <Route
              path={`${this.props.match.url}/2fa`}
              component={TwoFa}
            ></Route>
          </Switch>
          <Footer />
        </div>
      </>
    );
  }
}
export default withRouter(Admin);
