import axios from "axios";
import React from "react";
import Pagination from "react-js-pagination";
import { Link } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { FooterFront, HeaderFront } from "../components";
import config from "../config.js";
import helper from "../helper";
class BlogList extends React.Component {
  constructor(props) {
    super(props);
  }
  category = this.props.match?.params?.category;
  state = {
    loader_flag: false,
    modalIsOpen: false,
    blogList: [],
    totalCount: 0,
    categoryList: [],
    activePage: 1,
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    name: undefined,
    sort: "1",
    blog_id: "",
    categoryId: this.props.location.state?.category || "blog",
    selectedBlogCategory: this.props.location.state?.category || "blog",
    blog_category: this.props.location.state?.category
      ? this.props.location.state?.category
      : "",
  };

  initialState = {
    loader_flag: false,
    modalIsOpen: false,
    name: "",
    activePage: 1,
    itemsCountPerPage: 10,
  };

  componentDidMount = async () => {
    this.getCateogryList(1);
  };

  getCateogryList = async (pageNumber) => {
    this.setState({ activePage: pageNumber });
    window.scrollTo(0, 0);
    try {
      let uploadObj = {
        page: pageNumber,
        limit: this.state.itemsCountPerPage,
        name: this.state.name,
        sort: this.state.sort === "1" ? { timestamp: -1 } : { timestamp: 1 },
        category: this.state.categoryId !== "blog" ? this.state.categoryId : "",
      };

      axios
        .post(`${config.backend_URL}/admin/blog/fetchActiveBlog`, uploadObj)
        .then((res) => {
          if (res.status === 200) {
            this.setState({
              blogList: res.data?.result,
              totalCount: res.data?.count,
              categoryList: res.data?.category,
              itemsCountPerPage: uploadObj.limit,
            });
            if (this.state.categoryId === "blog") {
              helper.setMetaTitle("Blog");
            } else {
              helper.setMetaTitle(
                res.data.result[0].category.meta_content.title
              );
            }
          } else {
            toast.error("Something went wrong");
          }

          // this.setState({ categoryList: res.data.data });
          // this.setState({ loader_flag: false });
        });
    } catch (err) {
      console.log("err: ", err);
      toast.error("Something went wrong");
    }
  };
  handlePageChange(pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }
  render() {
    return (
      <>
        <HeaderFront />
        <ToastContainer />
        <div className="exceptAppWrapper">
          <section>
            <article
              className="pageHdrBlock wrapper py-80"
              style={{ backgroundImage: `url(${"/images/aboutPageBg.jpg"})` }}
            >
              <div className="pageHdrOverlay"></div>
              <div className="container">
                <div className="row align-items-center justify-space-beetween">
                  <div className="col-sm">
                    <h3>
                      {this.state.selectedBlogCategory === "blog" ? (
                        <>
                          Blog <span>List</span>
                        </>
                      ) : (
                        <span className="text-capitalize">
                          {this.state.selectedBlogCategory}
                        </span>
                      )}
                    </h3>
                  </div>
                  <div className="col-sm-auto">
                    <div className="pagebrdcrmbs">
                      <nav aria-label="breadcrumb">
                        <ol className="breadcrumb">
                          <li className="breadcrumb-item">
                            <Link to="/">Home</Link>
                          </li>
                          <li
                            className="breadcrumb-item active"
                            aria-current="page"
                          >
                            {this.state.selectedBlogCategory === "blog" ? (
                              <>
                                Blog <span>List</span>
                              </>
                            ) : (
                              <span className="text-capitalize">
                                {this.state.selectedBlogCategory}
                              </span>
                            )}
                          </li>
                        </ol>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </article>
            <article className="propertyCatBlock blogListBlock wrapper pt-80">
              <div className="container">
                <div className="blogListOuter">
                  <div className="row g-4">
                    <div className="col-md-5">
                      <div className="mainHeading headingWhite m-0">
                        <h2 className="m-0">Articles available for read</h2>
                      </div>
                    </div>
                    <div className="col-md-7">
                      <div className="  gap-2 blogListOuter-rightFilter d-flex flex-wrap align-items-center justify-content-start">
                        <div className="srchInput col-md-3">
                          <input
                            className="form-control"
                            placeholder="Search by title..."
                            value={this.state.name}
                            onChange={(e) => {
                              this.setState({ name: e.target.value }, () => {
                                this.getCateogryList(this.state.activePage);
                              });
                            }}
                          />
                        </div>
                        <div className="col-md-3">
                          <select
                            className="form-select newest"
                            value={this.state.selectedBlogCategory}
                            onChange={(e) => {
                              const categoryId = e.target.value;
                              const categoryName =
                                e.target.options[e.target.selectedIndex].text;
                              const url = categoryName
                                .toLowerCase()
                                .replaceAll(" ", "-");

                              this.setState(
                                {
                                  selectedBlogCategory: categoryName,
                                  categoryId: categoryId,
                                },
                                () => {
                                  this.props.history.push(
                                    `/${
                                      categoryId === "blog" ? categoryId : url
                                    }`
                                  );
                                  this.getCateogryList(this.state.activePage);
                                }
                              );
                            }}
                          >
                            <option value="blog">Category</option>
                            {this.state.categoryList.length > 0 &&
                              this.state.categoryList.map((item, index) => (
                                <option key={item._id} value={item.name}>
                                  {item.name}
                                </option>
                              ))}
                          </select>
                        </div>
                        <div className="col-md-3">
                          <select
                            className="form-select newest"
                            value={this.state.sort}
                            onChange={(e) => {
                              this.setState({ sort: e.target.value }, () => {
                                this.getCateogryList(this.state.activePage);
                              });
                            }}
                          >
                            <option value={undefined}>Sort</option>
                            <option value="1">Newest</option>
                            <option value="2">Oldest</option>
                          </select>
                        </div>
                        <div className="col-md-2">
                          <select
                            className="form-select smallSlct"
                            value={this.state.itemsCountPerPage}
                            onChange={(e) => {
                              this.setState({ limit: e.target.value }, () => {
                                this.getCateogryList(this.state.activePage);
                              });
                            }}
                          >
                            <option value={undefined}># of blogs</option>
                            <option value="10">10</option>
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row g-4 mt-4">
                    {this.state.blogList?.length > 0 ? (
                      this.state.blogList.map((item, index) => {
                        const category = item?.category?.name
                          ?.toLowerCase()
                          ?.replaceAll(" ", "-");
                        return (
                          <div className="col-md-6 col-lg-4" key={index}>
                            <div className="blogListCol">
                              <Link
                                to={{ pathname: `/gskblog/${category}/${item?.slug}` }}
                              >
                                <div className="blogListImgDiv">
                                  <div
                                    className="blogListImg"
                                    style={{
                                      backgroundImage: `url(${
                                        item.images.length > 0
                                          ? config.backend_URL +
                                            "/" +
                                            item.images[0]
                                          : "/images/amenities1.jpg"
                                      })`,
                                    }}
                                  ></div>
                                  {/* <div
                                    className="blogListImg"
                                    style={{
                                      backgroundImage: `url(${"/images/amenities1.jpg"})`,
                                    }}
                                  ></div> */}
                                  <span className="blogDate-view">
                                    {/* <i>
                                  <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M3 16.5L12 21.75L21 16.5" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M3 12L12 17.25L21 12" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                    <path d="M3 7.5L12 12.75L21 7.5L12 2.25L3 7.5Z" stroke="currentColor" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"></path>
                                  </svg>
                                </i> */}
                                    {item?.category?.name}
                                  </span>
                                  {/* <span className="blogDate-view"><i className="fa fa-eye" aria-hidden="true"></i>{" "}{item.views}</span> */}
                                </div>
                                <h3 className="px-4">{item.name}</h3>
                              </Link>
                              <div
                                className="  "
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  height: "100%",
                                  flexDirection: "column",
                                  padding: "0px 20px 20px 20px",
                                }}
                              >
                                <div
                                  className="blogDesc"
                                  dangerouslySetInnerHTML={{
                                    __html: item.description
                                      .split(" ")
                                      .slice(0, 20)
                                      .join(" "),
                                  }}
                                ></div>
                                <Link
                                  to={{
                                    pathname: `/gskblog/${category}/${item.name
                                      .replace(/[^a-zA-Z0-9]+/g, "-")
                                      .toLowerCase()}`,
                                    state: { id: item._id },
                                  }}
                                >
                                  <button className="radius-50 btnCommon">
                                    Read More
                                  </button>
                                </Link>
                                {/* <div className="blogCat d-flex align-items-center gap-2">
                                
                                <h4>{moment(item.creationDate).format("MMM D, YYYY")}</h4>
                              </div> */}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="text-center">No data found</div>
                    )}
                  </div>
                  <div className="paginationDiv mt-5">
                    <Pagination
                      itemClass="page-item"
                      linkClass="page-link"
                      activePage={this.state.activePage}
                      itemsCountPerPage={this.state.itemsCountPerPage}
                      totalItemsCount={this.state.totalCount}
                      pageRangeDisplayed={this.state.pageRangeDisplayed}
                      onChange={this.getCateogryList}
                    />
                  </div>
                </div>
              </div>
            </article>
          </section>
        </div>
        <FooterFront />
      </>
    );
  }
}

export default BlogList;
