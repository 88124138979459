exports.dynamicSubmitLink = (propertyName, city) =>  {
    const scriptFrame = document.getElementById("scriptFrame")
    const iWindow = scriptFrame.contentWindow;
    const iDocument = iWindow.document;
    const applicationButton = iDocument.getElementById("applicationButton")
    const applicationButtonText = iDocument.getElementById("applicantButtonText")
    const urlAnchor = iDocument.getElementById("scriptUrlLink")
    const updatedURL = updateURL(urlAnchor.href, propertyName, city)

    applicationButton.onclick = redirectApplicant
    applicationButtonText.onclick = redirectApplicant
    function redirectApplicant() { window.location.href = updatedURL }
}

const updateURL =  (rawURL, propertyName, city) => {
    const prams = `&propertyName=${propertyName}&city=${city}`
    return rawURL + prams;
}


