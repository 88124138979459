import React from "react";
import { withRouter } from "react-router-dom";
import config from "../../config.js";
import Links from "./includes/Links.js";
import Loader from "../../loader.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import $ from "jquery";
import jwt from "jsonwebtoken";

const axios = require("axios");

const location1 = window.location.href;
const position1 = location1.search("tkn");
const subStr1 = location1.substring(position1);

// geting position of domain and domain it self
const domainPosition1 = subStr1.search("=");
const Token = subStr1.substring(domainPosition1 + 1);

const CHECKTOKEN_URL = `/admin/check-token`;


class ResetPassword extends React.Component {
  state = {
    loader_flag: false,

    showError: false,
    errorMsg: "",
    color: "",
    showError1: false,
    errorMsg1: "",
    color1: "",
    showError2: false,
    errorMsg2: "",
    color2: "",
    showPage: true,

  };

  constructor(props) {
    super(props);

    this.state = {
      hidden: true,
      newPassword: "",
      hidden1: true,
      confirmPassword: "",
      valid: true,
    };

    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.newPasswordShow = this.newPasswordShow.bind(this);

    this.handleConfirmPasswordChange =
      this.handleConfirmPasswordChange.bind(this);

    this.confirmPasswordShow = this.confirmPasswordShow.bind(this);
  }

  handlePasswordChange(e) {
    this.setState({ newPassword: e.target.value });
  }

  newPasswordShow() {
    this.setState({ hidden: !this.state.hidden });
  }

  handleConfirmPasswordChange(e) {
    this.setState({ confirmPassword: e.target.value });
  }

  confirmPasswordShow() {
    this.setState({ hidden1: !this.state.hidden1 });
  }

  componentDidMount() {
    if (this.props.newPassword) {
      this.setState({ newPassword: this.props.newPassword });
    }

    if (this.props.confirmPassword) {
      this.setState({ confirmPassword: this.props.confirmPassword });
    }


    this.CheckToken();
  }

  CheckToken = () => {
    axios
      .post(`${config.backend_URL}/admin/check-token`, {
        resetToken: Token,
      })
      .then((responseJson) => {
        if (responseJson.data.status === 200) {
          this.setState({ valid: false });
        }
        if (responseJson.data.status === 404) {
          this.setState({ valid: true });
        }
      });
  };

  mySubmit = async (event) => {
    event.preventDefault();
    if (this.state.newPassword === "") {
      this.setState({ showError: true });
      this.setState({ errorMsg: "Cannot be empty" });
      this.setState({ color: "red" });
    } else {
      this.setState({ showError: false });
    }

    if (this.state.confirmPassword === "") {
      this.setState({ showError1: true });
      this.setState({ errorMsg1: "Cannot be empty" });
      this.setState({ color1: "red" });
    } else {
      this.setState({ showError1: false });
    }
    if (this.state.newPassword !== "" && this.state.confirmPassword !== "") {
      if (this.state.newPassword !== this.state.confirmPassword) {
        this.setState({ showError2: true });
        this.setState({ errorMsg2: "Password's are not matching" });
        this.setState({ color2: "red" });
      } else {
        axios
          .post(`${config.backend_URL}/admin/reset`, {
            resetToken: Token,
            password: this.state.confirmPassword,
          })
          .then((responseJson) => {
            if (responseJson.data.status === 200) {
              this.setState({ showError2: true });
              this.setState({ errorMsg2: responseJson.data.message });
              this.setState({ color2: "green" });
              window.location.replace("/login");
            }
            if (responseJson.data.status === 404) {
              this.setState({ showError2: true });
              this.setState({ errorMsg2: responseJson.data.message });
              this.setState({ color2: "red" });
            }
          });
      }
    }
  };

  render() {
    if (this.state.valid) {
      return (
        <>
          <Links />

          <ToastContainer />
          {this.state.loader_flag ? (
           <><Loader /></>
          ) : (
            <>
              <div class="appHdr">
                <div class="loginCenter">
                  <div class="login-box">
                    <div class="login-logo">
                      <a href="/admin">
                        <b>GSK Properties</b>
                      </a>
                    </div>
                    <div class="card">
                      <div class="card-body login-card-body">
                        <p class="login-box-msg" style={{ color: "red" }}>
                          Token is Invalid !
                        </p>
                        <form onSubmit={this.mySubmit}></form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          <Links />

          <ToastContainer />
          {this.state.loader_flag ? (
           <><Loader /></>
          ) : (
            <>
              <div class="appHdr">
                <div class="loginCenter">
                  <div class="login-box">
                    <div class="login-logo">
                      <a href="/admin">
                        <b>GSK Properties</b>
                      </a>
                    </div>
                    <div class="card">
                      <div class="card-body login-card-body">
                        <p class="login-box-msg">
                          Please fill the details below to reset your password.
                        </p>
                        <form onSubmit={this.mySubmit}>
                          <div class="mb-3">
                            <div class="input-group">
                              <input
                                className="form-control"
                                placeholder="New Password"
                                name="newPassword"
                                type={this.state.hidden ? "password" : "text"}
                                value={this.state.newPassword}
                                onChange={this.handlePasswordChange}
                              />
                              <div
                                class="input-group-append input-group-click"
                                onClick={this.newPasswordShow}
                              >
                                <div class="input-group-text">
                                  {this.state.hidden ? (
                                    <span class="fas fa-eye-slash"></span>
                                  ) : (
                                    <span class="fas fa-eye"></span>
                                  )}
                                </div>
                              </div>
                            </div>
                            {this.state.showError ? (
                              <p
                                id="errorMsg"
                                className="text-center"
                                style={{ color: this.state.color }}
                              >
                                {this.state.errorMsg}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="mb-3">
                            <div class="input-group">
                              <input
                                className="form-control"
                                placeholder="Confirm Password"
                                name="confirmPassword"
                                type={this.state.hidden1 ? "password" : "text"}
                                value={this.state.confirmPassword}
                                onChange={this.handleConfirmPasswordChange}
                              />
                              <div
                                class="input-group-append input-group-click"
                                onClick={this.confirmPasswordShow}
                              >
                                <div class="input-group-text">
                                  {this.state.hidden1 ? (
                                    <span class="fas fa-eye-slash"></span>
                                  ) : (
                                    <span class="fas fa-eye"></span>
                                  )}
                                </div>
                              </div>
                            </div>
                            {this.state.showError2 ? (
                              <p style={{ color: this.state.color2 }}>
                                {this.state.errorMsg2}
                              </p>
                            ) : (
                              ""
                            )}
                            {this.state.showError1 ? (
                              <p
                                id="errorMsg"
                                className="text-center"
                                style={{ color: this.state.color1 }}
                              >
                                {this.state.errorMsg1}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                          <div class="row d-flex align-items-center justify-content-center">
                            <div class="col-xl-6">
                              <button
                                type="submit"
                                class="btn btn-primary btn-block"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

            </>
          )}
        </>
      );
    }

  }
}
export default withRouter(ResetPassword);

//export default ForgotPassword;