import React from "react";
import config from "../config.js";
import SwiperCore,{ Navigation, Pagination, Scrollbar, A11y, EffectFlip } from 'swiper';
import { Swiper, SwiperSlide, } from 'swiper/react';
SwiperCore.use([EffectFlip,Navigation,Pagination])
class Testimonials_Loop extends React.Component {
    render() {
    const imgErrorPath = config.cdn_URL + "/no-img.png"

        return (

            <Swiper observer={true} observerParents={true} modules={[Navigation, Pagination, Scrollbar, A11y]} navigation={this.props.testimonils.length > 1 ?true:false} spaceBetween={30} slidesPerView={1} loop={true} autoplay={{ delay: 3000 }}>
                {this.props.testimonils.length > 0 ? this.props.testimonils.map((x, key) => {
                    let imgPath = config.cdn_backend_URL + '/' + x.image;
                    return (
                        <SwiperSlide key={key}>
                            <div className="testiSlide">
                                <div className="testiContent">
                                    <p>“{x.desc}”</p>
                                </div>
                                <div className="testiInfo d-flex align-items-center">
                                    <div className="testiAuthor">
                                        <span>
                                            <img src={imgPath}
                                            alt="testimonial"
                                            onError={({ currentTarget }) => {
                                                currentTarget.onerror = null
                                                currentTarget.src = imgErrorPath;
                                              }} />
                                        </span>
                                    </div>
                                    <div className="testiTitle">
                                        <h4>{x.name.substring(0, x.name.indexOf(" ") + 2)}</h4>
                                    </div>
                                </div>
                            </div>
                        </SwiperSlide>
                    )
                }) : ''
                }
            </Swiper>
        )
    }
};

export default Testimonials_Loop;