import axios from "axios";
import $ from "jquery";
import moment from "moment";
import queryString from "query-string";
import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link } from "react-router-dom";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import { toast } from "react-toastify";
import SwiperCore, {
  A11y,
  EffectFlip,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FooterFront, HeaderFront } from "../components";
import config from "../config.js";
import helper from "../helper.js";
SwiperCore.use([EffectFlip, Navigation, Pagination]);

class BlogDetail extends React.Component {
  id =
    this.props.location?.state?.id ||
    queryString.parse(this.props.location.search).id;
  title = this.props.match?.params?.blog;
  category = this.props.match?.params?.category;

  state = {
    blog_id: this.id,
    blogData: {},
    blog_list: [],
    blog_keyword: [],
    email: "",
    views: 0,
    weburl: "",
    copied: false,
    category: [],
  };

  componentDidMount = async () => {
    this.getCateogryList();
    const { _id, views, name } = await this.getBlog(this.title);
    this.updateViews(_id, views);
    console.log("this.title: ", this.props.location);
  };

  getBlog = async (id) => {
    try {
      console.log("id: ", this.props, id);
      let response = await axios.get(
        config.backend_URL + `/admin/blog/fetchBlog/${id}`
      );
      console.log("response: ", response);
      if (response.status === 200) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
        this.setState({
          blogData: response.data?.result,
          views: response.data?.result?.views,
          category: response.data?.categories,
        });
        let blogkeyword = response.data?.result?.meta_content?.keyword;
        let blogkeyword1 = blogkeyword.split(", ");
        this.setState({ blog_keyword: blogkeyword1 });
        helper.setMetaTitle(response.data?.result?.meta_content?.title);
        helper.setMetaDesc(response.data?.result?.meta_content?.description);
        helper.setMetaKeyword(response.data?.result?.meta_content?.keyword);
        // this.props.history.push(`/blog/${response.data?.result?.slug}`);
        this.setState({ weburl: window.location.href });
        return response.data?.result;
      } else {
        toast.error(response.data.msg);
      }
    } catch (err) {
      console.log("err: ", err);
      toast.error(err.message);
    }
  };

  addSubscriber = async (e) => {
    e.preventDefault();
    // try {
    //   let temp = await postData("backURL", `newsletter/add`, 0, 0, {
    //     email: email,
    //   });
    //   console.log(temp, ">>>>>>>>>>>>>>>>>>>>");
    //   if (temp.status == 200) {
    //     toast("Subscribed successfully!");
    //     setEmail("");
    //   } else {
    //     toast(temp.data.msg);
    //   }
    // } catch (error) {
    //   console.log(error);
    //   toast("Email Already subscribed!");
    // }
  };

  getCateogryList = async () => {
    // this.setState({ loader_flag: true });
    try {
      let uploadObj = {
        limit: 5,
        category: "",
      };

      axios
        .post(`${config.backend_URL}/admin/blog/fetchActiveBlog`, uploadObj)
        .then((res) => {
          console.log("res.data_bloglist: ", res);
          if (res.status === 200) {
            console.log("res.data: ", res.data);
            this.setState({ blog_list: res.data?.result });
          } else {
            toast.error("Something went wrong");
          }
          // this.setState({ categoryList: res.data.data });
          // this.setState({ loader_flag: false });
        });
    } catch (err) {
      console.log("err: ", err);
      toast.error("Something went wrong");
    }
  };

  updateViews = async (id, view) => {
    let updateObj = {
      views: view + 1,
    };
    try {
      let response = await axios.post(
        config.backend_URL + `/admin/blog/updateBlog/${id}`,
        updateObj
      );
      console.log("response: ", response.data?.result);
      if (response.status === 200) {
        // this.setState({ views: response.data?.result});
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      console.log("err: ", err);
      toast.error(err.message);
    }
  };

  shareSettings = () => {
    $("#sharepop").toggle();
  };

  copyFunction = () => {
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 2000);
  };
  render() {
    return (
      <>
        <HeaderFront />
        <div className="exceptAppWrapper">
          <section>
            {/* <article className="pageHdrBlock wrapper py-80" style={{ backgroundImage: `url("/images/aboutPageBg.jpg")` }}> */}
            <article
              className="pageHdrBlock wrapper py-80"
              style={{ backgroundImage: `url(${"/images/aboutPageBg.jpg"})` }}
            >
              <div className="pageHdrOverlay"></div>
              <div className="container">
                <div className="pageHdrText blogDtlTop">
                  <div className="blogPstd">
                    Posted:{" "}
                    {moment(this.state.blogData?.creationDate).format(
                      "MMM D, YYYY"
                    )}
                  </div>
                  <br />
                  {/* {this.state.blogData?.prev_doc ? (
                    <button style={{backgroundColor:"#29abff",color:"white" , borderRadius:"5px"}}> 
                        <a href="javascript:void(0)" style={{color : "white"}} onClick={()=> this.getBlog(this.state.blogData?.prev_doc)}>Prev</a></button>
                      )
                        :(
                          <button disabled style={{backgroundColor:"rgb(136 158 173)",color:"white" , borderRadius:"5px"}}>
                        Prev</button>)
                      } */}
                  <h3>{this.state.blogData?.name}</h3>
                  {/* {this.state.blogData?.next_doc ? (
                        <button style={{backgroundColor:"#29abff",color:"white" , borderRadius:"5px"}}>
                        <a href="javascript:void(0)" style={{color : "white"}}  onClick={()=> this.getBlog(this.state.blogData?.next_doc)}>Next</a></button>
                      )
                        :
                        <button disabled style={{backgroundColor:"rgb(136 158 173)",color:"white" , borderRadius:"5px"}}>
                        Next</button>
                      } */}
                  <div className="blogDtlFeau">
                    <h5>
                      <span>
                        <svg
                          width="22"
                          height="22"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3 16.5L12 21.75L21 16.5"
                            stroke="currentColor"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M3 12L12 17.25L21 12"
                            stroke="currentColor"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                          <path
                            d="M3 7.5L12 12.75L21 7.5L12 2.25L3 7.5Z"
                            stroke="currentColor"
                            stroke-width="1.6"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></path>
                        </svg>
                        &nbsp;
                        {this.state.blogData?.category?.name}
                      </span>
                      {/* <span className="ms-3">
                        <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M3.19421 12.4454C2.35557 11.0316 2.06187 9.36037 2.36826 7.74539C2.67466 6.13042 3.56006 4.68285 4.85823 3.6745C6.15639 2.66615 7.77801 2.16638 9.41858 2.26904C11.0592 2.3717 12.6058 3.06973 13.7681 4.23206C14.9305 5.39438 15.6285 6.94104 15.7312 8.58161C15.8339 10.2222 15.3341 11.8438 14.3257 13.142C13.3174 14.4401 11.8698 15.3256 10.2549 15.632C8.63989 15.9384 6.96862 15.6447 5.55486 14.806L5.55488 14.806L3.22356 15.4721C3.12711 15.4996 3.02504 15.5009 2.92793 15.4757C2.83082 15.4506 2.74221 15.3999 2.67128 15.3289C2.60034 15.258 2.54967 15.1694 2.52451 15.0723C2.49935 14.9752 2.50061 14.8731 2.52817 14.7767L3.19426 12.4453L3.19421 12.4454Z" stroke="currentColor" stroke-width="1.3" stroke-linecap="round" stroke-linejoin="round"></path>
                          <path d="M9.19375 9C9.19375 9.10701 9.10701 9.19375 9 9.19375C8.89299 9.19375 8.80625 9.10701 8.80625 9C8.80625 8.89299 8.89299 8.80625 9 8.80625C9.10701 8.80625 9.19375 8.89299 9.19375 9Z" fill="currentColor" stroke="currentColor" stroke-width="1.3"></path>
                          <path d="M5.625 9.84375C6.09099 9.84375 6.46875 9.46599 6.46875 9C6.46875 8.53401 6.09099 8.15625 5.625 8.15625C5.15901 8.15625 4.78125 8.53401 4.78125 9C4.78125 9.46599 5.15901 9.84375 5.625 9.84375Z" fill="currentColor"></path>
                          <path d="M12.375 9.84375C12.841 9.84375 13.2188 9.46599 13.2188 9C13.2188 8.53401 12.841 8.15625 12.375 8.15625C11.909 8.15625 11.5312 8.53401 11.5312 9C11.5312 9.46599 11.909 9.84375 12.375 9.84375Z" fill="currentColor"></path>
                        </svg>&nbsp;
                        {this.state.blogData?.comments?.length}
                      </span> */}
                    </h5>
                  </div>
                  <div className="lstt-wsh-btns">
                    <div
                      className="viewPrptyText"
                      title="9 Viewed this property"
                    >
                      <h4>
                        <i className="fa fa-eye"></i> {this.state.views}
                      </h4>
                    </div>
                    {/* <a href="javascript:void(0);" onClick={() => $("#sharepop").toggle()} > 
                      <img src="/images/share-icon-white.svg" title="Share" />
                    </a>
                    <div id="sharepop" style={{ display: "none" }}>
                  <FacebookShareButton
                                    url={this.state.weburl}

                                    quote={
                                      "Rentdigi - this.state.propertyData[0].address"
                                    }
                                    hashtag="#Rentdigi"
                                  >
                                    <FacebookIcon size={32} round={true} />
                                  </FacebookShareButton>
                                  <LinkedinShareButton
                                    url={this.state.weburl}
                                    title="Linkedin"
                                    quote={
                                      "Rentdigi - this.state.propertyData[0].address"
                                    }
                                    hashtag="#Rentdigi"
                                  >
                                    <LinkedinIcon size={32} round={true} />
                                  </LinkedinShareButton>
                                  <TwitterShareButton
                                    url={this.state.weburl}
                                    title="Twitter"
                                    quote={
                                      "Rentdigi - this.state.propertyData[0].address"
                                    }
                                    hashtag="#Rentdigi"
                                  >
                                    <TwitterIcon size={32} round={true} />
                                  </TwitterShareButton>

                                  <WhatsappShareButton
                                    url={this.state.weburl}
                                    title="Whatsapp"
                                    quote="Rentdigi - this.state.propertyData[0].address"
                                 
                                    hashtag="#Rentdigi"
                                  >
                                    <WhatsappIcon size={32} round={true} />
                                  </WhatsappShareButton>
                                  <CopyToClipboard text={this.state.weburl}
                                    onCopy={() => this.copyFunction()}
                      >
                        {
                          this.state.copied ? <span style={{ color: '#d3eef9' }}>Copied.</span> :
                       <a href="javascript:void(0);">
                      <img src="/images/copy-icon.png" className="copyIcon" title="Copy" />
                     </a>}
                    </CopyToClipboard>
                    
                    </div> */}
                  </div>
                </div>
              </div>
            </article>
            <article className="blogDtlBlock wrapper pt-80">
              <div className="container">
                <div className="row justify-content-left">
                  <div className="col">
                    <div className="blogSldrOuter crslCntrls">
                      <Swiper
                        modules={[Navigation, Pagination, Scrollbar, A11y]}
                        spaceBetween={30}
                        slidesPerView={1}
                        allowTouchMove={false}
                        allowSlideNext={false}
                        allowSlidePrev={false}
                        breakpoints={{
                          320: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                          },
                          576: {
                            slidesPerView: 1,
                            spaceBetween: 20,
                          },
                          768: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                          },
                          992: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                          },
                          1200: {
                            slidesPerView: 1,
                            spaceBetween: 30,
                          },
                        }}
                      >
                        {true ? (
                          this.state.blogData?.images?.map((img, index) => (
                            <SwiperSlide key={index}>
                              {/* <div
                                className="blogSldrMedia"
                                style={{
                                  backgroundImage: `url(${"/images/novaVilla.jpg"})`,
                                }}
                              ></div> */}
                              <div
                                className="blogSldrMedia"
                                style={{
                                  backgroundImage: `url(${
                                    config.cdn_backend_URL + "/" + img
                                  })`,
                                }}
                              ></div>
                            </SwiperSlide>
                          ))
                        ) : (
                          <>
                            <SwiperSlide>
                              <div
                                className="blogSldrMedia"
                                style={{
                                  backgroundImage: `url(${"/images/novaVilla.jpg"})`,
                                }}
                              ></div>
                            </SwiperSlide>
                          </>
                        )}
                      </Swiper>
                    </div>
                    <div className="postOptns my-3">
                      <ul className="d-flex align-items-center gap-3">
                        <li>
                          <span className="d-flex align-items-center gap-1">
                            <i>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 50 50"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  opacity="0.2"
                                  d="M10.9375 7.8136H39.0625C39.4769 7.8136 39.8743 7.97822 40.1674 8.27124C40.4604 8.56427 40.625 8.9617 40.625 9.3761V39.0636C40.625 40.3068 40.1311 41.4991 39.2521 42.3782C38.373 43.2572 37.1807 43.7511 35.9375 43.7511H14.0625C12.8193 43.7511 11.627 43.2572 10.7479 42.3782C9.86886 41.4991 9.375 40.3068 9.375 39.0636V9.3761C9.375 8.9617 9.53962 8.56427 9.83265 8.27124C10.1257 7.97822 10.5231 7.8136 10.9375 7.8136Z"
                                  fill="currentColor"
                                ></path>
                                <path
                                  d="M18.75 25.0012H31.25"
                                  stroke="currentColor"
                                  stroke-width="2.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M18.75 31.2512H31.25"
                                  stroke="currentColor"
                                  stroke-width="2.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M10.9375 7.8136H39.0625C39.4769 7.8136 39.8743 7.97822 40.1674 8.27124C40.4604 8.56427 40.625 8.9617 40.625 9.3761V39.0636C40.625 40.3068 40.1311 41.4991 39.2521 42.3782C38.373 43.2572 37.1807 43.7511 35.9375 43.7511H14.0625C12.8193 43.7511 11.627 43.2572 10.7479 42.3782C9.86886 41.4991 9.375 40.3068 9.375 39.0636V9.3761C9.375 8.9617 9.53962 8.56427 9.83265 8.27124C10.1257 7.97822 10.5231 7.8136 10.9375 7.8136Z"
                                  stroke="currentColor"
                                  stroke-width="2.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M15.625 4.68872V10.9387"
                                  stroke="currentColor"
                                  stroke-width="2.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M25 4.68872V10.9387"
                                  stroke="currentColor"
                                  stroke-width="2.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M34.375 4.68872V10.9387"
                                  stroke="currentColor"
                                  stroke-width="2.5"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </i>
                            {moment(this.state.blogData?.creationDate).format(
                              "YYYY-MM-DD"
                            )}
                          </span>
                        </li>
                        <li>
                          <span className="d-flex align-items-center gap-1 text-capitalize">
                            <i>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3 16.5L12 21.75L21 16.5"
                                  stroke="currentColor"
                                  stroke-width="1.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M3 12L12 17.25L21 12"
                                  stroke="currentColor"
                                  stroke-width="1.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                                <path
                                  d="M3 7.5L12 12.75L21 7.5L12 2.25L3 7.5Z"
                                  stroke="currentColor"
                                  stroke-width="1.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </i>{" "}
                            {this.state.blogData?.category?.name}
                          </span>
                        </li>
                        <li>
                          <span className="d-flex align-items-center gap-1 text-capitalize">
                            <i>
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 32 32"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M16 28C22.6274 28 28 22.6274 28 16C28 9.37258 22.6274 4 16 4C9.37258 4 4 9.37258 4 16C4 22.6274 9.37258 28 16 28Z"
                                  stroke="currentColor"
                                  stroke-width="1.6"
                                  stroke-miterlimit="10"
                                ></path>
                                <path
                                  d="M16 20C18.7614 20 21 17.7614 21 15C21 12.2386 18.7614 10 16 10C13.2386 10 11 12.2386 11 15C11 17.7614 13.2386 20 16 20Z"
                                  stroke="currentColor"
                                  stroke-width="1.6"
                                  stroke-miterlimit="10"
                                ></path>
                                <path
                                  d="M7.9751 24.9218C8.72836 23.4408 9.87675 22.1971 11.2931 21.3284C12.7095 20.4598 14.3387 20 16.0002 20C17.6617 20 19.2909 20.4598 20.7073 21.3284C22.1237 22.1971 23.272 23.4407 24.0253 24.9217"
                                  stroke="currentColor"
                                  stroke-width="1.6"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                ></path>
                              </svg>
                            </i>{" "}
                            {this.state.blogData?.author?.length > 0
                              ? this.state.blogData?.author
                                  ?.map((v) => v.name)
                                  .join(" , ")
                              : "Admin"}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="blogDtlDes mt-4">
                      {/* <div className="mainHeading headingSmall">
                        <h2>Blog Description</h2>
                      </div> */}
                      <div
                        className="propertyDtlParah"
                        dangerouslySetInnerHTML={{
                          __html: this.state.blogData?.description,
                        }}
                      ></div>
                    </div>
                    <div className="shrbtn">
                      <span>SHARE THIS ARTICLE:</span>
                      <div id="sharepop">
                        <FacebookShareButton
                          url={this.state.weburl}
                          quote={
                            "Rentdigi - this.state.propertyData[0].address"
                          }
                          hashtag="#Rentdigi"
                        >
                          <FacebookIcon size={32} round={true} />
                        </FacebookShareButton>
                        <LinkedinShareButton
                          url={this.state.weburl}
                          title="Linkedin"
                          quote={
                            "Rentdigi - this.state.propertyData[0].address"
                          }
                          hashtag="#Rentdigi"
                        >
                          <LinkedinIcon size={32} round={true} />
                        </LinkedinShareButton>
                        <TwitterShareButton
                          url={this.state.weburl}
                          title="Twitter"
                          quote={
                            "Rentdigi - this.state.propertyData[0].address"
                          }
                          hashtag="#Rentdigi"
                        >
                          <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>

                        <WhatsappShareButton
                          url={this.state.weburl}
                          title="Whatsapp"
                          quote="Rentdigi - this.state.propertyData[0].address"
                          hashtag="#Rentdigi"
                        >
                          <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        <CopyToClipboard
                          text={this.state.weburl}
                          onCopy={() => this.copyFunction()}
                        >
                          {this.state.copied ? (
                            <span style={{ color: "#d3eef9" }}>Copied.</span>
                          ) : (
                            <a href="javascript:void(0);">
                              <img
                                src="/images/copy-icon.png"
                                className="copyIcon"
                                title="Copy"
                              />
                            </a>
                          )}
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div
                      className="blogSidebar-nextPrev mb-1 d-flex justify-content-between align-items-center"
                      style={{ width: "100%", marginTop: "40px" }}
                    >
                      {!this.state.blogData?.prev_doc ? (
                        <div className="d-none d-md-block">
                          <button className="blogSidebar-nextPrev1" disabled>
                            <span className="d-none d-sm-block">Previous</span>
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className="d-none d-md-block">
                            <button className="blogSidebar-nextPrev1">
                              <a
                                href="javascript:void(0)"
                                onClick={() =>
                                  this.getBlog(
                                    this.state.blogData?.prev_doc?.slug
                                  )
                                }
                              >
                                <Link
                                  to={{
                                    pathname: `/${this.state.blogData?.category?.name
                                      ?.toLowerCase()
                                      ?.replaceAll(" ", "-")}/${
                                      this.state.blogData?.prev_doc?.slug
                                    }`,
                                  }}
                                >
                                  <i
                                    class="fa fa-chevron-left"
                                    aria-hidden="true"
                                  ></i>
                                  <span>Previous</span>
                                  <h3 className="d-none d-lg-block mt-1 mb-0">
                                    {" "}
                                    {this.state.blogData?.prev_doc?.name}
                                  </h3>
                                </Link>
                              </a>
                            </button>
                          </div>
                          <button className="blogSidebar-nextPrev1 blogSidebar-nextPrev-mob d-block d-md-none">
                            <a
                              href="javascript:void(0)"
                              onClick={() =>
                                this.getBlog(
                                  this.state.blogData?.prev_doc?.slug
                                )
                              }
                            >
                              <Link
                                to={{
                                  pathname: `/${this.state.blogData?.category?.name
                                    ?.toLowerCase()
                                    ?.replaceAll(" ", "-")}/${
                                    this.state.blogData?.prev_doc?.slug
                                  }`,
                                }}
                              >
                                <i
                                  class="fa fa-chevron-left"
                                  aria-hidden="true"
                                ></i>
                                <span>Previous</span>
                              </Link>
                            </a>
                          </button>
                        </>
                      )}

                      {!this.state.blogData?.next_doc ? (
                        <div className="d-none d-md-block">
                          <button
                            className="blogSidebar-nextPrev2"
                            disabled
                            style={{}}
                          >
                            <i
                              class="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                            <span className="d-none d-sm-block">Next</span>
                          </button>
                        </div>
                      ) : (
                        <>
                          <div className="d-none d-md-block">
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-end",
                              }}
                            >
                              <button className="blogSidebar-nextPrev2">
                                <a
                                  href="javascript:void(0)"
                                  onClick={() =>
                                    this.getBlog(
                                      this.state.blogData?.next_doc?.slug
                                    )
                                  }
                                >
                                  <Link
                                    to={{
                                      pathname: `/${this.state.blogData?.category?.name
                                        ?.toLowerCase()
                                        ?.replaceAll(" ", "-")}/${
                                        this.state.blogData?.next_doc?.slug
                                      }`,
                                    }}
                                  >
                                    <i
                                      class="fa fa-chevron-right"
                                      aria-hidden="true"
                                    ></i>
                                    <span>Next</span>
                                    <h3 className="d-none d-lg-block mt-1 mb-0">
                                      {" "}
                                      {this.state.blogData?.next_doc?.name}
                                    </h3>
                                  </Link>
                                </a>
                              </button>
                            </div>
                          </div>
                          <button className="blogSidebar-nextPrev2 blogSidebar-nextPrev-mob d-block d-md-none">
                            <a
                              href="javascript:void(0)"
                              style={{ color: "white" }}
                              onClick={() =>
                                this.getBlog(
                                  this.state.blogData?.next_doc?.slug
                                )
                              }
                            >
                              <Link
                                to={{
                                  pathname: `/${this.state.blogData?.category?.name
                                    ?.toLowerCase()
                                    ?.replaceAll(" ", "-")}/${
                                    this.state.blogData?.next_doc?.slug
                                  }`,
                                }}
                              >
                                <i
                                  class="fa fa-chevron-right"
                                  aria-hidden="true"
                                ></i>
                                <span>Next</span>
                              </Link>
                            </a>
                          </button>
                        </>
                      )}
                    </div>
                    {/*  <div className="blogCmntBlk mt-4">
                      <div className="mainHeading headingSmall">
                        <h2>Leave a comment</h2>
                      </div>
                      <form className="commentForm" id="addComment">
                        <div className="row g-4">
                          <div className="col-md-6">
                            <div>
                              <input type="text" className="form-control" placeholder="Your Name*" value=""/>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <input type="email" className="form-control" placeholder="Your E-mail*" value=""/>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="my-4">
                              <textarea className="form-control" placeholder="Your Comment*"></textarea>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-check">
                                <input type="checkbox" className="form-check-input" id="rememberCheck" />
                                <label className="form-check-label" for="rememberCheck">By using this form you agree with the storage and handling of your data by this website.*</label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-btn mt-4">
                              <button className="btn btnCommon" type="Submit">Submit</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="blogCmntListBlk mt-5">
                      <div class="mainHeading headingSmall">
                        <h2>4 Comments</h2>
                      </div>
                      <div className="blogCmntList">
                        <div className="row g-4">
                          <div className="col-12">
                            <div className="blogCmntCol d-flex align-items-center gap-3">
                              <div className="cmntUsrImg">
                                <img src="images/dummy.jpg" alt="User Image" />
                              </div>
                              <div className="cmntUsrInfo">
                                <h4>Deepak Taneja</h4>
                                <h5><a href="mailto:deepak.duple@gmail.com">deepak.duple@gmail.com</a></h5>
                                <p>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo.</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="blogCmntCol d-flex align-items-center gap-3">
                              <div className="cmntUsrImg">
                                <img src="images/dummy.jpg" alt="User Image" />
                              </div>
                              <div className="cmntUsrInfo">
                                <h4>Deepak Taneja</h4>
                                <h5><a href="mailto:deepak.duple@gmail.com">deepak.duple@gmail.com</a></h5>
                                <p>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo.</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="blogCmntCol d-flex align-items-center gap-3">
                              <div className="cmntUsrImg">
                                <img src="images/dummy.jpg" alt="User Image" />
                              </div>
                              <div className="cmntUsrInfo">
                                <h4>Deepak Taneja</h4>
                                <h5><a href="mailto:deepak.duple@gmail.com">deepak.duple@gmail.com</a></h5>
                                <p>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo.</p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="blogCmntCol d-flex align-items-center gap-3">
                              <div className="cmntUsrImg">
                                <img src="images/dummy.jpg" alt="User Image" />
                              </div>
                              <div className="cmntUsrInfo">
                                <h4>Deepak Taneja</h4>
                                <h5><a href="mailto:deepak.duple@gmail.com">deepak.duple@gmail.com</a></h5>
                                <p>Sed ut perspiciatis, unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa, quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt, explicabo.</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="col-md-4">
                    <div className="blogSidebar">
                      <div className="bloglatest ">
                        <div className="blogheading">
                          <h3>Latest Articles</h3>
                        </div>
                        <div className="p-2">
                          {this.state.blog_list.map((item, index) => {
                            return (
                              <>
                                <a
                                  href="javascript:void(0)"
                                  onClick={() => this.getBlog(item.slug)}
                                >
                                  <Link
                                    to={{
                                      pathname: "gskblog"+`/${item.category?.name
                                        ?.replaceAll(" ", "-")
                                        .toLowerCase()}/${item.slug}`,
                                    }}
                                  >
                                    <div className="blogcard mb-2">
                                      <div className="blogcardimg">
                                        <img
                                          height={100}
                                          width={100}
                                          src={
                                            item.images.length > 0
                                              ? config.backend_URL +
                                                "/" +
                                                item.images[0]
                                              : "/images/amenities1.jpg"
                                          }
                                        />
                                      </div>
                                      <div
                                        className="sdbrPostDes"
                                        style={{ width: "70%", padding: "8px" }}
                                      >
                                        <h5>
                                          {" "}
                                          <i>
                                            <svg
                                              width="18"
                                              height="18"
                                              viewBox="0 0 24 24"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M3 16.5L12 21.75L21 16.5"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M3 12L12 17.25L21 12"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                              <path
                                                d="M3 7.5L12 12.75L21 7.5L12 2.25L3 7.5Z"
                                                stroke="currentColor"
                                                stroke-width="1.6"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                              ></path>
                                            </svg>
                                          </i>{" "}
                                          {item.category?.name}
                                        </h5>
                                        <h4>{item.name}</h4>
                                      </div>
                                    </div>
                                  </Link>
                                </a>
                              </>
                            );
                          })}
                        </div>
                      </div>

                      <div className="bloglatest">
                        <div className="blogheading">
                          <h3>Category</h3>
                        </div>
                        <div className="px-3 py-2">
                          {this.state.category?.map((item, index) => (
                            <div className="blogcate">
                              <Link
                                className="d-flex"
                                to={{
                                  pathname: `/${item?.name
                                    ?.replaceAll(" ", "-")
                                    .toLowerCase()}`,
                                  state: { category: item.name },
                                }}
                              >
                                <i
                                  class="fa fa-hand-o-right"
                                  aria-hidden="true"
                                ></i>
                                <h5 className="my-0 p-0 ml-2">{item?.name}</h5>
                              </Link>
                            </div>
                          ))}
                        </div>
                      </div>

                      <div className="bloglatest">
                        <div className="blogheading">
                          <h3>Keywords</h3>
                        </div>

                        <div className="blogcate">
                          <div className="blogkeyword px-3">
                            <ul>
                              {" "}
                              {this.state.blog_keyword &&
                                this.state.blog_keyword.map((item) => {
                                  return <li>{item}</li>;
                                })}
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="bloglatest">
                        <div className="blogheading">
                          <h3>Subscribe</h3>
                        </div>

                        <div className="sdbrWdgtContent sdbrSearchCol p-2">
                          <form
                            className="sdbrSearchForm"
                            onSubmit={this.addSubscriber}
                          >
                            <div className="sdrbrSrchInput">
                              <input
                                type="text"
                                name="email"
                                className="form-control"
                                id="text"
                                value={this.state.email}
                                placeholder="Enter your email..."
                                onChange={(e) => {
                                  this.setState({ email: e.target.value });
                                }}
                              />
                              <button type="submit" className="search-submit">
                                <i
                                  class="fa fa-paper-plane"
                                  aria-hidden="true"
                                ></i>
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
              </div>
            </article>
          </section>
        </div>
        <FooterFront />
      </>
    );
  }
}

export default BlogDetail;
