import jwt from "jsonwebtoken";
import React from "react";
import ReactDOM from "react-dom";
import config from "./config.js";
import "./index.css";

import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import {
  Admin,
  Adminlogin,
  ForgotPassword,
  Front,
  ResetPassword,
  TwoFa,
} from "./components";
import ScrollToTop from "./components/ScrollToTop.js";

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.length > 0 ? (
        jwt.verify(
          localStorage.getItem("id"),
          config.login_secret.key,
          (err, decoded) => {
            if (err) {
              localStorage.clear();
              console.log("props", props);
              props.history.push("/login");
            } else {
              return decoded;
            }
          }
        ).email !== null ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);
//to check for login and signup
const Private = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      localStorage.length > 0 ? (
        jwt.verify(
          localStorage.getItem("id"),
          config.login_secret.key,
          (err, decoded) => {
            if (err) {
              localStorage.clear();
              props.history.push("/login");
            } else {
              return decoded;
            }
          }
        ).email !== null || undefined ? (
          <Redirect to="/admin/TwoFa" />
        ) : (
          <Component {...props} />
        )
      ) : (
        <Component {...props} />
      )
    }
  />
);

ReactDOM.render(
  <Router>
    <ScrollToTop />
    <Switch>
      {/* Admin routes */}
      <Private exact path="/login" component={Adminlogin} />
      <PrivateRoute path="/admin" component={Admin} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password" component={ResetPassword} />
      <Route path="/2fa" component={TwoFa} />
      <Route path="/" component={Front} />
    </Switch>
  </Router>,

  document.getElementById("root")
);
