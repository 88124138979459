import React, { useEffect, useState } from "react";
import HeaderLanding from "./HeaderLanding";
import { Link, NavLink, withRouter } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectFlip,
} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from "react-bootstrap";
import axios from "axios";
import config from "../config";
import helper from "../helper";
import Loader from "../loader";

export default function Landing() {
  
 
  const [properties, setProperties] = useState([]);
  const [value, setvalue] = useState("");
  const [newHeading, setNewHeading] = useState("");
  const [newText, setNewText] = useState("");
  const [region, setRegion] = useState("All");
  const [vendors, setVendors] = useState([]);
  const [image, setImage] = useState("");
  const [regions, setRegions] = useState([]);
  const [retailPartner, setRetailPartner] = useState(true);
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeAllIndex, setActiveAllIndex] = useState(true);
  const [loader_flag, setLoader_flag] = useState(false);

  useEffect(() => {
    getVendors('All');
    
  }, []);
  const searchAppartments = async (e) => {
    try {
      if (e.target.value) {
        const response = await axios.get(
          `${config.backend_URL}/admin/getSearchProperties`,
          {
            params: {
              text: e.target.value,
            },
          }
        );
        setProperties(response.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLandingContent = async () => {
    setLoader_flag(true)
    try {
      let response = await axios.get(
        `${config.backend_URL}/admin/updateLandingContent`
      );
      console.log(response.data);
      setvalue(response.data.data.heading);
      setImage(`${config.backend_URL}/${response.data.data.image}`);
      setNewText(response.data.data.text);
      setLoader_flag(false)
    } catch (error) {
      console.log(error);
      setLoader_flag(false)
    }
  };

  const TabHandleClick = (i) => {
    setActiveIndex(i);
    setActiveAllIndex(false)
  };
  const getVendors = (params) => {
    setRetailPartner(true);
    if(!params === 'All'){
      setActiveAllIndex(false);

    }else{
      setActiveAllIndex(true);
    }
    try {
      // const reg = regions.find((reg) => reg.region === region);
      // let params = reg?._id || region;
      axios
        .get(`${config.backend_URL}/admin/getVendors/${params}`)
        .then((res) => {
          setVendors(res.data.data);
          console.log(res.data.data);
          setRetailPartner(false);
        });
    } catch (error) {
      console.log(error);
      setRetailPartner(false);
    }
  };
 const handleSlideChange=(swiper)=>{
  const activeSlideIndex = swiper.activeIndex;
  // console.log(activeSlideIndex,'kkk',regions)
  if(activeSlideIndex == 0){
    getVendors("All")
  }else{
    let data = regions[activeSlideIndex-1]
    // console.log(data?._id)
    getVendors(data?._id)
  }
  
 }


  // Meta Tags Set Sart
  const getMetaTags = (page_name) => {
    axios
      .post(`${config.backend_URL}/admin/getMetaTags`, { page_name: page_name })
      .then((responseJson) => {
        //set Meta Tags
        console.log(responseJson);
        helper.setMetaTitle(responseJson.data.data.meta_title);
        helper.setMetaDesc(responseJson.data.data.meta_desc);
        helper.setMetaKeyword(responseJson.data.data.meta_keyword);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getRegion = () => {
    axios
      .get(`${config.backend_URL}/admin/getRegion`)
      .then((responseJson) => {
        console.log(responseJson.data.data);
        // return
        setRegions(responseJson.data.data);
        setLoader_flag(false);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  // function Loader(){
  //   setLoader_flag(true)
  // }
  useEffect(() => {
    getRegion();
    getMetaTags("gsk");
  }, []);



  useEffect(() => {
    getLandingContent();
  }, []);

 
  return (
    <>
      {loader_flag ? (
        <>
        
          <div
            className="d-flex align-items-center justify-content-center position-relative"
            style={{ height: '100vh', width: '100%'}}
          >
            <div style={{ zIndex: "9999" }}>
              <Loader />
            </div>
            <div
              style={{
                backgroundColor: "rgb(41 171 255)",
                position: "fixed",
                left: "0",
                right: "0",
                bottom: "0",
                top: "0",
                zIndex: "-1",
                height: '100%',
                width: '100%',
              }}
            ></div>
          </div>
        </>
      ) : (
        <div className="landingPage">
          <link
            href="/css/landing.css"
            rel="stylesheet"
            crossorigin="anonymous"
          />
          <HeaderLanding />

          <div
            style={
              {
                // background: `url(${image}) center center/cover`
              }
            }
            className="heroImg text-center"
          >
            <div className="container">
              <div className="heroImg-text">
                {/* <h1 className="heroImg-title mb-4">
                <span className="d-block">
                  Your <i className="colorPrimary ">Comfort</i> Is
                </span>{" "}
                Our Priority!!!
              </h1> */}
                <h1 className="heroImg-title mb-4">
                  <span
                    className="d-block"
                    dangerouslySetInnerHTML={{
                      __html: value.replace(/\n/g, "<br>"),
                    }}
                  />
                </h1>
                <p>{newText}</p>
              </div>
              {/* <div className="heroImg-search mt-5">
              <input
                type="text"
                placeholder="Search Apartments..."
                onChange={(e) => searchAppartments(e)}
              />
            </div> */}
              {/* <div className="mt-4 heroImg-searchBottom">
              <ul className="m-0 p-0">
                {
                  properties.map((p) => (
                    <li>
                      <a href={`alberta/${p.cityInfo.slug}/${p.slug}`}>{p.property_name }</a>
                    </li>
                  ))
                }
              </ul>
            </div> */}
            </div>
          </div>

          {/* partners */}
          <section className="landingPartners py-5">
            <div className="container">
              <div className="landingPartners-tabs my-5">
              {/* desktop start */}
                <Swiper
                  // loop={true}
                  className="onlyTabs d-none d-md-block"
                  navigation={true}
                  slidesPerView={4}
                  // spaceBetween={40}
                  breakpoints={{
                    768: { slidesPerView: 4 },
                    0: { slidesPerView: 1 },
                  }}
                >
                  <SwiperSlide className={activeAllIndex ? "active" : ""}>
                    <div className="row">
                      <div
                        className="col sliderTabText "
                        onClick={() => getVendors("All")}
                      >
                        All
                      </div>
                    </div>
                  </SwiperSlide>
                  {regions.map((reg, i) => (
                    // <SwiperSlide className={i === activeIndex ? 'active' : ''}  onClick={() => handleClick(i)}>
                    <SwiperSlide
                      className={
                        !activeAllIndex && i === activeIndex ? "active" : ""
                      }
                      onClick={() => TabHandleClick(i)}
                    >
                      <div className="row">
                        <div
                          className="col sliderTabText text-center"
                          onClick={() => getVendors(reg._id)}
                        >
                          {reg.region}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>

                {/* ===mobile slider start */}
                <Swiper
                  // loop={true}
                  className="onlyTabs d-block d-md-none"
                  navigation={true}
                  slidesPerView={4}
                  // spaceBetween={40}
                  onSlideChange={(swiper) => handleSlideChange(swiper)}
                  breakpoints={{
                    768: { slidesPerView: 4 },
                    0: { slidesPerView: 1 },
                  }}
                >
                  <SwiperSlide className={activeAllIndex ? "active" : ""}>
                    <div className="row">
                      <div
                        className="col sliderTabText"
                        onClick={() => getVendors("All")}
                      >
                        All
                      </div>
                    </div>
                  </SwiperSlide>
                  {regions.map((reg, i) => (
                    // <SwiperSlide className={i === activeIndex ? 'active' : ''}  onClick={() => handleClick(i)}>
                    <SwiperSlide
                      className={
                        !activeAllIndex && i === activeIndex ? "active" : ""
                      }
                      onClick={() => TabHandleClick(i)}
                    >
                      <div className="row">
                        <div
                          className="col sliderTabText text-center"
                          onClick={() => getVendors(reg._id)}
                        >
                          {reg.region}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
                {/* loop start for tabs */}

                {console.log(regions, "regions")}
                {retailPartner ? (
                  <>
                    {" "}
                    <div
                      className="d-flex align-items-center justify-content-center position-relative"
                      style={{ height: "500px" }}
                    >
                      <div style={{ zIndex: "9999" }}>
                        <Loader />
                      </div>
                      <div
                        style={{
                          backgroundColor: "rgb(41 171 255)",
                          position: "absolute",
                          left: "0",
                          right: "0",
                          bottom: "0",
                          top: "0",
                          zIndex: "-1",
                        }}
                      ></div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="m-2 p-5 pt-4" style={{ display: "none@" }}>
                      <div className="row">
                        <div className="col mb-2">
                          {vendors.length > 0 ? (
                            <h2>Our Retail Partners</h2>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <Swiper
                        loop={true}
                        autoplay={{
                          delay: 2000,
                          disableOnInteraction: false,
                          pauseOnMouseEnter: true,
                        }}
                        slidesPerView={4}
                        spaceBetween={40}
                        breakpoints={{
                          768: { slidesPerView: 4 },
                          0: { slidesPerView: 3 },
                        }}
                        on={{
                          slideChange: () => console.log("slide changed"),
                          progress: (s, progress) =>
                            console.log(`progress is ${progress}`),
                        }}
                      >
                        {vendors.length > 0 ? (
                          vendors?.map((vendor, i) => (
                            <SwiperSlide key={i}>
                              <div className="landingPartners-card">
                                <div className="landingPartners-cardLeft">
                                  <img
                                    src={
                                      config.backend_URL + "/" + vendor?.logo
                                    }
                                  />
                                </div>
                                <div className="landingPartners-cardRight">
                                  <h4>{vendor?.description}</h4>
                                  {vendor?.buttonText && (
                                    // <Button className="btn bg-white colorPrimary comnButton mt-4" onClick={() => window.href = `${vendor.buttonLink}`}>
                                    //   {vendor.buttonText}
                                    // </Button>
                                    <a
                                      className="btn bg-white colorPrimary comnButton mt-4"
                                      target="_blank"
                                      href={vendor?.buttonLink}
                                    >
                                      {vendor?.buttonText}
                                    </a>
                                  )}
                                </div>
                              </div>
                            </SwiperSlide>
                          ))
                        ) : (
                          <>
                            <h3
                              style={{
                                color: "rgb(178, 225, 255)",
                                height: "430px",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              No Retail Partners!
                            </h3>
                          </>
                        )}
                      </Swiper>
                    </div>
                  </>
                )}
                {/* </Tab>
                  ))
                } */}
                {/* </Tabs> */}
              </div>
            </div>
          </section>

          <section className="todayRental text-center p-md-5 p-2">
            <div className="container">
              <div className="row">
                <div className="col">
                  <h4>Find Your Perfect Rental Today!</h4>
                  <p>
                    Explore our wide range of rental properties and find your
                    ideal home for rent in Alberta.
                  </p>
                  <div className="mt-5">
                    <a
                      href="/alberta"
                      target="_blank"
                      className="px-5 d-inline-block  btn comnButton bg-white colorPrimary"
                    >
                      View Properties
                    </a>
                    <a
                      href="/contact-us"
                      target="_blank"
                      className="px-5 d-inline-block  btn comnButton  getInTouch ms-md-3 mt-3 mt-md-0"
                    >
                      Get In Touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <section className="landingFooter text-center">
          <div className="container">
            <div className="row">
              <div className="col">
              
                  <img
                    src="https://www.gskproperties.ca:9000/file_1651815945633.png"
                    alt="logo"
                    className="landingFooterLogo"
                  />
            
              </div>
            </div>
            <div className="row">
              <div className="col">
                <ul class="navbar-nav flex-md-row mx-auto justify-content-center">
                  <li class="nav-item active">
                    <a class="nav-link" href="#">
                      Home <span class="sr-only"></span>
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" target="_blank" href="#">
                      {" "}
                      Choose Us
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" target="_blank" href="https://www.gskproperties.ca/alberta">
                      Properties
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" target="_blank" href="https://www.gskproperties.ca/blog">
                      Blog
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

        </section> */}
          <div className="landingFooter-copyright bgPrimary ">
            <p className="m-0">
              ©Copyrights 2023 - www.gskproperties.ca - All rights reserved
              {/* | <a href="" className="">Privacy policy</a>  and <a href="">Terms & Services</a> */}
            </p>
          </div>

          {/* ------ */}
          <style jsx scoped>
            {`
              #scriptFrame {
                display: none !important;
              }
            `}
          </style>
        </div>
      )}
    </>
  );
}
