import axios from "axios";
import moment from "moment";
import React from "react";
import Pagination from "react-js-pagination";
import Modal from "react-modal";
import { Link, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.js";
import Loader from "../../loader.js";

class BlogList extends React.Component {
  state = {
    loader_flag: false,
    modalIsOpen: false,
    blogList: [],
    totalCount: 0,
    activePage: 1,
    itemsCountPerPage: 10,
    pageRangeDisplayed: 5,
    startDate: undefined,
    endDate: undefined,
    name: undefined,
    status: undefined,
    blog_id: "",
  };

  initialState = {
    loader_flag: false,
    modalIsOpen: false,
    startDate: undefined,
    endDate: undefined,
    name: "",
    status: "",
    activePage: 1,
    itemsCountPerPage: 10,
  };

  openModal = (id = "") => {
    this.setState({ blog_id: id, modalIsOpen: true });
  };

  closeModal = async (e) => {
    this.setState({ modalIsOpen: false });
  };

  componentDidMount = async () => {
    this.getCateogryList(1);
  };

  clear = async (e) => {
    e.preventDefault();
    await this.setState(this.initialState);
    this.getCateogryList(1);
  };

  getCateogryList = async (pageNumber) => {
    // this.setState({ loader_flag: true });
    this.setState({ activePage: pageNumber });
    try {
      let uploadObj = {
        page: pageNumber,
        limit: this.state.itemsCountPerPage,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        name: this.state.name !== "" ? this.state.name : undefined,
        status: this.state.status !== "" ? this.state.status : undefined,
      };
      axios
        .post(`${config.backend_URL}/admin/blog/fetchBlog`, uploadObj)
        .then((res) => {
          console.log("res.data: ", res);
          if (res.status === 200) {
            console.log("res.data: ", res.data);
            this.setState({
              blogList: res.data?.result,
              totalCount: res.data?.count,
              itemsCountPerPage: uploadObj.limit,
            });
          } else {
            toast.error("Something went wrong");
          }
          // this.setState({ categoryList: res.data.data });
          // this.setState({ loader_flag: false });
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  updateCategory = async (e, id) => {
    e.preventDefault();
    try {
      let updateObj = {
        status: e.target.value == "true" ? true : false,
      };
      axios
        .post(`${config.backend_URL}/admin/blog/updateBlog/${id}`, updateObj)
        .then((res) => {
          console.log("res.data: ", res);
          if (res.status === 200) {
            console.log("res.data: ", res.data);
            this.getCateogryList(this.state.activePage);
            toast.success("Status updated successfully");
          } else {
            this.setState({ modalIsOpen: false });
            toast.error("Something went wrong");
          }
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  deleteCategory = async (e) => {
    e.preventDefault();
    // this.setState({ loader_flag: true });
    try {
      axios
        .post(
          `${config.backend_URL}/admin/blog/deleteBlog/${this.state.blog_id}`
        )
        .then((res) => {
          console.log("res.data: ", res);
          if (res.status === 200) {
            console.log("res.data: ", res.data);
            this.setState({ modalIsOpen: false });
            this.getCateogryList(1);
            toast.success("Category deleted successfully");
          } else {
            this.setState({ modalIsOpen: false });
            toast.error("Something went wrong");
          }
        });
    } catch (error) {
      toast.error("Something went wrong");
    }
  };

  handleDateChange = async (e) => {
    this.setState({ startDate: new Date(e.target.value).valueOf() });
  };

  render() {
    const customStyles = {
      content: {
        width: "30%",
        top: "50%",
        left: "55%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    let i = 1;
    return (
      <>
        <ToastContainer />
        {this.state.loader_flag ? (
          <><Loader /></>
        ) : (
          <>
            <div class="content-wrapper">
              <section class="content-header">
                <div class="container-fluid">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <h1>Blog / Blog List</h1>
                    </div>
                    <div class="col-sm-6">
                      <ol class="breadcrumb float-sm-right">
                        <Link
                          to="/admin/addBlog"
                          class="pull-right btn btn-success"
                        >
                          Add Blog
                        </Link>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section class="content">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title">Filter Properties</h3>
                        </div>
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            this.getCateogryList(1);
                          }}
                        >
                          <div class="card-body">
                            <div class="row">
                              <div class="form-group col-lg col-md-3 col-sm-6">
                                <label>To</label>
                                <input
                                  class="form-control"
                                  type="date"
                                  placeholder="Search Blog Category"
                                  onChange={(e) =>
                                    this.setState({
                                      startDate:
                                        new Date(e.target.value).valueOf() /
                                        1000,
                                    })
                                  }
                                />
                              </div>
                              <div class="form-group col-lg col-md-3 col-sm-6">
                                <label>From</label>
                                <input
                                  class="form-control"
                                  type="date"
                                  placeholder="Search Blog Category"
                                  onChange={(e) =>
                                    this.setState({
                                      endDate:
                                        new Date(e.target.value).valueOf() /
                                        1000,
                                    })
                                  }
                                />
                              </div>
                              <div class="form-group col-lg col-md-3 col-sm-6">
                                <label>Search</label>
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Search Blog Category"
                                  value={this.state.name}
                                  onChange={(e) =>
                                    this.setState({ name: e.target.value })
                                  }
                                />
                              </div>
                              <div class="form-group col-lg col-md-3 col-sm-6">
                                <label>Status</label>
                                <select
                                  class="form-select"
                                  value={this.state.status}
                                  onChange={(e) =>
                                    this.setState({
                                      status:
                                        e.target.value == "true" ||
                                        e.target.value == "false"
                                          ? e.target.value == "true"
                                            ? true
                                            : false
                                          : undefined,
                                    })
                                  }
                                >
                                  <option value={undefined}>Select</option>
                                  <option value="true">Active</option>
                                  <option value="false">In-Active</option>
                                </select>
                              </div>
                              <div class="form-group col-lg col-md-3 col-sm-6">
                                <label>Type</label>
                                <select
                                  class="form-select"
                                  value={this.state.itemsCountPerPage}
                                  onChange={(e) =>
                                    this.setState({
                                      itemsCountPerPage: Number(e.target.value),
                                    })
                                  }
                                >
                                  <option value={undefined}>Select</option>
                                  <option value={10}>10</option>
                                  <option value={25}>25</option>
                                  <option value={50}>50</option>
                                  <option value={100}>100</option>
                                </select>
                              </div>
                              <div class="form-group col-lg col-md-3 col-sm-6">
                                <label>&nbsp;</label>
                                <button type="submit" class="btn btn-primary">
                                  Submit
                                </button>
                                <button
                                  className="btn btn-success mx-2"
                                  onClick={this.clear}
                                >
                                  Clear
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section class="content">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title">Blog List</h3>
                        </div>
                        <div class="card-body">
                          <table
                            id="exampl e1"
                            class="table table-bordered table-striped"
                          >
                            <thead>
                              <tr>
                                <th width="8%">Sr No.</th>
                                <th style={{ width: "30%" }}>Blog</th>
                                <th style={{ width: "20%" }}>Category</th>
                                <th style={{ width: "20%" }}>Date Created</th>
                                <th style={{ width: "10%" }}>Status</th>
                                <th style={{ width: "15%" }}>Action</th>
                              </tr>
                            </thead>
                            {this.state.blogList?.length > 0 ? (
                              this.state.blogList.map((item, index) => {
                                const category = item?.category?.name
                                  ?.toLowerCase()
                                  ?.replaceAll(" ", "-");
                                return (
                                  <tbody key={index}>
                                    <tr>
                                      <td>
                                        {++index == 10
                                          ? `${this.state.activePage}0`
                                          : `${
                                              this.state.activePage - 1
                                            }${i++}`}
                                      </td>
                                      <td>
                                        <div className="d-flex gap-2 justify-content-between">
                                          <div className=" w-25 avatar avatar-xl pull-up">
                                            <img
                                              src={
                                                item.images[0]
                                                  ? `${config.backend_URL}/${item.images[0]}`
                                                  : "../images/noImage.jpg"
                                              }
                                              width="72"
                                              height="72"
                                              alt="Blog Image"
                                            />
                                          </div>
                                          <div className="w-75 text-capitalize">
                                            <Link
                                              to={`/${category}/${item?.slug}`}
                                            >
                                              {item.name}
                                            </Link>

                                            <br />
                                            <div className="badge bg-secondary text-capitalize me-1">
                                              {item?.author?.length > 0
                                                ? item?.author
                                                    ?.map((v) => v?.name)
                                                    ?.join(", ")
                                                : "N/A"}
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        <span class="badge bg-primary">
                                          {item?.category?.name}
                                        </span>
                                      </td>
                                      <td>
                                        <span class="badge bg-secondary">
                                          {moment(item.creationDate).format(
                                            "YYYY-MM-DD"
                                          )}
                                        </span>
                                      </td>
                                      <td>
                                        <select
                                          class="form-select"
                                          value={item.status}
                                          onChange={(e) =>
                                            this.updateCategory(e, item._id)
                                          }
                                        >
                                          <option value={true}>Active</option>
                                          <option value={false}>
                                            Inactive
                                          </option>
                                        </select>
                                      </td>
                                      <td>
                                        <Link
                                          to={{
                                            pathname: "/admin/addBlog",
                                            state: {
                                              id: item._id,
                                              name: item.slug,
                                            },
                                          }}
                                          class="btn btn-success btn-sm"
                                        >
                                          <i class="fa fa-edit"></i>
                                        </Link>
                                        &nbsp;&nbsp;
                                        <button
                                          onClick={() => {
                                            this.openModal(item._id);
                                          }}
                                          class="btn btn-danger btn-sm"
                                        >
                                          <i class="fas fa-trash"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                );
                              })
                            ) : (
                              <tbody>
                                <tr>
                                  <td colSpan="6" className="text-center">
                                    No data found
                                  </td>
                                </tr>
                              </tbody>
                            )}
                          </table>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <Pagination
                          itemClass="page-item"
                          linkClass="page-link"
                          activePage={this.state.activePage}
                          itemsCountPerPage={this.state.itemsCountPerPage}
                          totalItemsCount={this.state.totalCount}
                          pageRangeDisplayed={this.state.pageRangeDisplayed}
                          onChange={this.getCateogryList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <Modal
              isOpen={this.state.modalIsOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Are you sure?"
              ariaHideApp={false}
            >
              {/* modal */}
              <div class="modal-header">
                <h3>Are you sure?</h3>
                <button
                  type="button"
                  class="btn-close"
                  onClick={this.closeModal}
                ></button>
              </div>
              <div class="modal-body">
                <p>Are you sure you want to delete this blog?</p>
                <button
                  type="submit"
                  class="btn btn-primary"
                  onClick={this.deleteCategory}
                >
                  Yes
                </button>
                <button
                  type="button"
                  class="btn btn-secondary ms-3"
                  onClick={this.closeModal}
                >
                  No
                </button>
              </div>
            </Modal>
          </>
        )}
      </>
    );
  }
}
export default withRouter(BlogList);
