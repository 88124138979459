import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import {
  About,
  BlogDetail,
  BlogList,
  Category,
  Contact,
  Home,
  Properties,
  PropertyDetail,
  SearchResult,
  Testimonials,
  WhyChooseUs,
  Landing,
} from "../components";
import NotFound from "../components/Notfound";
import Deletefiles from "./delete/App";
const axios = require("axios");
class Front extends React.Component {
  render() {
    return (
      <>
        <div class="wrapper">
          <Switch>
            <Route exact path={`/`} component={Home}></Route>
            <Route exact path={`/about-us`} component={About}></Route>
            <Route exact path={`/gsk`} component={Landing}></Route>
            <Route
              exact
              path={`/why-choose-us`}
              component={WhyChooseUs}
            ></Route>
            <Route exact path={`/alberta`} component={Properties}></Route>
            <Route exact path={`/alberta/:city`} component={Properties}></Route>
            <Route
              exact
              path={`/alberta/:city/:property`}
              component={PropertyDetail}
            ></Route>
            {/* <Route exact path={`/category`} component={Category}></Route> */}
            {/* <Route
              exact
              path={`/property-detail`}
              component={PropertyDetail}
            ></Route> */}
            <Route
              exact
              path={`/testimonials`}
              component={Testimonials}
            ></Route>
            {/* <Route
              exact
              path={`/operation`}
              component={Deletefiles}
            ></Route> */}
            {/* <Route exact path={`/blog`} component={BlogList}></Route>
            <Route exact path={`/blog/:id`} component={BlogDetail}></Route> */}
            <Route exact path={`/contact-us`} component={Contact}></Route>
            <Route
              exact
              path={`/SearchResult`}
              component={SearchResult}
            ></Route>
            <Route
              path="/gskblog/:category/:blog"
              exact
              component={BlogDetail}
            />
            <Route path="/gskblog/:category" component={BlogList} />
            <Route path={`/:property`} component={PropertyDetail} />
            <Route path="*" component={NotFound} />
          </Switch>
        </div>
      </>
    );
  }
}
export default withRouter(Front);
