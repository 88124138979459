import { React, Component } from "react";
import URLS from "./config"
export default class Loader extends Component {
  render() {
    return (
      <>
        <div className="loaderOuter">
          <i>
            <img
              src={`${URLS.cdn_backend_URL}/file_1651815945633.png`}
              alt="loader"
            />
          </i>
        </div>
      </>
    );
  }
}
