 import { Component } from "react";
import config from "../config.js";
import { withRouter, Link } from "react-router-dom";
import $ from "jquery";

class Filter_Property extends Component {
  state = {
    filterName: "2",
  };
  hitMap = (id) => {
    this.props.setPropertyId(id);

    $("div").removeClass("selected");
    var element1 = document.getElementById(id);
    element1.classList.add("selected");
  };
  viewPropertyDetail = (e) => {
    alert("sdfhsfdh");
    console.log("dgdfg", e.target.title);
    this.props.history.push(`/${e.target.title}`);
  };
  selectFilter = async (e) => {
    // console.log("val",e.target.value)
    await this.setState({ filterName: e.target.value });
    if (this.state.filterName === "3") {
      this.props.filterProperty({ timestamp: 1 });
    } else {
      this.props.filterProperty();
    }
  };
  render() {
    return (
      <>
        <div className="listingCntntCol">
          <div className="listingCntntHdngDiv d-md-flex align-items-center justify-content-between">
            <div className="mainHeading headingSmall m-0">
              <p className="m-0">
                <i className="d-inline-block pe-2">
                  <img src={config.cdn_URL + "/images/locIcon.png"} />
                </i>
                {this.props.data.length}{" "}
                {this.props.data.length > 1 ? "Properties" : "Property"} Found
              </p>
              <h2>Search Property</h2>
            </div>

            {this.props.data.length > 0 ? (
              <div class="listSortBy">
                <select
                  class="form-select listSortSelect"
                  value={this.state.filterName}
                  name="filterName"
                  aria-label="Default select example"
                  onChange={this.selectFilter}
                >
                  <option value="0">Select Here</option>
                  {/* <option value="1" selected="">Relevance</option> */}
                  <option value="2" selected>
                    Newest
                  </option>
                  <option value="3">Oldest</option>
                </select>
              </div>
            ) : (
              ""
            )}
          </div>
          <div id="listingCntntDiv" className="listingCntntListRow">
            <div className="row g-4">
              {this.props.data.length > 0 ? (
                this.props.data.map((x, key) => {
                  let imagePath1 = config.cdn_backend_URL + "/" + x.property_image;
                  let imagePath =
                    x.property_image == ""
                      ? `${config.cdn_backend_URL}/default.jpg`
                      : imagePath1;
                  return (
                    <div className="col-md-6" key={key}>
                      <div
                        className={`listPropertyItem ${
                          x.border ? "selected" : ""
                        }`}
                      >
                        <Link to={x.slug}>
                          <div class="prprtyBadge">View Detail</div> 
                          <div
                            className="listPropertyImg"
                            style={{ backgroundImage: `url(${imagePath})` }}
                          >
                            <div className="listPropertyOverlay"></div>
                          </div>
                          <div
                            className="listPropertyInfo"
                            id={x._id}
                            onClick={() => this.hitMap(x._id)}
                          >
                            <h4 title={x.property_name}>{x.property_name}</h4>
                            <p title={x.address}>
                              <i>
                                <img src={config.cdn_URL + "/images/locIcon.png"} />
                              </i>
                              <i>
                                <img src={config.cdn_URL + "/images/locWhiteIcon.png"} />
                              </i>
                              {x.address.length > 40
                                ? x.address.substring(0, 40) + "..."
                                : x.address}
                            </p>
                          </div>
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <center>
                    <h4>Property Not Found</h4>
                  </center>{" "}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(Filter_Property);
