import React from "react";
import { Link } from "react-router-dom";

const HomeBanner = () => {
  return (
    <div className="row homeBanner">
      <div className=" col-md-12 col-lg-6  d-flex flex-column justify-content-center">
        <h1 className="footerbannerheading">
          Find Your Perfect
          <br /> Rental Today!
        </h1>
        <p className="py-3 my-2" style={{ fontSize: "1.2rem", color: "black" }}>
          Explore our wide range of rental properties and find your ideal home
          for rent in Alberta.
        </p>
        <div className="d-flex  gap-3 my-2 justify-content-start">
          <Link to="/alberta">
            <button className="btnCommon text-uppercase ">
              View Properties
            </button>
          </Link>
          <Link to="/contact-us">
            <button className="footerlgtbtn  text-uppercase ">
              Get In Touch
            </button>
          </Link>
        </div>
      </div>
      <div className=" col-md-12 col-lg-6 ">
        <img
          loading="lazy"
          src={"/images/gsklaptop.webp"}
          className="img-fluid"
          alt="city estate management"
        />
      </div>
    </div>
  );
};

export default HomeBanner;
