import React from "react";
import { Link, withRouter } from "react-router-dom";
import config from "../../../config.js";
const axios = require("axios");

class Sidebar extends React.Component {
  state = {
    test: "",
  };

  componentDidMount = () => {
    axios
      .post(`${config.backend_URL}/admin/getAdminData`)
      .then((responseJson) => {
        this.setState(responseJson.data.data);
        console.log(responseJson.data.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  render() {
    return (
      <aside class="main-sidebar sidebar-dark-primary elevation-4">
        <a href="#" class="brand-link">
          <span class="brand-text font-weight-light">GSK</span>
        </a>

        <div class="sidebar">
          <div class="user-panel mt-3 pb-3 mb-3 d-flex">
            <div class="image">
              {/* <img src="../dist/img/user2-160x160.jpg" class="img-circle elevation-2" alt="User Image" /> */}

              <a
                href={`${config.cdn_backend_URL}/${this.state.profile_image}`}
                target="_blank"
                rel="noreferrer"
              >
                <img
                  id="userImg"
                  src={
                    this.state.profile_image
                      ? `${config.cdn_backend_URL}/${this.state.profile_image}`
                      : ""
                  }
                  class="img-circle elevation-2"
                  alt="User"
                />
              </a>
            </div>
            <div class="info">
              <a href="#" class="d-block">
                Admin
              </a>
            </div>
          </div>

          <nav class="mt-2">
            <ul
              class="nav nav-pills nav-sidebar flex-column"
              data-widget="treeview"
              role="menu"
              data-accordion="false"
            >
              <li class="nav-item has-treeview ">
                <Link to="/admin/dashboard">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon  fas fa-tachometer-alt"></i>
                    <p>Dashboard</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview ">
                <Link to="/admin/banner">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon fas fa-bookmark"></i>
                    <p>Banner</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview ">
                <Link to="/admin/ameneties">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon fab fa-servicestack"></i>
                    <p>Amenities</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview ">
                <Link to="/admin/city">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon 	fas fa-city"></i>
                    <p>City</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview ">
                <Link to="/admin/properties_list">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon fas fa-building"></i>
                    <p>Property</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview ">
                <Link to="/admin/category">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon"></i>
                    <p>Category</p>
                  </a>
                </Link>
              </li>
              <li class="nav-item has-treeview ">
                <Link to="/admin/bedroom">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon "></i>
                    <p>Bedroom</p>
                  </a>
                </Link>
              </li>
              <li class="nav-item has-treeview ">
                <Link to="/admin/bathroom">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon "></i>
                    <p>Bathroom</p>
                  </a>
                </Link>
              </li>
              <li class="nav-item has-treeview ">
                <Link to="/admin/regional-offers">
                  <a class="nav-link d-flex align-items-center gap-2 ">
                    <i class="nav-icon fas fa-gift"></i>
                    <p>Regional Offers</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview ">
                <Link>
                  <a class="nav-link d-flex align-items-center gap-2 ">
                    <i class="nav-icon fas fa-blog"></i>
                    <p>Blog</p>
                  </a>
                </Link>
              </li>
              <li class="nav-item has-treeview ">
                <Link to="/admin/author">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon "></i>
                    <p>Author </p>
                  </a>
                </Link>
              </li>
              <li class="nav-item has-treeview ">
                <Link to="/admin/addBlogCategory">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon "></i>
                    <p>Add Blog Category</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview ">
                <Link to="/admin/blogCategoryList">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon "></i>
                    <p>Blog Category List</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview ">
                <Link to="/admin/addBlog">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon "></i>
                    <p>Add Blog</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview ">
                <Link to="/admin/blogList">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon "></i>
                    <p>Blog List</p>
                  </a>
                </Link>
              </li>
              {/* <li class="nav-item has-treeview ">
                <Link to="/admin/author">
                  <a class="nav-link d-flex align-items-center gap-2 ">
                    <i class="nav-icon fas fa-user-edit"></i>
                    <p>Author</p>
                  </a>
                </Link>
              </li> */}
              <li class="nav-item has-treeview">
                <Link to="/admin/testimonials">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon 	fas fa-users"></i>
                    <p>Testimonials</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview">
                <Link to="/admin/whychoose">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon 	fab fa-quora "></i>
                    <p>Why Choose Us</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview">
                <Link to="/admin/aboutus">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon 	fas fa-address-book"></i>
                    <p>About Us</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview">
                <Link to="/admin/feedback">
                  <a href="#!" class="nav-link">
                    <i class="nav-icon fas fa-copy"></i>
                    <p>Contact Feedbacks</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview">
                <Link to="/admin/property_appointment">
                  <a href="#!" class="nav-link">
                    <i class="nav-icon fas fa-user-clock"></i>
                    <p>Property Appointment</p>
                  </a>
                </Link>
              </li>
              <li class="nav-item has-treeview">
                <Link to="/admin/visitor_parking">
                  <a href="#!" class="nav-link">
                    <i class="nav-icon fas fa-warehouse"></i>
                    <p>Visitor Parking</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview">
                <Link to="/admin/google_fb_rating">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon fas fa-grin-stars"></i>
                    <p>Google/Facebook Rating</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview">
                <Link to="/admin/websetting">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon 	fab fa-wordpress"></i>
                    <p>Website Settings</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview">
                <Link to="/admin/landingpage">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon 	fas fa-newspaper"></i>
                    <p>Landing Page Settings</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview">
                <Link to="/admin/adminsetting">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon 		fas fa-user-cog"></i>
                    <p>Admin Settings</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview">
                <Link to="/admin/change_password">
                  <a
                    href="#!"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon fas fa-user-lock"></i>
                    <p>Change Password</p>
                  </a>
                </Link>
              </li>

              <li class="nav-item has-treeview">
                <Link to="/admin/meta_tags">
                  <a
                    href="javascript:void(0)"
                    class="nav-link d-flex align-items-center gap-2 "
                  >
                    <i class="nav-icon fas fa-tags"></i>
                    <p> Meta Tags</p>
                  </a>
                </Link>
              </li>
              {/*<li class="nav-item has-treeview">
                     <Link to="/admin/smtp">
                  <a href="javascript:void(0)" class="nav-link ">
                    <i class="nav-icon fas fa-copy"></i>
                    <p> Smtp</p>
                  </a>flog
                </Link>
                </li>*/}
            </ul>
          </nav>
        </div>
      </aside>
    );
  }
}
export default withRouter(Sidebar);
