import React from "react";
import { withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../../config.js";
import Loader from "../../loader.js";

import Modal from "react-modal";
const axios = require("axios");

class Author extends React.Component {
  state = {
    loader_flag: false,
    modalIsOpen: false,
    data: [],
    id: "",
    author: "",
    errors: [],
  };

  initialState = {
    loader_flag: false,
    modalIsOpen: false,
    data: [],
    id: "",
    author: "",
    errors: [],
  };

  openModal = (id = "", author = "") => {
    this.setState({
      id: id,
      author: author,
      modalIsOpen: true,
    });
  };

  closeModal = async (e) => {
    this.setState({ modalIsOpen: false });
  };

  changedata = (e) => {
    if (e.target.name === "author") {
      this.setState({ author: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  componentDidMount = async () => {
    this.setState({ loader_flag: true });
    await this.getAuthors();
    this.setState({ loader_flag: false });
  };

  getAuthors = () => {
    axios
      .post(`${config.backend_URL}/admin/blog/get_author`)
      .then((responseJson) => {
        console.log(responseJson.data.data);
        this.setState({ data: responseJson.data.data });
        console.log(this.state.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  mySubmit = (e) => {
    e.preventDefault();

    if (this.state.author === "") {
      toast("Author can not be empty");
    }

    let newObj = {
      name: this.state.author,
    };

    if (this.state.id === "") {
      //Insert Data
      this.setState({ loader_flag: true });
      axios
        .post(`${config.backend_URL}/admin/blog/add_author`, newObj)
        .then(async (data) => {
          console.log(data);
          if (data.data.status === true) {
            toast(data.data.message);
            await this.setState(this.initialState);
            this.getAuthors();
          } else {
            toast("Something wrong!");
          }
          this.setState({ loader_flag: false });
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loader_flag: false });
        });
    } else {
      //Update Data
      this.setState({ loader_flag: true });
      axios
        .put(
          `${config.backend_URL}/admin/blog/update_author/${this.state.id}`,
          newObj
        )
        .then(async (data) => {
          if (data.data.status === true) {
            toast(data.data.message);
            await this.setState(this.initialState);
            this.getAuthors();
          } else {
            toast("Something wrong!");
          }
          this.setState({ loader_flag: false });
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loader_flag: false });
        });
    }
  };

  delCity = (id) => {
    if (window.confirm("Are you sure want to delete?")) {
      let options = {
        method: "DELETE",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      this.setState({ loader_flag: true });
      fetch(`${config.backend_URL}/admin/blog/delete_author/${id}`, options)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data.status === true) {
            this.getAuthors();
            toast(data.message);
          } else {
            toast("Something wrong!");
          }
          this.setState({ loader_flag: false });
        })
        .catch((err) => {
          console.log("error", err);
          this.setState({ loader_flag: false });
        });
    }
  };

  render() {
    const customStyles = {
      content: {
        width: "30%",
        top: "50%",
        left: "55%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
      },
    };
    return (
      <>
        <ToastContainer />
        {this.state.loader_flag ? (
          <><Loader /></>
        ) : (
          <>
            <div class="content-wrapper">
              <section class="content-header">
                <div class="container-fluid">
                  <div class="row mb-2">
                    <div class="col-sm-6">
                      <h1> Author </h1>
                    </div>
                    <div class="col-sm-6">
                      <ol class="breadcrumb float-sm-right">
                        <button
                          onClick={() => {
                            this.openModal("", "", "", "", "");
                          }}
                          class="pull-right btn btn-success"
                        >
                          Add Author
                        </button>
                      </ol>
                    </div>
                  </div>
                </div>
              </section>

              <section class="content">
                <div class="container-fluid">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card card-primary">
                        <div class="card-header">
                          <h3 class="card-title">Author List</h3>
                        </div>
                        <div class="card-body">
                          <table
                            id="exampl e1"
                            class="table table-bordered table-striped"
                          >
                            <thead>
                              <tr>
                                <th width="16%">Sr No.</th>
                                <th>Author</th>

                                <th width="20%">Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data.length > 0 ? (
                                this.state.data.map((x, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{++key}</td>
                                      <td>{x.name}</td>

                                      <td>
                                        <a
                                          href="#"
                                          onClick={() => {
                                            this.openModal(x._id, x.name);
                                          }}
                                          class="btn btn-success btn-sm"
                                        >
                                          <i class="fa fa-edit"></i>
                                        </a>{" "}
                                        &nbsp;&nbsp;
                                        <a
                                          class="btn btn-danger btn-sm"
                                          href="#"
                                          onClick={() => {
                                            this.delCity(x._id);
                                          }}
                                        >
                                          <i class="fas fa-trash"></i>
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr>
                                  <td colSpan={3} style={{ color: "red" }}>
                                    <center> Result Not Found </center>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>

            <Modal
              isOpen={this.state.modalIsOpen}
              // onAfterOpen={afterOpenModal}
              onRequestClose={this.closeModal}
              style={customStyles}
              contentLabel="Add Author  "
              ariaHideApp={false}
            >
              {/* modal */}
              <div class="modal-header">
                <h2>Author</h2>
                <button
                  type="button"
                  class="btn-close"
                  onClick={this.closeModal}
                ></button>
              </div>
              <div class="modal-body">
                <p id="error"></p>
                <form
                  onSubmit={this.mySubmit}
                  encType="multipart/form-data"
                  id="testi_form"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <input
                        class="form-control"
                        placeholder="Author"
                        onChange={this.changedata}
                        value={this.state.author}
                        name="author"
                        type="text"
                        required
                      />
                      <br />
                    </div>
                  </div>

                  <button type="submit" class="btn btn-success">
                    Submit
                  </button>
                </form>
              </div>
            </Modal>
          </>
        )}
      </>
    );
  }
}
export default withRouter(Author);
