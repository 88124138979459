import React from "react";
import { Tab, Tabs } from "react-bootstrap";
import { Link } from "react-router-dom";
import { FooterFront, HeaderFront } from "../components";
import config from "../config.js";
import helper from "../helper.js";
import Loader from "../loader.js";
const axios = require("axios");

class Properties extends React.Component {
  state = {
    Properties: [],
    cities: [],
    cityId: "all",
    loader_flag: false,
  };

  renderProperties = () => {
    this.setState({ loader_flag: true });

    const slug = this.props.match.params.city;

    if (slug != null) {
      this.getSingleCity(slug);
      this.getProperties(slug);
      this.getMetaTags(slug);
    } else {
      this.getMetaTags("properties");
      this.getProperties();
    }
    this.getCities();
  };

  componentDidMount = () => {
    this.renderProperties();
  };
  componentDidUpdate(prevProps) {
    if (
      prevProps.match.params.city !== this.props.match.params.city &&
      this.props.match.params.city !== undefined
    ) {
      if (this.props.location?.state?.cityId === "category") {
        this.getProperties(this.props.match.params.city);
        this.getMetaTags(this.props.match.params.city);
        this.setState({ cityId: "all" });
      } else {
        this.getMetaTags(this.props.match.params.city);
        this.getProperties(this.props.match.params.city);
        this.setState({ cityId: this.props.match.params.city });
      }
    }
  }

  // Meta Tags Set Sart
  getMetaTags = (page_name) => {
    axios
      .post(`${config.backend_URL}/admin/getMetaTags`, { slug: page_name })
      .then((responseJson) => {
        //set Meta Tags
        console.log(responseJson.data, "responseJson.data");
        if (responseJson.data.status) {
          helper.setMetaTitle(responseJson.data.data.meta_title);
          helper.setMetaDesc(responseJson.data.data.meta_desc);
          helper.setMetaKeyword(responseJson.data.data.meta_keyword);
        } else {
          helper.setMetaTitle("");
          helper.setMetaDesc("");
          helper.setMetaKeyword("");
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  shouldComponentUpdate = (nextProps) => {
    if (nextProps.location.search !== this.props.location.search) {
      this.renderProperties();
      return false;
    }
    return true;
  };

  getSingleCity = (slug) => {
    let newObj = { slug: slug };

    axios
      .post(`${config.backend_URL}/admin/getSingleCity`, newObj)
      .then((responseJson) => {
        this.setState({
          cityId: responseJson.data.data.slug,
        });

        //set Meta Tags
        helper.setMetaTitle(responseJson.data.data.meta_title);
        helper.setMetaDesc(responseJson.data.data.meta_desc);
        helper.setMetaKeyword(responseJson.data.data.meta_keyword);

        this.getProperties(responseJson.data.data.slug);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getProperties = async (key) => {
    const citySlug = key;

    let newObj = null;
    if (citySlug !== "all" && citySlug !== undefined) {
      newObj = { slug: citySlug };
    }
    await axios
      .post(`${config.backend_URL}/admin/getProperties`, newObj)
      .then((responseJson) => {
        if (citySlug === "all" || citySlug === undefined) {
          this.props.history.push(`/alberta`);
          this.setState({ cityId: "all" });
          helper.setMetaTitle("Properties");
        } else {
          this.props.history.push(`/alberta/${citySlug}`);
        }

        this.setState((prev) => ({
          ...prev,
          Properties: responseJson.data.data,
        }));
        // this.getSingleCity(responseJson.data.data[0]?.city.slug)
        if (newObj?.slug !== undefined && citySlug === "all") {
          helper.setMetaTitle(responseJson.data.data[0]?.city.meta_title);
          helper.setMetaDesc(responseJson.data.data[0]?.city.meta_desc);
          helper.setMetaKeyword(responseJson.data[0]?.city.data.meta_keyword);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getCities = () => {
    axios
      .get(`${config.backend_URL}/admin/getCities`)
      .then((responseJson) => {
        this.setState({ cities: responseJson.data.data });

        console.log(responseJson.data.data);
        this.setState({ loader_flag: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loader_flag: false });
      });
  };

  render() {
    return (
      <>
        <HeaderFront />
        <div className="exceptAppWrapper">
          {this.state.loader_flag ? (
            <><Loader /></>
          ) : (
            <>
              <section>
                <article
                  className="pageHdrBlock wrapper py-80"
                  style={{
                    backgroundImage: `url(${
                      config.cdn_URL + "/images/subpageBg.jpg"
                    })`,
                  }}
                >
                  <div className="pageHdrOverlay"></div>
                  <div className="container">
                    <div className="row align-items-center justify-space-beetween">
                      <div
                        className="col-sm"
                        onClick={() => {
                          console.log(this.state);
                        }}
                      >
                        <h3>Alberta</h3>
                      </div>
                      <div className="col-sm-auto">
                        <div className="pagebrdcrmbs">
                          <nav aria-label="breadcrumb">
                            <ol className="breadcrumb">
                              <li className="breadcrumb-item">
                                <Link to="/">Home</Link>
                              </li>
                              <li
                                className="breadcrumb-item active"
                                aria-current="page"
                              >
                                Alberta
                              </li>
                            </ol>
                          </nav>
                        </div>
                      </div>
                    </div>
                  </div>
                </article>

                <article className="propertyCatBlock wrapper pt-80">
                  <div className="container">
                    <div className="propertyTabsRow">
                      <div className="propertyTabsOuter">
                        <Tabs
                          id="uncontrolled-tab-example"
                          activeKey={this.state.cityId}
                          className="propertyTabs"
                          onSelect={this.getProperties}
                        >
                          <Tab eventKey="all" title="All">
                            <div className="propertyCatItems">
                              <div className="row filterMain g-xl-5 g-4">
                                {this.state.Properties.length > 0 ? (
                                  this.state.Properties.map((x, key) => {
                                    let imagePath = `${config.cdn_backend_URL}/${x.property_image}`;
                                    imagePath =
                                      x.property_image === ""
                                        ? `${config.cdn_backend_URL}/default.jpg`
                                        : imagePath;

                                    return (
                                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                                        <div className="prprtyItem">
                                          <Link
                                            to={`/alberta/${x.city.slug}/${x.slug}`}
                                          >
                                            <a href="#!">
                                              <div className="prprtyBadge">
                                                <span
                                                  className="prprtyBadgeIcon"
                                                  style={{
                                                    backgroundImage: `url(${
                                                      config.cdn_URL +
                                                      "/images/badge.png"
                                                    })`,
                                                  }}
                                                ></span>
                                                For Rent
                                              </div>
                                              <div
                                                className="prprtyImg"
                                                style={{
                                                  backgroundImage: `url(${imagePath})`,
                                                }}
                                              >
                                                <div className="prprtyOverlay"></div>
                                              </div>
                                              <div className="prprtyInfo">
                                                <h4>{x.property_name}</h4>
                                                <p>
                                                  <i>
                                                    <img
                                                      src={
                                                        config.cdn_URL +
                                                        "/images/locIcon.png"
                                                      }
                                                      alt={x.address}
                                                    />
                                                  </i>
                                                  {x.address}
                                                </p>
                                              </div>
                                            </a>
                                          </Link>
                                        </div>
                                      </div>
                                    );
                                  })
                                ) : (
                                  <>
                                    {" "}
                                    <center>
                                      <h3>Coming Soon...</h3>
                                    </center>{" "}
                                  </>
                                )}
                              </div>
                            </div>
                          </Tab>

                          {this.state.cities.length > 0 ? (
                            this.state.cities.map((x, key) => {
                              return x.propertyCount > 0 ? (
                                <Tab
                                  eventKey={x.slug}
                                  title={x.city}
                                  onClick={(e) => {
                                    e.preventDefault();
                                  }}
                                >
                                  <div className="propertyCatItems">
                                    <div className="row filterMain g-5">
                                      {this.state.Properties.length > 0 ? (
                                        this.state.Properties.map((x, key) => {
                                          let imagePath = `${config.cdn_backend_URL}/${x.property_image}`;
                                          imagePath =
                                            x.property_image === ""
                                              ? `${config.cdn_backend_URL}/default.jpg`
                                              : imagePath;
                                          return (
                                            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                              <div className="prprtyItem">
                                                <Link
                                                  to={`/alberta/${x.city.slug}/${x.slug}`}
                                                >
                                                  <a href="#!">
                                                    <div className="prprtyBadge">
                                                      For Rent
                                                    </div>
                                                    <div
                                                      className="prprtyImg"
                                                      style={{
                                                        backgroundImage: `url(${imagePath})`,
                                                      }}
                                                    >
                                                      <div className="prprtyOverlay"></div>
                                                    </div>
                                                    <div className="prprtyInfo">
                                                      <h4>{x.property_name}</h4>
                                                      <p>
                                                        <i>
                                                          <img
                                                            src={
                                                              config.cdn_URL +
                                                              "/images/locIcon.png"
                                                            }
                                                            alt={x.address}
                                                          />
                                                        </i>
                                                        {x.address}
                                                      </p>
                                                    </div>
                                                  </a>
                                                </Link>
                                              </div>
                                            </div>
                                          );
                                        })
                                      ) : (
                                        <>
                                          {" "}
                                          <center>
                                            <h3>Coming Soon...</h3>
                                          </center>{" "}
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </Tab>
                              ) : (
                                ""
                              );
                            })
                          ) : (
                            <><Loader /></>
                          )}
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </article>
              </section>
            </>
          )}
        </div>
        <FooterFront />
      </>
    );
  }
}

export default Properties;
