import React from "react";
import config from "../config.js";
import helper from "../helper.js";
import Loader from "../loader.js";

const axios = require("axios");
class WhyChooseUs_home extends React.Component {
  state = {
    loader_flag: false,
    data: [],
  };

  componentDidMount = () => {
    helper.setMetaTitle("Why Choose Us");

    this.getWhyChooseUs();
  };

  getWhyChooseUs = () => {
    this.setState({ loader_flag: true });
    axios
      .get(`${config.backend_URL}/admin/getWhyChooseUs`)
      .then(async (responseJson) => {
        await this.setState({
          data: responseJson.data.data,
          loader_flag: false,
        });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loader_flag: false });
      });
  };

  render() {
    return (
      <>
        {this.state.loader_flag ? (
          <><Loader /></>
        ) : (
          <>
            <article className="homeWhyChooseBlock wrapper py-80">
              <div
                className="homeWhyChooseBefore"
                style={{
                  backgroundImage: `url(${
                    config.cdn_URL + "/images/whyChooseBg.png"
                  })`,
                }}
              ></div>
              <div className="whyChooseRow">
                <div className="container">
                  <div className="mainHeading headingWhite headingCenter">
                    {/* <h2>Why Choose Us</h2> */}
                    <div className="hdngBrdrs">
                      <span className="hdngBrdr1"></span>
                      <span className="hdngBrdr2"></span>
                      <span className="hdngBrdr3"></span>
                    </div>
                  </div>
                  <div className="whyChooseInfoDiv">
                    <div className="row g-0">
                      {this.state.data.length > 0
                        ? this.state.data.map((x, key) => {
                            let imgPath =
                              config.cdn_backend_URL + "/" + x.image;
                            return (
                              <div className="col-lg-3 col-md-6">
                                <div className="whyChooseInfoCol">
                                  <span>
                                    <img src={imgPath} alt="Why Choose Us" />
                                  </span>
                                  <h4>{x.title}</h4>
                                  <p>{x.description}</p>
                                </div>
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </div>
                </div>
              </div>
            </article>
          </>
        )}
      </>
    );
  }
}

export default WhyChooseUs_home;
