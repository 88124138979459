import React from "react";
import { Link } from "react-router-dom";
import config from "../config.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "../loader.js";
import helper from "../helper.js";
import { HeaderFront, FooterFront } from "../components";
import Feedback_Form from "./Feedback_Form.js";

const axios = require("axios");

class Contact extends React.Component {
  state = {
    name: "",
    number: "",
    email: "",
    subject: "",
    message: "",
    loader_flag: false,
  };

  initialState = {
    name: "",
    number: "",
    email: "",
    subject: "",
    message: "",
  };

  componentDidMount() {
    this.getSettings();
    this.getMetaTags("contact");
  }

  // Meta Tags Set Sart
  getMetaTags = (page_name) => {
    axios
      .post(`${config.backend_URL}/admin/getMetaTags`, { page_name: page_name })
      .then((responseJson) => {
        //set Meta Tags
        helper.setMetaTitle(responseJson.data.data.meta_title);
        helper.setMetaDesc(responseJson.data.data.meta_desc);
        helper.setMetaKeyword(responseJson.data.data.meta_keyword);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  getSettings = () => {
    this.setState({ loader_flag: true });
    axios
      .get(`${config.backend_URL}/admin/getSettings`)
      .then((responseJson) => {
        this.setState(responseJson.data.data);
        this.setState({ loader_flag: false });
      })
      .catch((error) => {
        console.error(error);
        this.setState({ loader_flag: false });
      });
  };

  createMap = (lat, long) => {
    let zoom = 15;
    let mapScale = 591657550.5 / Math.pow(2, zoom + 1);
    var base64 = btoa([lat, long]);
    let mapStr =
      "https://www.google.com/maps/embed?key=asdfsdfsf&pb=!1m7!1m2!1m1!1d" +
      mapScale +
      "!3m3!1m2!1s0!2z" +
      base64;
    return mapStr;
  };

  render() {
    return (
      <>
        <HeaderFront />
        <div className="exceptAppWrapper">
          <ToastContainer />
          {this.state.loader_flag ? (
            <><Loader /></>
          ) : (
            <section>
              <article
                className="pageHdrBlock wrapper py-80"
                style={{
                  backgroundImage: `url(${
                    config.cdn_URL + "/images/subpageBg.jpg"
                  })`,
                }}
              >
                <div className="pageHdrOverlay"></div>
                <div className="container">
                  <div className="row align-items-center justify-space-beetween">
                    <div className="col-sm">
                      <h3>Get In Touch</h3>
                    </div>
                    <div className="col-sm-auto">
                      <div className="pagebrdcrmbs">
                        <nav aria-label="breadcrumb">
                          <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                              <Link to="/">Home</Link>
                            </li>
                            <li
                              className="breadcrumb-item active"
                              aria-current="page"
                            >
                              Contact Us
                            </li>
                          </ol>
                        </nav>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
              <article className="homeCntctBlock wrapper pt-80">
                <div
                  className="homeCntctAfter"
                  style={{
                    backgroundImage: `url(${
                      config.cdn_URL + "/images/mapBg.png"
                    })`,
                  }}
                ></div>
                <div className="container">
                  <div className="row">
                    <div className="col-xl-5 col-lg-6">
                      <div className="homeCntctDiv">
                        <div className="homeCntctForm">
                          <div className="mainHeading headingCenter">
                            <h2>Provide Us Your Feedback</h2>
                            <div className="hdngBrdrs">
                              <span className="hdngBrdr1"></span>
                              <span className="hdngBrdr2"></span>
                              <span className="hdngBrdr3"></span>
                            </div>
                            <p>
                              Please fill out the form and our Community Leader
                              will get in contact with you.
                            </p>
                          </div>

                          <Feedback_Form />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-7 col-lg-6">
                      <div className="homeCntctMainDiv">
                        <div className="homeCntctInfoRow">
                          <div className="row">
                            <div className="col-xl-4 col-lg-12 col-md-4 col-sm-12">
                              <div className="homeCntctInfoCol">
                                <span>
                                  <img
                                    src={
                                      config.cdn_URL +
                                      "/images/conttLocIcon.png"
                                    }
                                  />
                                </span>
                                <h4>GSK Properties Ltd</h4>
                                <p>{this.state.address}</p>
                              </div>
                            </div>
                            {this.state.phone ? (
                              <div className="col-xl-4 col-lg-12 col-md-4 col-sm-12">
                                <div className="homeCntctInfoCol homeCntctCall">
                                  <span>
                                    <img
                                      src={
                                        config.cdn_URL +
                                        "/images/conttCallIcon.png"
                                      }
                                    />
                                  </span>
                                  <h4>Talk to us</h4>
                                  <p>
                                    <Link to={`tel:${this.state.phone}`}>
                                      {this.state.phone}
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            {this.state.email ? (
                              <div className="col-xl-4 col-lg-12 col-md-4 col-sm-12">
                                <div className="homeCntctInfoCol">
                                  <span>
                                    <img
                                      src={
                                        config.cdn_URL +
                                        "/images/conttMailIcon.png"
                                      }
                                    />
                                  </span>
                                  <h4>Mail us @</h4>
                                  <p>
                                    <Link to={`mailto:${this.state.email}`}>
                                      {this.state.email}
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                        <div className="homeCntctMap">
                          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2508.046274393938!2d-114.08836808404027!3d51.05223335170088!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x53716feed4244bb7%3A0xcf0a9deaba549030!2sGSK%20Properties%20Ltd!5e0!3m2!1sen!2sin!4v1641631682470!5m2!1sen!2sin" allowfullscreen="" loading="lazy"></iframe> */}

                          {/* <iframe src={`https://maps.google.com/maps?q=${this.state.lat},${this.state.long}&z=15&output=embed`} allowfullscreen=""></iframe> */}
                          <iframe
                            src={this.createMap(
                              this.state.lat,
                              this.state.long
                            )}
                            allowfullscreen=""
                          ></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </section>
          )}
        </div>
        <FooterFront />
      </>
    );
  }
}

export default Contact;
